<div class="psw">
    <div>
        <div>
            <div>
                <table class="standardFont table table-striped table-condensed table-bordered">
                    <thead>
                        <div style="white-space: normal">
                            <h5>
                                Passwords must contain at least 8 characters,with at least one lowercase and one uppercase letter, at least one digit and at
                                least one special character.
                            </h5>
                        </div>
                    </thead>
                </table>
            </div>
        </div>

        <div class="psw-row" *ngIf="rule === 'user'">
            <div class="psw-col-label">
                <label for="oldPasswordInput">Old Password:</label>
            </div>
            <div class="psw-col-input">
                <input id="oldPasswordInput" type="password" class="form-control" [(ngModel)]="oldValue" />
            </div>
            <div class="psw-col-ext"></div>
        </div>

        <div class="psw-row">
            <div class="psw-col-label">
                <label for="passwordInput">New Password:</label>
            </div>
            <div class="psw-col-input">
                <input id="passwordInput" type="password" class="form-control" [(ngModel)]="value" (keyup)="checkRetype(); checkPass()" />
            </div>

            <div class="psw-col-ext psw-score">
                <div [hidden]="!score">
                    <div class="psw-text">
                        <span
                            *ngIf="rawScore <= 400"
                            [ngClass]="{ 'psw-text-0': strength == 0, 'psw-text-1': strength == 1, 'psw-text-2': strength == 2, 'psw-text-3': strength >= 3 }"
                        >
                            {{ lv[strength] }}
                        </span>
                        <span class="psw-text-0" *ngIf="rawScore > 400"> ARE YOU SERIOUS? </span>
                    </div>
                    <div class="psw-bar">
                        <span
                            class="psw-bar-main"
                            [ngClass]="{ 'psw-bar-0': strength == 0, 'psw-bar-1': strength == 1, 'psw-bar-2': strength == 2, 'psw-bar-3': strength >= 3 }"
                            [ngStyle]="{ width: score + '%' }"
                        >
                        </span>
                        <span class="psw-bar-side" [ngStyle]="{ width: 100 - score + '%' }"> </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="psw-row">
            <div class="psw-col-label">
                <label for="retypePasswordInput">Retype Password:</label>
            </div>
            <div class="psw-col-input">
                <input id="retypePasswordInput" type="password" class="form-control" [(ngModel)]="revalue" (keyup)="checkRetype()" />
            </div>
            <div class="psw-col-ext">
                <div *ngIf="value && revalue" class="psw-retype-icon">
                    <span [hidden]="!passRetype"><i class="fa fa-check-circle fa-lg psw-retype-check"></i></span>
                    <span [hidden]="passRetype"><i class="fa fa-times-circle fa-lg psw-retype-cross"></i></span>
                </div>
            </div>
        </div>
    </div>

    <div class="psw-err" *ngFor="let error of err">
        {{ error }}
    </div>

    <hr />

    <div style="text-align: right">
        <button type="submit" class="btn btn-danger" [hidden]="hideButton" (click)="savePassword()">Save</button>
        <button [hidden]="hideCancel == 'true'" type="button" class="btn btn-primary" (click)="cancel()">Cancel</button>
    </div>
</div>
