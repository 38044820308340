import commonPassword from './commonpassword'

module smartorg.r6.cmpts.password {

    import MostCommonPassword = commonPassword.MostCommonPassword

    /**
     * Verify is password is strong enough.
     *
     * @param input
     * @returns {boolean}
     */
    export let scorePassword = function (input: string) {
        let score = 0;

        if (typeof input !== 'string' || !input) {
            return score;
        }

        let letters: { [char: string]: number } = {};
        for (let i = 0; i < input.length; i++) {
            letters[input[i]] = (letters[input[i]] || 0) + 1;
            score += 5.0 / letters[input[i]];
        }

        let variations = 0;
        variations += /\d/.test(input) ? 1 : 0;
        variations += /[a-z]/.test(input) ? 1 : 0;
        variations += /[A-Z]/.test(input) ? 1 : 0;
        variations += /\W/.test(input) ? 1 : 0;

        score += variations * 10 - 10;

        score += input.length > 14 ? (input.length - 14) * 3 : 0;

        return score;
    };

    let mostcommonpassword = MostCommonPassword.getPasswordArray();

    export let length_check = (input: string) => {
        return input.length >= 8;
    };

    //check if password has uppercase and lowercase letters
    export let letters_check = (input: string) => {
        return /^(?=.*[a-z])(?=.*[A-Z])/.test(input);
    };

    //check if password has digits(0-9)
    export let digits_check = (input: string) => {
        return /^(?=.*\d)/.test(input);
    };

    //check if password has special charactor
    export let specialCharactor_check = (input: string) => {
        return /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/.test(input);
    };

    export let dictionary_wordnotused_check = (input: string) => {
        return mostcommonpassword.indexOf(input) === -1;
    };

    export class PasswordChecker {
        check: Function;
        errorMessage: Function;

        constructor(checkFunction: Function, errorMessage: string) {
            this.check = checkFunction;
            this.errorMessage = () => {
                return errorMessage;
            };
        }
    }

    export let checkers = [
        new PasswordChecker(length_check, "Please make password at least 8 characters in length"),
        new PasswordChecker(letters_check, "Please add at least one" +
            " uppercase and one lowercase letter"),
        new PasswordChecker(digits_check, "Please add at lease 1 digit"),
        new PasswordChecker(specialCharactor_check, "Please add a special character"),
        new PasswordChecker(dictionary_wordnotused_check, "Password is too easy to guess")
    ]
}

export default smartorg.r6.cmpts.password;