// azure-login.component.ts
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-azure-login',
  templateUrl: './azure-login.component.html',
  styleUrls: ['./azure-login.component.scss']
})
export class AzureLoginComponent implements OnInit {

  private readonly TOKEN_COOKIE_KEY = 'smartorg_api_jwt';
  private readonly TOKEN_COOKIE_KEY_TIME = 'smartorg_api_jwt_time';

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    const urlString = this.document.location.search;
    const jwtToken = urlString.substring(
      urlString.lastIndexOf('?') + 1,
      urlString.lastIndexOf('&')
    );

    this.setCookie(this.TOKEN_COOKIE_KEY, jwtToken, 35);
    this.setCookie(this.TOKEN_COOKIE_KEY_TIME, new Date().getTime().toString(), 35);

    this.loggedIn();
  }

  private setCookie(name: string, value: string, exmins: number) {
    const date = new Date();
    date.setTime(date.getTime() + (exmins * 60 * 1000));
    const expires = 'expires=' + date.toUTCString();
    this.document.cookie = `${name}=${value};${expires};path=/`;
  }

  private loggedIn() {
    // Navigate to first page after login
    this.router.navigate(['/workbench/home']);
  }
}
