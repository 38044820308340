/**
 * Created by yang on 6/5/17.
 */
module smartorg.r6.cmpts.password {
    export class MostCommonPassword {
        public static getPasswordArray() {
            return [
                "password",
                "12345678",
                "baseball",
                "football",
                "jennifer",
                "superman",
                "trustno1",
                "michelle",
                "sunshine",
                "123456789",
                "starwars",
                "computer",
                "corvette",
                "princess",
                "iloveyou",
                "maverick",
                "samantha",
                "steelers",
                "whatever",
                "hardcore",
                "internet",
                "mercedes",
                "bigdaddy",
                "midnight",
                "11111111",
                "marlboro",
                "victoria",
                "butthead",
                "startrek",
                "liverpoo",
                "danielle",
                "redskins",
                "mountain",
                "shithead",
                "xxxxxxxx",
                "88888888",
                "nicholas",
                "metallic",
                "qwertyui",
                "dolphins",
                "cocacola",
                "rush2112",
                "jonathan",
                "scorpion",
                "asdfasdf",
                "godzilla",
                "williams",
                "lifehack",
                "platinum",
                "garfield",
                "69696969",
                "jordan23",
                "bullshit",
                "airborne",
                "elephant",
                "explorer",
                "christin",
                "december",
                "benjamin",
                "dickhead",
                "brooklyn",
                "redwings",
                "michigan",
                "87654321",
                "guinness",
                "einstein",
                "snowball",
                "alexande",
                "passw0rd",
                "lasvegas",
                "slipknot",
                "kimberly",
                "1q2w3e4r",
                "carolina",
                "colorado",
                "creative",
                "bollocks",
                "darkness",
                "asdfghjk",
                "poohbear",
                "nintendo",
                "november",
                "password1",
                "lacrosse",
                "paradise",
                "maryjane",
                "spitfire",
                "anderson",
                "cherokee",
                "drowssap",
                "marshall",
                "1qaz2wsx",
                "caroline",
                "franklin",
                "snickers",
                "courtney",
                "westside",
                "patricia",
                "semperfi",
                "freeuser",
                "babygirl",
                "champion",
                "softball",
                "security",
                "wildcats",
                "veronica",
                "abcd1234",
                "wolverin",
                "remember",
                "freepass",
                "pearljam",
                "mistress",
                "peekaboo",
                "budlight",
                "electric",
                "stargate",
                "brittany",
                "swimming",
                "scotland",
                "swordfis",
                "blink182",
                "virginia",
                "passport",
                "aaaaaaaa",
                "rolltide",
                "bulldogs",
                "liverpool",
                "chevelle",
                "mitchell",
                "spiderma",
                "patriots",
                "cardinal",
                "kawasaki",
                "ncc1701d",
                "airplane",
                "scarface",
                "elizabet",
                "wolfpack",
                "lawrence",
                "american",
                "stingray",
                "simpsons",
                "srinivas",
                "panthers",
                "pussycat",
                "loverboy",
                "tarheels",
                "wolfgang",
                "testtest",
                "michael1",
                "pakistan",
                "infinity",
                "letmein1",
                "hercules",
                "billybob",
                "pavilion",
                "changeme",
                "darkside",
                "zeppelin",
                "darkstar",
                "charlie1",
                "wrangler",
                "qwerty12",
                "bobafett",
                "business",
                "sterling",
                "babydoll",
                "cheyenne",
                "longhorn",
                "presario",
                "mustang1",
                "21122112",
                "q1w2e3r4",
                "12341234",
                "devildog",
                "bluebird",
                "metallica",
                "access14",
                "enterpri",
                "blizzard",
                "asdf1234",
                "harrison",
                "thailand",
                "1234567890",
                "cadillac",
                "hellfire",
                "lonewolf",
                "12121212",
                "fireball",
                "precious",
                "engineer",
                "basketba",
                "valentin",
                "wetpussy",
                "morpheus",
                "hotstuff",
                "fuck_inside",
                "goldberg",
                "wrinkle1",
                "consumer",
                "serenity",
                "99999999",
                "bigboobs",
                "chocolat",
                "christia",
                "birthday",
                "stephani",
                "1234qwer",
                "98765432",
                "77777777",
                "highland",
                "seminole",
                "airforce",
                "hamilton",
                "buckeyes",
                "abcdefgh",
                "goldfish",
                "deftones",
                "icecream",
                "pleasure",
                "juventus",
                "ncc1701e",
                "51505150",
                "cavalier",
                "aardvark",
                "babylon5",
                "savannah",
                "yankees1",
                "fredfred",
                "concrete",
                "shamrock",
                "atlantis",
                "wordpass",
                "predator",
                "marathon",
                "montreal",
                "kathleen",
                "jessica1",
                "diamonds",
                "stallion",
                "letmein2",
                "clitoris",
                "sundance",
                "renegade",
                "hollywoo",
                "hello123",
                "sweetpea",
                "stocking",
                "campbell",
                "christop",
                "rockstar",
                "geronimo",
                "chandler",
                "lovelove",
                "greenday",
                "987654321",
                "creampie",
                "trombone",
                "55555555",
                "mongoose",
                "tottenha",
                "butterfl",
                "clifford",
                "fuckyou2",
                "infantry",
                "skywalke",
                "raistlin",
                "vanhalen",
                "sherlock",
                "dietcoke",
                "ultimate",
                "superfly",
                "freedom1",
                "drpepper",
                "lesbians",
                "musicman",
                "warcraft",
                "microsoft",
                "morrison",
                "isabelle",
                "thuglife",
                "stonecol",
                "logitech",
                "florence",
                "1passwor",
                "bluemoon",
                "22222222",
                "stardust",
                "margaret",
                "66666666",
                "charlott",
                "waterloo",
                "11223344",
                "standard",
                "alexandr",
                "hannibal",
                "frontier",
                "welcome1",
                "spanking",
                "japanese",
                "kristina",
                "deepthroat",
                "bonehead",
                "showtime",
                "squirrel",
                "mustangs",
                "septembe",
                "leonardo",
                "makaveli",
                "vacation",
                "passwor1",
                "columbia",
                "napoleon",
                "motorola",
                "william1",
                "matthew1",
                "robinson",
                "penguins",
                "8j4ye3uz",
                "californ",
                "qwertyuiop",
                "portland",
                "asdfghjkl",
                "overlord",
                "stranger",
                "socrates",
                "spiderman",
                "13131313",
                "national",
                "intrepid",
                "megadeth",
                "bigballs",
                "chargers",
                "discover",
                "isabella",
                "megapass",
                "grateful",
                "mushroom",
                "cristina",
                "hongkong",
                "basketball",
                "satan666",
                "kingkong",
                "penelope",
                "thompson",
                "anything",
                "knickers",
                "playtime",
                "lightnin",
                "slapshot",
                "titleist",
                "werewolf",
                "fernando",
                "blackcat",
                "tacobell",
                "kittycat",
                "thunder1",
                "thankyou",
                "scoobydo",
                "coltrane",
                "lonestar",
                "heather1",
                "beefcake",
                "zzzzzzzz",
                "personal",
                "anthony1",
                "fuckface",
                "lowrider",
                "punkrock",
                "dodgeram",
                "dingdong",
                "qqqqqqqq",
                "johnjohn",
                "asshole1",
                "crusader",
                "syracuse",
                "meridian",
                "turkey50",
                "keyboard",
                "ilovesex",
                "blackman",
                "richmond",
                "sandiego",
                "cooldude",
                "mariners",
                "caliente",
                "fletcher",
                "porsche9",
                "kangaroo",
                "springer",
                "goodtime",
                "chelsea1",
                "freckles",
                "nebraska",
                "webmaster",
                "blueeyes",
                "director",
                "monopoly",
                "blackjac",
                "southern",
                "peterpan",
                "fuckyou1",
                "a1b2c3d4",
                "sentinel",
                "richard1",
                "1234abcd",
                "guardian",
                "candyman",
                "mandingo",
                "munchkin",
                "billyboy",
                "rootbeer",
                "assassin",
                "frederic",
                "giovanni",
                "scarlett",
                "achilles",
                "warriors",
                "plymouth",
                "cameltoe",
                "fuckfuck",
                "sithlord",
                "backdoor",
                "chevrole",
                "lorraine",
                "cosworth",
                "eternity",
                "verbatim",
                "chocolate",
                "deadhead",
                "pineappl",
                "rosemary",
                "porkchop",
                "blackdog",
                "alexander",
                "valhalla",
                "santiago",
                "portugal",
                "1qazxsw2",
                "stripper",
                "sebastia",
                "hurrican",
                "1x2zkg8w",
                "atlantic",
                "hyperion",
                "44444444",
                "skittles",
                "hastings",
                "gangbang",
                "sailboat",
                "immortal",
                "maryland",
                "columbus",
                "beautiful",
                "swordfish",
                "ncc1701a",
                "spartans",
                "threesom",
                "dilligaf",
                "pinkfloy",
                "catalina",
                "formula1",
                "scooter1",
                "colombia",
                "lancelot",
                "angelica",
                "rockhard",
                "poontang",
                "starship",
                "starbuck",
                "catherin",
                "kentucky",
                "33333333",
                "12344321",
                "sapphire",
                "raiders1",
                "excalibu",
                "imperial",
                "phillips",
                "golfball",
                "front242",
                "macdaddy",
                "qwer1234",
                "cowboys1",
                "dannyboy",
                "martinez",
                "aquarius",
                "pppppppp",
                "clarence",
                "eatpussy",
                "beatrice",
                "phillies",
                "research",
                "gggggggg",
                "doughboy",
                "lollipop",
                "qazwsxed",
                "crazybab",
                "brothers",
                "butthole",
                "rightnow",
                "greatone",
                "gateway1",
                "wildfire",
                "jackson1",
                "0.0.0.000",
                "snuggles",
                "phoenix1",
                "technics",
                "gesperrt",
                "brucelee",
                "woofwoof",
                "theodore",
                "richards",
                "punisher",
                "username",
                "bunghole",
                "elizabeth",
                "lifetime",
                "masterbate",
                "diamond1",
                "abnormal",
                "davidson",
                "starfish",
                "penetration",
                "michaela",
                "caligula",
                "railroad",
                "bradford",
                "military",
                "bearbear",
                "patrick1",
                "christine",
                "swinging",
                "labrador",
                "justdoit",
                "meatball",
                "saturday",
                "defender",
                "piercing",
                "microsof",
                "mechanic",
                "robotech",
                "universe",
                "newpass6",
                "hellyeah",
                "zaq12wsx",
                "spectrum",
                "jjjjjjjj",
                "oklahoma",
                "mmmmmmmm",
                "blueblue",
                "wolverine",
                "sniffing",
                "keystone",
                "bbbbbbbb",
                "handsome",
                "tttttttt",
                "ssssssss",
                "somethin",
                "melissa1",
                "marcius2",
                "godsmack",
                "rangers1",
                "deeznuts",
                "kingston",
                "yosemite",
                "tommyboy",
                "masterbating",
                "marianne",
                "happyday",
                "manchest",
                "unbelievable",
                "aberdeen",
                "nathalie",
                "intercourse",
                "supersta",
                "bcfields",
                "hardrock",
                "children",
                "commando",
                "sinclair",
                "squerting",
                "jeanette",
                "meathead",
                "gandalf1",
                "magnolia",
                "kenworth",
                "redalert",
                "homemade",
                "webmaste",
                "insertion",
                "temptress",
                "gretchen",
                "celebrity",
                "ragnarok",
                "trinidad",
                "kingfish",
                "blackhaw",
                "thursday",
                "meatloaf",
                "interacial",
                "streaming",
                "pertinant",
                "pool6123",
                "animated",
                "gordon24",
                "fantasies",
                "touching",
                "homepage",
                "ejaculation",
                "whocares",
                "jamesbon",
                "amsterda",
                "february",
                "luckydog",
                "businessbabe",
                "brandon1",
                "experience",
                "software",
                "thirteen",
                "rasputin",
                "greenbay",
                "pa55word",
                "contortionist",
                "sneakers",
                "sonyfuck",
                "test1234",
                "roadkill",
                "cheerleaers",
                "madeline",
                "christian",
                "brighton",
                "housewifes",
                "emmanuel",
                "bigmoney",
                "seductive",
                "sexygirl",
                "canadian",
                "gangbanged",
                "crawford",
                "hotpussy",
                "implants",
                "intruder",
                "andyod22",
                "barcelon",
                "chainsaw",
                "chickens",
                "downtown",
                "magicman",
                "clevelan",
                "designer",
                "budweise",
                "experienced",
                "pitchers",
                "passwords",
                "jeremiah",
                "alliance",
                "halflife",
                "saratoga",
                "positive",
                "transexual",
                "close-up",
                "sunnyday",
                "starfire",
                "pictuers",
                "testing1",
                "tiberius",
                "lisalisa",
                "golfgolf",
                "flounder",
                "majestic",
                "trailers",
                "mikemike",
                "whitesox",
                "angelina",
                "goodluck",
                "charlton",
                "fingerig",
                "gallaries",
                "lockerroom",
                "treasure",
                "absolutely",
                "homepage-",
                "beerbeer",
                "testerer",
                "fordf150",
                "pa55w0rd",
                "kamikaze",
                "japanees",
                "masterbaiting",
                "callaway",
                "panasoni",
                "housewife",
                "18436572",
                "sullivan",
                "terrapin",
                "masturbation",
                "hardcock",
                "freeporn",
                "pornographic",
                "traveler",
                "moneyman",
                "shopping",
                "thumbnils",
                "amateurs",
                "apollo13",
                "goldwing",
                "doghouse",
                "pounding",
                "truelove",
                "underdog",
                "wrestlin",
                "sherwood",
                "johannes",
                "balloons",
                "happy123",
                "flamingo",
                "paintbal",
                "llllllll",
                "twilight",
                "christie",
                "bullseye",
                "knickerless",
                "binladen",
                "peterson",
                "thanatos",
                "albatros",
                "getsdown",
                "nwo4life",
                "underwear",
                "dddddddd",
                "deeznutz",
                "enterprise",
                "misfit99",
                "solution",
                "meredith",
                "barefoot",
                "50spanks",
                "scandinavian",
                "original",
                "shannon1",
                "techniques",
                "chemical",
                "salvador",
                "manchester",
                "buckshot",
                "thegreat",
                "goldstar",
                "triangle",
                "kristine",
                "snowboar",
                "penetrating",
                "roadking",
                "rockford",
                "chicago1",
                "ferrari1",
                "galeries",
                "godfathe",
                "gargoyle",
                "gangster",
                "pussyman",
                "pooppoop",
                "newcastl",
                "mortgage",
                "snoopdog",
                "assholes",
                "property",
                "broadway",
                "butterfly",
                "earthlink",
                "westwood",
                "blackbir",
                "slippery",
                "pianoman",
                "tomorrow",
                "roadrunn",
                "attitude",
                "seahawks",
                "tunafish",
                "cinnamon",
                "northern",
                "23232323",
                "zerocool",
                "limewire",
                "films+pic+galeries",
                "francois",
                "fuckthis",
                "girfriend",
                "uncencored",
                "chrisbln",
                "netscape",
                "hhhhhhhh",
                "knockers",
                "tazmania",
                "pharmacy",
                "arsenal1",
                "anaconda",
                "australi",
                "gotohell",
                "bulldog1",
                "monalisa",
                "whiteout",
                "james007",
                "bitchass",
                "southpar",
                "lionking",
                "megatron",
                "hawaiian",
                "gymnastic",
                "panther1",
                "wp2003wp",
                "passwort",
                "friendly",
                "oooooooo",
                "bullfrog",
                "holyshit",
                "jasmine1",
                "sergeant",
                "babyblue",
                "pass1234",
                "poseidon",
                "confused",
                "hollywood",
                "insertions",
                "juliette",
                "hayabusa",
                "hawkeyes",
                "geoffrey",
                "chuckles",
                "hounddog",
                "philippe",
                "thunderb",
                "marino13",
                "handyman",
                "cerberus",
                "gamecock",
                "magician",
                "preacher",
                "chrysler",
                "contains",
                "hedgehog",
                "hoosiers",
                "dutchess",
                "wareagle",
                "ihateyou",
                "sunflowe",
                "senators",
                "terminal",
                "laurence",
                "maradona",
                "america1",
                "chicken1",
                "passpass",
                "r2d2c3po",
                "myxworld",
                "missouri",
                "wishbone",
                "infiniti",
                "wonderboy",
                "stanford",
                "smeghead",
                "titanium",
                "charlene",
                "fishing1",
                "fullmoon",
                "absolute",
                "seinfeld",
                "pingpong",
                "matthews",
                "recovery",
                "babyface",
                "gladiato",
                "paranoid",
                "packers1",
                "longjohn",
                "clarinet",
                "mortimer",
                "modelsne",
                "vladimir",
                "together",
                "avalanch",
                "55bgates",
                "cccccccc",
                "paradigm",
                "operator",
                "valencia",
                "cocksuck",
                "creature",
                "borussia",
                "browning",
                "heritage",
                "millions",
                "starcraf",
                "spaceman",
                "chester1",
                "rrrrrrrr",
                "sandwich",
                "magazine",
                "buttfuck",
                "yeahbaby",
                "11235813",
                "bangbang",
                "charles1",
                "ffffffff",
                "doberman",
                "overkill",
                "claymore",
                "brewster",
                "electron",
                "eastside",
                "minimoni",
                "wildbill",
                "wildcard",
                "yyyyyyyy",
                "sweetnes",
                "skywalker",
                "alphabet",
                "babybaby",
                "graphics",
                "florida1",
                "flexible",
                "fuckinside",
                "ursitesux",
                "christma",
                "wwwwwwww",
                "just4fun",
                "rebecca1",
                "adrienne",
                "19691969",
                "silverad",
                "rhiannon",
                "10101010",
                "ashleigh",
                "qwerasdf",
                "presiden",
                "newyork1",
                "brigitte",
                "buddyboy",
                "heineken",
                "millwall",
                "beautifu",
                "sinister",
                "smashing",
                "teddybea",
                "ticklish",
                "lipstick",
                "reynolds",
                "applepie",
                "digital1",
                "dinosaur",
                "icehouse",
                "insanity",
                "bluefish",
                "strength",
                "sentnece",
                "temppass",
                "medicine",
                "hahahaha",
                "casanova",
                "fountain",
                "dolphin1",
                "porsche1",
                "vampires",
                "highheel",
                "kkkkkkkk",
                "illinois",
                "21212121",
                "stonecold",
                "testpass",
                "jiggaman",
                "federico",
                "scorpio1",
                "rt6ytere",
                "madison1",
                "coolness",
                "christina",
                "coldbeer",
                "brittney",
                "washingt",
                "stephanie",
                "shepherd",
                "tiffany1",
                "mephisto",
                "dragonba",
                "nygiants",
                "password2",
                "corleone",
                "kittykat",
                "vikings1",
                "splinter",
                "pipeline",
                "meowmeow",
                "chestnut",
                "longdong",
                "quant4307s",
                "eastwood",
                "moonligh",
                "illusion",
                "jayhawks",
                "swingers",
                "stefanie",
                "jefferso",
                "michael2",
                "fastball",
                "scrabble",
                "dirtbike",
                "customer",
                "nemrac58",
                "bobdylan",
                "hopeless",
                "kcj9wx5n",
                "killbill",
                "volkswag",
                "windmill",
                "iloveyou1",
                "starligh",
                "soulmate",
                "mcdonald",
                "rochelle",
                "oblivion",
                "valkyrie",
                "concorde",
                "costello",
                "delaware",
                "nocturne",
                "herewego",
                "earnhard",
                "eeeeeeee",
                "mobydick",
                "reddevil",
                "reckless",
                "radiohea",
                "coolcool",
                "classics",
                "choochoo",
                "wireless",
                "bigblock",
                "summer99",
                "sexysexy",
                "platypus",
                "telephon",
                "12qwaszx",
                "fishhead",
                "paramedi",
                "lonesome",
                "katherin",
                "moonbeam",
                "monster1",
                "monkeybo",
                "windsurf",
                "31415926",
                "sebastian",
                "smoothie",
                "snowflak",
                "playstat",
                "playboy1",
                "roadster",
                "hardware",
                "captain1",
                "undertak",
                "uuuuuuuu",
                "criminal",
                "1a2b3c4d",
                "thedoors",
                "annabell",
                "catwoman",
                "faithful",
                "farscape",
                "genesis1",
                "pumpkins",
                "training",
                "islander",
                "jamesbond",
                "19841984",
                "shitface",
                "maxwell1",
                "armstron",
                "alejandr",
                "augustus",
                "care1839",
                "fantasia",
                "freefall",
                "sandrine",
                "qwerqwer",
                "crystal1",
                "nineinch",
                "broncos1",
                "winston1",
                "warrior1",
                "iiiiiiii",
                "iloveyou2",
                "straight",
                "specialk",
                "tinkerbe",
                "jellybea",
                "cbr900rr",
                "gabriell",
                "gertrude",
                "glennwei",
                "sausages",
                "vanguard",
                "trinitro",
                "eldorado",
                "whiskers",
                "wildwood",
                "istheman",
                "interest",
                "25802580",
                "woodland",
                "strawber",
                "amsterdam",
                "catherine",
                "football1",
                "vancouve",
                "vauxhall",
                "acidburn",
                "myspace1",
                "buttercu",
                "minemine",
                "bigpoppa",
                "blackout",
                "blowfish",
                "talisman",
                "sundevil",
                "shanghai",
                "spencer1",
                "slowhand",
                "jonathon",
                "michaels",
                "resident",
                "redbaron",
                "andromed",
                "harddick",
                "5wr2i7h8",
                "charlotte",
                "fredrick",
                "francesc",
                "ferguson",
                "fairlane",
                "dogpound",
                "pornporn",
                "clippers",
                "daylight",
                "nnnnnnnn",
                "budapest",
                "whistler",
                "whatwhat",
                "wanderer",
                "idontkno",
                "thisisit",
                "robotics",
                "gonzalez",
                "drummer1",
                "private1",
                "cornwall",
                "christopher",
                "corvet07",
                "iverson3",
                "bluesman",
                "terminat",
                "johnson1",
                "bastards",
                "fuckoff1",
                "doomsday",
                "pornking",
                "bookworm",
                "highbury",
                "mischief",
                "ministry",
                "bigbooty",
                "yogibear",
                "september",
                "lkjhgfds",
                "123123123",
                "carpedie",
                "foxylady",
                "gatorade",
                "valdepen",
                "deadpool",
                "hotmail1",
                "kordell1",
                "vvvvvvvv",
                "jackson5",
                "bergkamp",
                "zanzibar",
                "services",
                "sheridan",
                "checkers",
                "luv2epus",
                "rainbow6",
                "qwerty123",
                "commande",
                "nightwin",
                "hotmail0",
                "enternow",
                "viewsoni",
                "berkeley",
                "woodstoc",
                "starstar",
                "patience",
                "hawaii50",
                "gorgeous",
                "challeng",
                "callisto",
                "firewall",
                "firefire",
                "passmast",
                "transfer",
                "clarissa",
                "moonshin",
                "jakejake",
                "bluejays",
                "southpark",
                "tomahawk",
                "leedsutd",
                "jermaine",
                "jeepster",
                "josephin",
                "matthias",
                "marriage",
                "antelope",
                "cabernet",
                "cheshire",
                "california",
                "fuckhead",
                "dominion",
                "trucking",
                "nostromo",
                "honolulu",
                "dynamite",
                "mollydog",
                "windows1",
                "washburn",
                "vincent1",
                "irishman",
                "bearcats",
                "sylveste",
                "marijuan",
                "reddwarf",
                "12312312",
                "hardball",
                "goldfing",
                "chambers",
                "fandango",
                "festival",
                "scrapper",
                "cromwell",
                "entrance",
                "klondike",
                "mohammed",
                "insomnia",
                "24682468",
                "24242424",
                "billbill",
                "blessing",
                "solitude",
                "pimpdadd",
                "johndeer",
                "babylove",
                "barbados",
                "carpente",
                "fishbone",
                "fireblad",
                "scissors",
                "screamer",
                "obsidian",
                "progress",
                "tottenham",
                "comanche",
                "monsters",
                "veronika",
                "20202020",
                "blueball",
                "yankees2",
                "wrestler",
                "sealteam",
                "sidekick",
                "smackdow",
                "sporting",
                "remingto",
                "arkansas",
                "andersen",
                "barcelona",
                "baltimor",
                "fortress",
                "fishfish",
                "firefigh",
                "rsalinas",
                "dontknow",
                "universa",
                "heinrich",
                "enforcer",
                "katherine",
                "waterboy",
                "23skidoo",
                "zildjian",
                "stoppedby",
                "sexybabe",
                "speakers",
                "polopolo",
                "perfect1",
                "thrasher",
                "lakeside",
                "masamune",
                "cherries",
                "chipmunk",
                "cezer121",
                "carnival",
                "fearless",
                "funstuff",
                "salasana",
                "pantera1",
                "qwert123",
                "creation",
                "nascar24",
                "estrella",
                "erection",
                "ericsson",
                "internal",
                "1michael",
                "19781978",
                "25252525",
                "sheepdog",
                "snowbird",
                "toriamos",
                "tennesse",
                "mazdarx7",
                "revolver",
                "babycake",
                "hallowee",
                "cannabis",
                "dolemite",
                "dodgers1",
                "painting",
                "coventry",
                "christmas",
                "cocksucker",
                "hotgirls",
                "eggplant",
                "mustang6",
                "monkey12",
                "wapapapa",
                "volleyba",
                "birthday4",
                "stephen1",
                "suburban",
                "soccer10",
                "something",
                "starcraft",
                "soccer12",
                "plastics",
                "penthous",
                "peterbil",
                "lakewood",
                "reginald",
                "goodgirl",
                "gotyoass",
                "capricor",
                "getmoney",
                "godfather",
                "gilligan",
                "dudedude",
                "pasadena",
                "opendoor",
                "magellan",
                "printing",
                "pressure",
                "killkill",
                "whiteboy",
                "voyager1",
                "jackjack",
                "success1",
                "spongebo",
                "phialpha",
                "password9",
                "tickling",
                "lexingky",
                "redheads",
                "apple123",
                "backbone",
                "aviation",
                "green123",
                "carlitos",
                "cartman1",
                "camaross",
                "favorite6",
                "ginscoot",
                "sabrina1",
                "devil666",
                "doughnut",
                "paintball",
                "rainbow1",
                "umbrella",
                "abc12345",
                "complete",
                "deerhunt",
                "darklord",
                "holidays",
                "hetfield",
                "hillbill",
                "hugetits",
                "evolutio",
                "whiplash",
                "wg8e3wjf",
                "istanbul",
                "bluebell",
                "wrestling",
                "superior",
                "suckdick",
                "stephane",
                "playball",
                "marcello",
                "marjorie",
                "rockwell",
                "baritone",
                "gladiator",
                "cricket1",
                "clemente",
                "exchange",
                "kisskiss",
                "kristian",
                "montecar",
                "mississi",
                "washington",
                "20012001",
                "bigdick1",
                "penguin1",
                "pathfind",
                "testibil",
                "lightning",
                "lighting",
                "republic",
                "anthony7",
                "goldeney",
                "cameron1",
                "freefree",
                "screwyou",
                "passthie",
                "postov1000",
                "puppydog",
                "a1234567",
                "cleopatr",
                "contract",
                "buffalo1",
                "bordeaux",
                "sunlight",
                "sprinter",
                "peaches1",
                "pinetree",
                "theforce",
                "jupiter1",
                "mckenzie",
                "annmarie",
                "austin31",
                "78945612",
                "calimero",
                "chevrolet",
                "favorite",
                "fellatio",
                "f00tball",
                "francine",
                "gateway2",
                "gamecube",
                "giovanna",
                "scheisse",
                "offshore",
                "macaroni",
                "pringles",
                "trouble1",
                "coolhand",
                "colonial",
                "darthvad",
                "cygnusx1",
                "natalie1",
                "eighteen",
                "elcamino",
                "blueberr",
                "yamahar1",
                "stafford",
                "snowboard",
                "speedway",
                "playboy2",
                "toonarmy",
                "mariposa",
                "baberuth",
                "gonzales",
                "chiquita",
                "charisma",
                "capslock",
                "cashmone",
                "gizmodo1",
                "dragonfl",
                "rachelle",
                "tropical",
                "crescent",
                "nathanie",
                "espresso",
                "kikimora",
                "20002000",
                "birthday1",
                "beatles1",
                "bigdicks",
                "beethove",
                "blacklab",
                "woodwork",
                "survivor",
                "pinnacle",
                "lemonade",
                "lalakers",
                "lebowski",
                "lalalala",
                "mercury1",
                "rocknrol",
                "riversid",
                "11112222",
                "alleycat",
                "ambrosia",
                "australia",
                "hattrick",
                "cassandr",
                "charlie123",
                "fighting",
                "gabriela",
                "outoutout",
                "pussy123",
                "randolph",
                "coldplay",
                "novifarm",
                "notredam",
                "honeybee",
                "wednesda",
                "waterfal",
                "billabon",
                "zachary1",
                "01234567",
                "superstar",
                "stiletto",
                "sigmachi",
                "somerset",
                "smithers",
                "playmate",
                "pinkfloyd",
                "laetitia",
                "revoluti",
                "archange",
                "handball",
                "chewbacc",
                "fullback",
                "dominiqu",
                "mandrake",
                "vagabond",
                "csfbr5yy",
                "deadspin",
                "ncc74656",
                "houston1",
                "hurricane",
                "horseman",
                "virginie",
                "idontknow",
                "151nxjmt",
                "bendover",
                "surprise",
                "supernov",
                "phantom1",
                "playoffs",
                "johngalt",
                "maserati",
                "riffraff",
                "architec",
                "cambridg",
                "foreplay",
                "sanity72",
                "salesman",
                "dreaming",
                "palmtree",
                "luckyone",
                "treefrog",
                "usmarine",
                "darkange",
                "cyclones",
                "bubba123",
                "building",
                "eclipse1",
                "kayleigh",
                "mustang2",
                "bigtruck",
                "yeahyeah",
                "stickman",
                "skipper1",
                "singapor",
                "southpaw",
                "slamdunk",
                "therock1",
                "tiger123",
                "mccarthy",
                "13576479",
                "greywolf",
                "candyass",
                "catfight",
                "frankie1",
                "qazwsxedc",
                "pregnant",
                "death666",
                "negative",
                "hooligan",
                "everlast",
                "mulligan",
                "motocros",
                "waterman",
                "inspiron",
                "bigblack",
                "zaq1xsw2",
                "yy5rbfsc",
                "takehana",
                "skydiver",
                "special1",
                "slimshad",
                "sopranos",
                "patches1",
                "thething",
                "mash4077",
                "matchbox",
                "14789632",
                "amethyst",
                "baseball1",
                "greenman",
                "goofball",
                "castillo",
                "capitals",
                "favorite2",
                "forsaken",
                "feelgood",
                "gfxqx686",
                "dilbert1",
                "dukeduke",
                "downhill",
                "longhair",
                "lockdown",
                "mamacita",
                "rainyday",
                "pumpkin1",
                "prospect",
                "rainbows",
                "trinity1",
                "trooper1",
                "citation",
                "bukowski",
                "bubbles1",
                "humphrey",
                "kcchiefs",
                "morticia",
                "montrose",
                "154ugeiu",
                "year2005",
                "wonderfu",
                "tampabay",
                "slapnuts",
                "spartan1",
                "sprocket",
                "sometime",
                "stanley1",
                "thinking",
                "lavalamp",
                "laserjet",
                "jediknig",
                "mazda626",
                "alexandra",
                "hairball",
                "graduate",
                "cartoons",
                "cashflow",
                "outsider",
                "mallrats",
                "primetime21",
                "valleywa",
                "abcdefg1",
                "natedogg",
                "nineball",
                "normandy",
                "nicetits",
                "buddy123",
                "highlife",
                "earthlin",
                "eatmenow",
                "kirkland",
                "money123",
                "warhamme",
                "instinct",
                "jackass1",
                "20spanks",
                "blackjack",
                "085tzzqi",
                "383pdjvl",
                "sparhawk",
                "pavement",
                "johnston",
                "material",
                "melanie1",
                "redlight",
                "aolsucks",
                "alexalex",
                "b929ezzh",
                "goodyear",
                "griffith",
                "863abgsg",
                "carebear",
                "checkmat",
                "forgetit",
                "rushmore",
                "question",
                "ptfe3xxp",
                "prophecy",
                "aircraft",
                "access99",
                "cocktail",
                "civilwar",
                "cleveland",
                "claudia1",
                "dapzu455",
                "daisydog",
                "eldiablo",
                "kingrich",
                "mudvayne",
                "vipergts",
                "italiano",
                "innocent",
                "yqlgr667",
                "zxcvbnm1",
                "suckcock",
                "stephens",
                "380zliki",
                "sexylady",
                "sixtynin",
                "sleeping",
                "sparkles",
                "letsdoit",
                "landmark",
                "marauder",
                "register",
                "basebal1",
                "azertyui",
                "hawkwind",
                "capetown",
                "flathead",
                "fisherma",
                "flipmode",
                "gabriel1",
                "dreamcas",
                "dirtydog",
                "dickdick",
                "destiny1",
                "trumpet1",
                "aaaaaaa1",
                "conquest",
                "creepers",
                "constant",
                "cornhole",
                "nirvana1",
                "elisabet",
                "musician",
                "milamber",
                "isacs155",
                "1million",
                "1letmein",
                "stonewal",
                "sexsexsex",
                "sonysony",
                "smirnoff",
                "pentagon",
                "paulpaul",
                "lighthou",
                "letmein22",
                "letmesee",
                "merchant",
                "redstorm",
                "14141414",
                "allison1",
                "basement",
                "hartford",
                "hardwood",
                "fatluvr69",
                "fidelity",
                "feathers",
                "gogators",
                "general1",
                "dragon69",
                "dragonball",
                "papillon",
                "optimist",
                "longshot",
                "undertow",
                "copenhag",
                "delldell",
                "culinary",
                "ibilltes",
                "hihje863",
                "envelope",
                "express1",
                "mustang5",
                "wellingt",
                "waterski",
                "infinite",
                "iloveyou!",
                "063dyjuy",
                "survival",
                "stockton",
                "softtail",
                "slimed123",
                "pizzaman",
                "pathetic",
                "tigercat",
                "jennings",
                "rootedit",
                "riverrat",
                "atreides",
                "happines",
                "chadwick",
                "ffvdj474",
                "foreskin",
                "gameover",
                "scoobydoo",
                "saxophon",
                "macintos",
                "lollypop",
                "qwertzui",
                "adelaide",
                "acapulco",
                "cybersex",
                "davecole",
                "davedave",
                "nineteen",
                "highlander",
                "kristin1",
                "knuckles",
                "katarina",
                "montana1",
                "wingchun",
                "watching",
                "illmatic",
                "bigpenis",
                "blue1234",
                "xxxxxxx1",
                "svetlana",
                "368ejhih",
                "playstation",
                "pescator",
                "jo9k2jw2",
                "jupiter2",
                "jurassic",
                "marines1",
                "14725836",
                "12345679",
                "alessand",
                "angelika",
                "alpha123",
                "barefeet",
                "badabing",
                "gsxr1000",
                "gregory1",
                "766rglqy",
                "69camaro",
                "calendar",
                "fishcake",
                "giuseppe",
                "gnasher23",
                "fuzzball",
                "save13tx",
                "russell1",
                "dripping",
                "dragon12",
                "dragster",
                "mainland",
                "poophead",
                "porn4life",
                "producer",
                "rapunzel",
                "velocity",
                "vanessa1",
                "trueblue",
                "vampire1",
                "navyseal",
                "nightowl",
                "nonenone",
                "nightmar",
                "bulletin",
                "hillside",
                "hzze929b",
                "hellohel",
                "edgewise",
                "embalmer",
                "excalibur",
                "mounta1n",
                "muffdive",
                "vivitron",
                "winfield",
                "wednesday",
                "17171717",
                "17011701",
                "tangerin",
                "stewart1",
                "summer69",
                "sweetness",
                "surveyor",
                "stirling",
                "ssptx452",
                "thriller",
                "master12",
                "anastasi",
                "almighty",
                "argentin",
                "flanders",
                "flyers88",
                "firehawk",
                "flashman",
                "godspeed",
                "giveitup",
                "funtimes",
                "frenchie",
                "disaster",
                "lovelife",
                "qcmfd454",
                "undertaker",
                "911turbo",
                "cristian",
                "daughter",
                "notebook",
                "borabora",
                "brisbane",
                "mohammad",
                "bettyboo",
                "blackice",
                "yvtte545",
                "tailgate",
                "shitshit",
                "sooners1",
                "smartass",
                "pennywis",
                "thetruth",
                "reindeer",
                "allstate",
                "greatest",
                "caldwell",
                "fussball",
                "geneviev",
                "samadams",
                "dipstick",
                "losangel",
                "loverman",
                "pussy4me",
                "university",
                "troubles",
                "churchil",
                "crazyman",
                "cutiepie",
                "bullwink",
                "bulldawg",
                "horsemen",
                "escalade",
                "minnesot",
                "moonlight",
                "mwq6qlzo",
                "verygood",
                "bellagio",
                "sickness",
                "skeeter1",
                "phaedrus",
                "thumper1",
                "tmjxn151",
                "thematri",
                "letmeinn",
                "jeffjeff",
                "johnmish",
                "11001001",
                "allnight",
                "amatuers",
                "attorney",
                "happyman",
                "graywolf",
                "474jdvff",
                "551scasi",
                "fishtank",
                "freewill",
                "glendale",
                "frogfrog",
                "gerhardt",
                "scirocco",
                "devilman",
                "pallmall",
                "lunchbox",
                "manhatta",
                "mandarin",
                "pxx3eftp",
                "president",
                "chris123",
                "daedalus",
                "natasha1",
                "nancy123",
                "nevermin",
                "newcastle",
                "edmonton",
                "monterey",
                "violator",
                "wildstar",
                "winter99",
                "iqzzt580",
                "19741974",
                "1q2w3e4r5t",
                "bigbucks",
                "blackcoc",
                "yesterda",
                "skinhead",
                "shadow12",
                "snapshot",
                "soccer11",
                "pleasant",
                "pimpdaddy",
                "lionhear",
                "littlema",
                "lincoln1",
                "laughing",
                "redshift",
                "12locked",
                "arizona1",
                "alfarome",
                "hawthorn",
                "goodfell",
                "554uzpad",
                "flipflop",
                "garrison",
                "rustydog",
                "sandberg",
                "samsung1",
                "dreamer1",
                "detectiv",
                "dominick",
                "paladin1",
                "papabear",
                "panasonic",
                "nyyankee",
                "pussyeat",
                "princeto",
                "climbing",
                "dad2ownu",
                "daredevi",
                "necklace",
                "huskers1",
                "hornyman",
                "england1",
                "motherfucker",
                "ilovegod",
                "201jedlz",
                "wrinkle5",
                "zoomzoom",
                "09876543",
                "starlite",
                "peternorth",
                "jeepjeep",
                "joystick",
                "junkmail",
                "jojojojo",
                "rockrock",
                "rasta220",
                "andyandy",
                "auckland",
                "gooseman",
                "happydog",
                "charlie2",
                "cardinals",
                "fortune12",
                "generals",
                "division",
                "ozlq6qwm",
                "macgyver",
                "mallorca",
                "prelude1",
                "trousers",
                "aerosmit",
                "clueless",
                "delpiero",
                "nounours",
                "buckaroo",
                "honeydew",
                "hooters1",
                "hugohugo",
                "evang,li"
            ]
        }
    }
}

export default smartorg.r6.cmpts.password;