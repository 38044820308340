//@ts-nocheck
class Messaging {
    private protocols: any;

    constructor(protocols) {
        this.protocols = protocols;
    }

    /**
     * Creat a new system message
     * @param messages
     * @returns {*|Promise|axios.Promise}
     */
    public saveMessages(messages) {
        const path = this.protocols._getPath("framework/admin/broadcast/messages/save");
        const body = messages; // Array

        return this.protocols.apiPost(path, body);
    }

    adminGetAllMessages() {
        var path_ = this.protocols._getPath('framework/admin/broadcast/messages/list');

        return this.protocols.apiPost(path_, {});
    }

    /**
     * deletes all system messages
     * @returns {*|Promise|axios.Promise}
     */
    deleteAllMessages() {
        var path_ = this.protocols._getPath('framework/admin/broadcast/messages/delete');
        return this.protocols.apiDelete(path_);
    }

    /**
     * delete single message by id
     * @param id
     * @returns {*|Promise|axios.Promise}
     */
    deleteListOfMessages(idList) {
        var path_ = this.protocols._getPath('framework/admin/broadcast/messages/delete');
        var body_ = idList;
        return this.protocols.apiPost(path_, body_);
    }
    
    getMessages() {
        var path_ = this.protocols._getPath('framework/broadcast/messages/list');
        return this.protocols.apiPost(path_, {});
    }
}

export default Messaging