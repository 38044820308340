<div *ngIf="loading" style="width: 100%; height:100%; text-align: center">
    <h2>
      <i class='fa fa-spinner fa-spin'></i>
      Loading...
    </h2>
  </div>
  
  <div class="login-container" *ngIf="!loading">
    <div class="login-sub-container" style="max-width: 500px">
      <form class="login" style="max-width: 700px">
        <div class="login-logo">
          <img width="300px" src="assets/images/smartorg_logo_transparent.png"/>
        </div>
  
        <div class="login-main" style="height: 350px; padding: 25px 30px">
          <h2>We’re sorry</h2>
          <br/>
          <p style="font-size: 20px">
  
            You can’t log in because you do not have an account set up
            in the system.</p>
          <p style="font-size: 20px">
            Please contact your administrator to create an account
            for you. If you need further assistance, please contact
            <a [href]="'mailto:' + clientAdminEmail">{{ clientAdminEmail }}</a>.
          </p>
        </div>
      </form>
    </div>
  </div>
  