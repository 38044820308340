import { Component } from "@angular/core";
import { Router } from "@angular/router";
import SmartOrgService from "@app/services/smartorg.service";

@Component({
    selector: "app-request-reset",
    templateUrl: "./request-reset.component.html",
    styleUrls: ["./request-reset.component.scss"],
})
export class RequestResetComponent {
    errorMessage: string;
    usernameWarning: boolean;
    username: any;
    emailWarning: boolean;
    email: any;
    showConfirm: boolean;
    autoDirect: boolean;
    constructor(private smartorgService: SmartOrgService, private router: Router) {}
    requestReset = () => {
        this.errorMessage = "";
        this.usernameWarning = !this.username;
        this.emailWarning = !this.email;

        if (!this.username || !this.email) {
            this.errorMessage = "Please enter username and email. ";
        } else {
            this.smartorgService
                .requestResetPassword(this.username, this.email)
                .then((rs: any) => {
                    console.log(rs);
                    if (rs) {
                        this.showConfirm = true;
                        this.autoDirect = true;
                        setTimeout(() => {
                            if (this.autoDirect) {
                                this.router.navigate(["/login"]);
                            }
                        }, 10000);
                        this.errorMessage = "";
                    } else {
                        this.errorMessage = "We cannot match either username or email in our system.";
                    }
                })
                .catch((err: any) => {
                    console.error(err);
                    this.errorMessage = "We cannot match either username or email in our system.";
                });
        }
    };
    stopAutoRedirect() {
        this.autoDirect = false;
    }

    triggerEventByEnter(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.requestReset();
        }
    }
}
