import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-short-url',
  standalone: true,
  imports: [],
  templateUrl: './short-url.component.html',
  styleUrl: './short-url.component.scss'
})
export class ShortUrlComponent implements OnInit, OnDestroy{
  routeParamsSubscription: Subscription;
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    
  }
  ngOnInit(): void {
    this.routeParamsSubscription = this.activatedRoute.params.subscribe((params: any) => {
      this.router.navigate(['/workbench/home'], {
        queryParams: {
          s: params.shortUrl
        }
      });
    });
  }
  ngOnDestroy(): void {
    this.routeParamsSubscription.unsubscribe();
  }
}
