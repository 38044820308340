import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import SmartOrgService from '@app/services/smartorg.service';

@Component({
  selector: 'app-azure-login-failed',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './azure-login-failed.component.html',
  styleUrl: './azure-login-failed.component.scss'
})
export class AzureLoginFailedComponent {
  loading: boolean = true;
  clientAdminEmail: string;
  constructor(private smartOrgService: SmartOrgService) {
    this.smartOrgService.getServerConfig().then((response: any) => {
      const { clientAdminEmail } = response;
      this.clientAdminEmail = clientAdminEmail;
      this.loading = false;
  }).catch((err: any) => {
      console.error(err);
      alert('Error! ' + err.message);
      this.loading = false;
  });
  }
}
