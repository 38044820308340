import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordKitComponent } from './components/password-kit/password-kit.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [PasswordKitComponent],
  imports: [
    CommonModule, FormsModule
  ],
  exports: [PasswordKitComponent]
})
export class SharedModule { }
