export const versionLog = {
    versions: [
        {
            version: '10.0.0',
            apiVersion: '5.15.0',
            title: 'Brand new UI',
            date: 'Nov 15, 2024',
            showDate: false,
            logs: [
                'Implemented Brand new UI with the latest angular version v18'
            ]
        },
        {
            version: '8.3.3',
            apiVersion: '5.15.0',
            title: '',
            date: 'May 05, 2023',
            showDate: false,
            logs: [
                'Searchable multi-select dropdown is implemented in tree filter.',
                'Exclude category is implemented in tree filter.',
                'Rearrange universal table schema feature is implemented.'
            ]
        },
        {
            version: '8.3.2',
            apiVersion: '5.15.0',
            title: '',
            date: 'Jan 16, 2023',
            showDate: true,
            logs: [
                'Program Overview modified.',
                'Corrected issue with duplicate category value in Define Category.',
                'Corrected error with editing and saving universal table schema.',
                'Corrected tree filter - Tree filter now allows filtering of tags that are not assigned in the portfolio.'
            ]
        },
        {
            version: '8.3.1',
            apiVersion: '5.15.0',
            title: '',
            date: 'Dec 17, 2021',
            showDate: true,
            logs: [
                'Cut and paste feature implemented.',
                'Allow _ and - in category names and category entries.',
                'Recalculation saves live input type picked from database table widget.'
            ]
        },
        {
            version: '8.3.0',
            apiVersion: '5.15.0',
            title: '',
            date: 'Apr 07, 2021',
            showDate: true,
            logs: [
                'Added Goal Analysis.',
                'Added CFO Chart Group by Alternatives.',
                'Enabled Difference Waterfall.',
                'Enabled Portfolio Level P&L Chart Drill Down.',
                'Added Error checking for distributed Inputs.',
                'Added Category Reorder.',
                'Improved UI for category page at project level.',
                'Added input screen reference to date, and output.',
                'Added chart rotation for horizontal bar chart.',
                'Modified timestamp on table and chart.',
                'Enhanced robustness of node deletion process.',
                'Cleaned up user logs.'
            ]
        },
        {
            version: '8.2.3',
            apiVersion: '5.15.0',
            title: '',
            date: 'Oct 16, 2020',
            showDate: true,
            logs: [
                'Added Promise vs. Forecast tracking.',
                'Added Table Input Base Year Shift Tool.',
                'Treated dropdown as category.',
                'Included variables on input table screen.',
                'Refactored update data structure.',
                'Added data validation key in database.',
                'Fixed issue with category update through universal table.',
                'Fixed bugs in table input history tracking.',
                'Added browser compatibility check.',
                'Enhanced navigation search feature.'
            ]
        },
        {
            version: '8.2.2',
            apiVersion: '5.15.0',
            title: '',
            date: 'Jul 27, 2020',
            showDate: true,
            logs: [
                'Enabled category assignment commenting and change tracking.',
                'Enabled category renaming and reordering.',
                'Enabled category creation through excel.',
                'Enabled category export.',
                'Added right click menu for node.',
                'Implemented recalculate locked nodes feature without updating database manager.',
                'Fixed single text box save issue.'
            ]
        },
        {
            version: '8.2.1',
            apiVersion: '5.15.0',
            title: '',
            date: 'Apr 28, 2020',
            showDate: true,
            logs: [
                'Added role based download model feature.',
                'Fixed issue with Universal Table Permissions.',
                'Implemented limit on input comment.',
                'Fixed category data loss during renaming.',
                'Implemented double click node feature to fully expand/collapse subtree.',
                'Changed tab order in tree filtering modal.',
                'Added special data points for leaf and portfolio level CDF plots.',
                'Enabled image resize in rich text box.',
                'Fixed bug in compare value chart alignment.'
            ]
        },
        {
            version: '8.2.0',
            apiVersion: '5.15.0',
            title: '',
            date: 'Jan 31, 2020',
            showDate: true,
            logs: [
                'Launched onboarding tutorial.',
                'Upgraded rich-text box.',
                'Added option to turn on guided comment form for probability and range assessment.',
                'Last updates information shows up in project node tooltip.',
                'Enabled color projects nodes by last updates.',
                'Enabled recovery of deleted nodes.',
                'Added numerical filters.',
                'Added Monte Carlo mode for portfolio level distribution.',
                'Switched from CouchDB to MongoDB.'
            ]
        },
        {
            version: '8.1.6',
            apiVersion: '5.15.0',
            title: '',
            date: 'Oct 22, 2019',
            showDate: true,
            logs: [
                'Enabled category assignment at project level.',
                'Added default category to node creation and edits.',
                'Enabled Buy Up Buy Down capability.',
                'Fixed portfolio deletion issue.',
                'Added download model configuration.'
            ]
        },
        {
            version: '8.1.5',
            apiVersion: '5.15.0',
            title: '',
            date: 'Sept 25, 2019',
            showDate: true,
            logs: [
                'Fixed P&L table compare to column mismatch.',
                'Fixed Program Overview export rounding issue.',
                'Improved speed at Program Overview at program level (w/ filters).',
                'Added Universal Table error checking for database inputs.',
                'Fixed Table input comment box special character issue.'
            ]
        },
        {
            version: '8.1.4',
            apiVersion: '5.15.0',
            title: '',
            date: 'Aug 16, 2019',
            showDate: true,
            logs: [
                'Improved Program Overview performance.',
                'Improved UX for Input Table and Input Screen.',
                'Improved Universal Table Comment.',
                'Fixed Bucket Chart.',
                'Various bug fixes (expansion button, email verification).'
            ]
        },
        {
            version: '8.1.3',
            apiVersion: '5.15.0',
            title: '',
            date: 'Jul 30, 2019',
            showDate: true,
            logs: [
                'Fixed add table base year problem.',
                'Fixed recalculate record deleted node issue.',
                'First template is used if multiple templates are in a node.',
                'Fixed tree view filter after background loading.',
                'Improved Table Inputs.',
                'Browser information added to user log.',
                'Improved Universal Table comment.',
                'Improved Program Overview.'
            ]
        },
        {
            version: '8.1.2',
            apiVersion: '5.15.0',
            title: '',
            date: 'Jul 15, 2019',
            showDate: true,
            logs: [
                'Improved Recalculation and added Status.',
                'Fixed issues with platforms and CFO charts.',
                'Improved Program Overview.',
                'Added support email address configuration.',
                'Fixed Add Table export and rich text box.',
                'Fixed Share Link with Azure login.'
            ]
        },
        {
            version: '8.1.1',
            apiVersion: '5.15.0',
            title: '',
            date: 'Jun 21, 2019',
            showDate: true,
            logs: [
                'Improved Recalculation.',
                'Enable path info in financial table export.',
                'Program Overview.',
                'Template Synchronization across distributed calculation engines.'
            ]
        },
        {
            version: '8.1.0',
            apiVersion: '5.15.0',
            title: '',
            date: 'May 20, 2019',
            showDate: true,
            logs: [
                'Added tree filter.',
                'Enhanced Report Options, enable default report option on release.',
                'Added a row based special aggregation rule for add tables.',
                'Part-I of Program Overview is implemented.',
                'Added database input widget.',
                'Multiple bug fixes.',
                'Added Program Overview feature.'
            ]
        },
        {
            version: '8.0.0',
            apiVersion: '5.15.0',
            title: '',
            date: 'Mar 11, 2019',
            showDate: true,
            logs: [
                'Enhanced Universal Table, including: ' +
                'error checking for input change, ' +
                'category entry edits, ' +
                'flexible charting, supporting multiple templates',
                'Bubble Chart',
                'Group by chart drill down',
                'Chart Interpretation',
                'Advanced Filters',
                'Probablity Aggregation',
            ]
        },
        {
            version: '7.11.0',
            apiVersion: '5.14.2',
            title: '',
            date: 'Dec 27, 2018',
            showDate: true,
            logs: [
                'Compare value drilldown functionality is working.',
                'Chart Explanation is implemented',
                'Expand unassigned feature is implemented for Compare Value charts.',
                'Improvements made in Address Book and My Profile.',
                'Improvements made in Anchor-scale widget.'
            ]
        },
        {
            version: '7.10.0',
            apiVersion: '5.14.1',
            title: '',
            date: 'Nov 19, 2018',
            showDate: true,
            logs: [
                'Support bubble chart widget.',
                'Support portfolio configuration for charts and tables.',
                'Multiple bug fixes.'
            ]
        },
        {
            version: '7.9.0',
            apiVersion: '5.14.0',
            title: '',
            date: 'Oct 2, 2018',
            showDate: true,
            logs: [
                'Waterfall widget is implemented.',
                'Copy universal schema along with subnodes.',
                'Support portfolio configuration for charts and tables.',
                'Tree loading improvements.',
                'Multiple bug fixes.'
            ]
        },
        {
            version: '7.8.1',
            apiVersion: '5.13.3',
            title: '',
            date: 'Jul 23, 2018',
            showDate: true,
            logs: [
                'Fix bugs.',
                'Update report option in Compare Value.',
                'Display empty lines in Add_Tables'
            ]
        },
        {
            version: '7.8.0',
            apiVersion: '5.13.2',
            title: '',
            date: 'Jun 4, 2018',
            showDate: true,
            logs: [
                'Support broadcast messages to users.',
                'Display user action logs in table format.',
                'Upgrade UI for tables.',
                'Infrastructure upgrade.',
            ]
        },
        {
            version: '7.7.0',
            apiVersion: '5.13.1',
            title: '',
            date: 'Apr 2, 2018',
            showDate: true,
            logs: [
                'Support login by JWT.',
                'Support freeze row and column header in Table and Table Input.',
                'Ability to switch swiftly to different action is implemented.',
                'Shows warning message when unsaved data will be lost.'
            ]
        },
        {
            version: '7.6.1',
            apiVersion: '5.13.0',
            title: '',
            date: 'Feb 26, 2018',
            showDate: true,
            logs: [
                'Export table in EU format option is available for all table exports',
                'Export table in EU format - bug fixed',
                'Innovation screen\'s legends overlapping with labels - bug fixed',
                'Fixed portfolio uncertainty, CFO chart, scatter plot, innovation screen height issue.',
                'Fixed compare value duplicated bar value when color by.',
                'Fixed portfolio uncertainty shows error message when leaf node has no data',
                'Fixed image compare to introduced by MVSOutput']
        },
        {
            version: '7.6.0',
            apiVersion: '5.13.0',
            title: '',
            date: 'Jan 31, 2018',
            showDate: true,
            logs: [
                'Group-by and color-by sub-nodes produces stacked bars',
                'Portfolio uncertainty using Poland and Metalog fits',
                'Metalog shows fitted points on PDF'
            ]
        },
        {
            version: '7.5.1',
            apiVersion: '5.12.3',
            title: '',
            date: 'Nov 13, 2017',
            showDate: true,
            logs: [
                'Multiple ui bug fixes.'
            ]
        },
        {
            version: '7.5.0',
            apiVersion: '5.12.2',
            title: '',
            date: 'Oct 30, 2017',
            showDate: true,
            logs: [
                'Fixed universal IO save table input issue.',
                'Fixed ie cannot login problem',
                'Multiple ui bug fixes.',
                'Password security rules updated'
            ]
        },
        {
            version: '7.4.0',
            apiVersion: '5.12.0',
            title: '',
            date: 'Sep 15, 2017',
            showDate: true,
            logs: [
                'Give proper message when encountering network issue.',
                'Can download spreadsheet from leaf or platform node.',
                'Fixed table input handling invalid characters and empty cells.'
            ]
        },
        {
            version: '7.3.1',
            apiVersion: '5.11.1',
            title: '',
            date: 'Aug 11, 2017',
            showDate: true,
            logs: [
                'Installed in-memory database.',
                'Improved category management.',
                'Fixed Universal Table IE 11 display problem.',
                'Fixed output not showing in edit universal table.',
                'Fixed export chart on latest version (v60) of Chrome.',
                'Bug fixes.'
            ]
        },
        {
            version: '7.3.0',
            apiVersion: '5.11.1',
            title: '',
            date: 'June 22, 2017',
            showDate: true,
            logs: [
                'Upgrade universal table for better performance.',
                'Improve user interface for category manage.',
                'Bug fixes.'
            ]
        },
        {
            version: '7.2.2',
            apiVersion: '5.11.0',
            title: '',
            date: 'May 31, 2017',
            showDate: true,
            logs: [
                'Fixed table input saving in common data.',
                'Fixed resend verification email.',
                'Bug fixes.'
            ]
        },
        {
            version: '7.2.1',
            apiVersion: '5.11.0',
            title: '',
            date: 'May 22, 2017',
            showDate: true,
            logs: [
                'Only one template allowed on each node for universal table.'
            ]
        },
        {
            version: '7.2.0',
            apiVersion: '5.11.0',
            title: '',
            date: 'May 9, 2017',
            showDate: true,
            logs: [
                'Supports latest Excel models (xlsx and xlsm template files).'
            ]
        },
        {
            version: '7.1.1',
            apiVersion: '5.10.5',
            title: '',
            date: 'May 5, 2017',
            showDate: true,
            logs: [
                'Multiple bug fixes are done.'
            ]
        },
        {
            version: '7.1.0',
            apiVersion: '5.10.5',
            title: '',
            date: 'Apr 11, 2017',
            showDate: true,
            logs: [
                'Minor fixes in Universal IO Table.',
                'Universal IO Table stabilized.'
            ]
        },
        {
            version: '7.0.0',
            apiVersion: '5.10.5',
            title: '',
            date: 'Mar 29, 2017',
            showDate: true,
            logs: [
                'Official Release!'
            ]
        },
        {
            version: '6.3.0',
            apiVersion: '5.10.5',
            title: '',
            date: 'Mar 10, 2017',
            showDate: true,
            logs: [
                'Bucket chart is available.',
                'Metalog chart is available.',
                'Revised Universal Table UI.'
            ]
        },
        {
            version: '6.2.1',
            apiVersion: '5.10.4',
            title: '',
            date: 'Jan 9, 2017',
            showDate: true,
            logs: [
                'Fixed cluster menu behavior.',
                'Fixed last record not showing user and time issue.'
            ]
        },
        {
            version: '6.2.0',
            apiVersion: '5.10.4',
            title: '',
            date: 'Dec 23, 2016',
            showDate: true,
            logs: [
                'Universal Table/Exporter feature is available.',
                'Copy/paste data into Universal Table and save is implemented.'
            ]
        },
        {
            version: '6.1.4',
            apiVersion: '5.10.3',
            title: '',
            date: 'Dec 22, 2016',
            showDate: true,
            logs: [
                'Tornado-rollup is supported.',
                'Video embed support in description.',
                'Bug fixes.'
            ]
        },
        {
            version: '6.1.3',
            apiVersion: '5.10.3',
            title: '',
            date: 'Dec 15, 2016',
            showDate: true,
            logs: [
                'Change log support added.'
            ]
        },
        {
            version: '6.1.2',
            apiVersion: '5.10.0',
            title: '',
            date: 'Nov 7, 2016',
            showDate: true,
            logs: [
                'Text widget is created.',
                'Minor bug fixes and UI changes.',
                'Support forgot password.',
                'Table-input\'s save function is fixed.'
            ]
        },
        {
            version: '6.1.1',
            apiVersion: '5.10.0',
            title: '',
            date: 'Sep 27, 2016',
            showDate: true,
            logs: [
                'Image size fixed, now it should show original size or fit screen base on size.',
                'Fixed copy link for firefox.',
                'Remove orphan nodes option in recalculate.'
            ]
        },
        {
            version: '6.1.0',
            apiVersion: '5.10.0',
            title: '',
            date: 'Sep 20, 2016',
            showDate: true,
            logs: [
                'Major UI upgrade.',
                'Support selective recalculation.',
                'Bug fixes.'
            ]
        },
        {
            version: '6.0.1.8',
            apiVersion: '5.9.18',
            title: '',
            date: 'Sep 7, 2016',
            showDate: true,
            logs: [
                'Short URL feature is available.'
            ]
        },
        {
            version: '6.0.1.7',
            apiVersion: '5.9.17',
            title: '',
            date: 'Sep 1, 2016',
            showDate: true,
            logs: [
                'Bug fix - Scatter plot chart select from dropdown is fixed.'
            ]
        },
        {
            version: '6.0.1.6',
            apiVersion: '5.9.17',
            title: '',
            date: 'Aug 31, 2016',
            showDate: true,
            logs: [
                'Bug fix - Table IRR special rule on exported CSV.'
            ]
        },
        {
            version: '6.0.1.5',
            apiVersion: '5.9.17',
            title: '',
            date: 'Aug 30, 2016',
            showDate: true,
            logs: [
                'Recalculate portfolio feature allows you to recalculate all/unlocked nodes only. '
            ]
        },
        {
            version: '6.0.1.4',
            apiVersion: '5.9.16',
            title: '',
            date: 'Aug 25, 2016',
            showDate: true,
            logs: [
                'Bug fix - Rollup table IRR special rule on exported CSV.'
            ]
        },
        {
            version: '6.0.1.3',
            apiVersion: '5.9.16',
            title: '',
            date: 'Aug 22, 2016',
            showDate: true,
            logs: [
                'Bug fix - Long descriptions can be saved.'
            ]
        },
        {
            version: '6.0.1.2',
            apiVersion: '5.9.15',
            title: '',
            date: 'Aug 16, 2016',
            showDate: true,
            logs: [
                'Bug fix - Tables REPLACE special rule.'
            ]
        },
        {
            version: '6.0.1.1',
            apiVersion: '5.9.15',
            title: '',
            date: 'Aug 8, 2016',
            showDate: true,
            logs: [
                'Bug fix - Compare value table\'s total.'
            ]
        },
        {
            version: '6.0.1',
            apiVersion: '5.9.15',
            title: '',
            date: 'Jul 15, 2016',
            showDate: true,
            logs: [
                'Add-tables - Changed tooltips to clickable popover.',
                'Tables and add-tables alignment fixed.',
                'Fixed read-only bug.',
                'Input screen is improved.',
                'Recalculate portfolio is available.',
                'License and security warning available.',
                'Notifications are improved.',
                'Calculation engine log feature is available.',
                'Export current portfolio feature is available.',
                'Import portfolio feature is available.',
                'Download inputs is available.',
                'Wizard is available.'
            ]
        },
        {
            version: '6.0.0.4',
            apiVersion: '5.9.15',
            title: '',
            date: 'Jun 22, 2016',
            showDate: true,
            logs: [
                'Report options is moved to the bottom of the screen.',
                'Table input - Current input will be first entry in history.',
                'Table input - Version navigation bug is fixed.',
                'Compare value and compare uncertainty chart zoom works better.',
                'Compare uncertainty - select chart from dropdown is fixed.',
                'Major bug in selected action menu is fixed.'
            ]
        },
        {
            version: '6.0.0.3',
            apiVersion: '5.9.15',
            title: '',
            date: 'Jun 10, 2016',
            showDate: true,
            logs: [
                'Bug fix - Define categories saving.',
                'Other minor fixes.'
            ]
        },
        {
            version: '6.0.0.2',
            apiVersion: '5.9.15',
            title: '',
            date: 'Jun 9, 2016',
            showDate: true,
            logs: [
                'Bug fix - Compare uncertainty chart\'s tooltips will not show NaN anymore.',
                'Tables and exported tables match the chart order.'
            ]
        },
        {
            version: '6.0.0.1',
            apiVersion: '5.9.15',
            title: '',
            date: 'Jun 8, 2016',
            showDate: true,
            logs: [
                'Input screen does not allow to edit comments in read-only mode.',
                'Input screen - Current input will be first entry in history.'
            ]
        },
        {
            version: '6.0.0',
            apiVersion: '5.9.14',
            title: '',
            date: 'Jun 7, 2016',
            showDate: true,
            logs: [
                'First official release!'
            ]
        }, {
            version: '0.0.15',
            apiVersion: '5.9.14',
            title: '',
            date: 'Jun 7, 2016',
            showDate: true,
            logs: [
                'Create new portfolio now include templates and group restriction.',
                'Can now restrict templates and groups.',
                'Portfolio menu is improved.',
                'Portfolio permissions management is ready.',
                'Date picker feature is added to input screen.'
            ]
        }, {
            version: '0.0.14',
            apiVersion: '5.9.13',
            title: '',
            date: 'May 26, 2016',
            showDate: true,
            logs: [
                'Added create new portfolio from existing portfolio feature under management menu.',
                'Added read-only feature under edit menu.',
                'Added user-group management.',
                'Export table as CSV feature in the charts will show all the decimals.',
                'Can turn into submit ticket and ticket list from Help menu.'
            ]
        },
        {
            version: '0.0.13',
            apiVersion: '5.9.12',
            title: '',
            date: 'May 16, 2016',
            showDate: true,
            logs: [
                'Added create new portfolio feature under administration menu.',
                'Added address book for administration',
                'Added my profile for user.'
            ]
        },
        {
            version: '0.0.12.4',
            apiVersion: '5.9.11',
            title: '',
            date: 'May 10, 2016',
            showDate: true,
            logs: [
                'Fixed zooming in scatter plot and innovation screen.'
            ]
        },
        {
            version: '0.0.12.3',
            apiVersion: '5.9.11',
            title: '',
            date: 'May 10, 2016',
            showDate: true,
            logs: [
                'Fixed cannot create category.'
            ]
        },
        {
            version: '0.0.12.2',
            apiVersion: '5.9.11',
            title: '',
            date: 'May 9, 2016',
            showDate: true,
            logs: [
                'Changed Action panel color.'
            ]
        },
        {
            version: '0.0.12.1',
            apiVersion: '5.9.11',
            title: '',
            date: 'May 5, 2016',
            showDate: true,
            logs: [
                'Bugs in AddTables chart are fixed.',
                'Bugs in Tables chart are fixed.',
                'Fixed saving problem description and input screen.'
            ]
        },
        {
            version: '0.0.12',
            apiVersion: '5.9.11',
            title: '',
            date: 'May 3, 2016',
            showDate: true,
            logs: [
                'AddTables & Tables can now ignore the columns that caused its chart to stop working.'
            ]
        },
        {
            version: '0.0.11.1',
            apiVersion: '5.9.11',
            title: '',
            date: 'May 3, 2016',
            showDate: true,
            logs: [
                'Fixed slider legends overlap.'
            ]
        },
        {
            version: '0.0.11',
            apiVersion: '5.9.11',
            title: '',
            date: 'Apr 30, 2016',
            showDate: true,
            logs: [
                'Changed 3 Panel v2 view.',
                'Share links (short) now working.',
                'Common data (shared data) is now available.',
                'Assign categories available.',
                'Added slider to input screen.',
                'Display full image',
                'Bugs fixes and ui tweaks.'
            ]
        },
        {
            version: '0.0.10',
            apiVersion: '5.9.10',
            title: '',
            date: 'Apr 13, 2016',
            showDate: true,
            logs: [
                'Major change on multi panel user interface.',
                'Multiple panel options to select from.',
                'Can defined categories now.',
                'Bug fixes.'
            ]
        },
        {
            version: '0.0.9.2',
            apiVersion: '5.9.10',
            date: 'Apr 5, 2016',
            showDate: true,
            logs: [
                'Add-tables will display colors only when color by is selected.',
                'Sequence of legends in all the charts will be consistent.',
                'Chart legends works correctly when selecting color by and filtering.'
            ]
        },
        {
            version: '0.0.9.1',
            apiVersion: '5.9.10',
            date: 'Apr 1, 2016',
            showDate: true,
            logs: [
                'Delete node bug fixed.',
                'Support compare drag and drop in IE.',
                'Support export chart in IE'
            ]
        },
        {
            version: '0.0.9',
            apiVersion: '5.9.9',
            date: 'Mar 30, 2016',
            showDate: true,
            logs: [
                'Table input working now.',
                'Major bug in CFO Chart Color By is fixed.',
                'Edit Node does not allow duplicate node names',
                'Tree loading is supported up to 30 levels',
                'Show time stamp for scatter plots',
                'Bug fixes'
            ]
        },
        {
            version: '0.0.8',
            apiVersion: '5.9.9',
            date: 'Mar 16, 2016',
            showDate: true,
            logs: [
                'Tornado Chart is now working.',
                'Can load images from template now.',
                'Table at leaf level is available.',
                'Fixed desciption display.',
                'Save description.',
                'Input screen supported.',
                'Bug fixes'
            ]
        },
        {
            version: '0.0.7',
            apiVersion: '5.9.8',
            date: 'Mar 11, 2016',
            showDate: true,
            logs: [
                'Greatly improved action responding speed.',
                'Color by option is now available on portfolio level.',
                'Rollback to original resizing panel.'
            ]
        },
        {
            version: '0.0.6',
            apiVersion: '5.9.8',
            date: 'Mar 8, 2016',
            showDate: true,
            logs: [
                'Report options are now available (Except color by).',
                'Major user interface changes.',
                'Page scrolling and size fix.',
                'Change compare to drag and drop'
            ]
        },
        {
            version: '0.0.5',
            date: 'Feb 19, 2016',
            showDate: true,
            logs: [
                'Fixed cluster behavior in menu items.',
                'Added enter for tree search.',
                'Changed tree expand/collapse icon style.',
                'Login page redesigned',
                'Added error prompt for login.',
                'Remember me in login is now working.',
                'Rebuilt error prompts.',
                'Rearrange tree node available.'
            ]
        },
        {
            version: '0.0.4',
            date: 'Feb 9, 2016',
            showDate: true,
            logs: [
                'Export chart is functional.',
                'Export csv is working now.',
                'Fixed add table chart display problem.',
                'Fixed api missmatch error on edit.',
                'Fixed single leaf compare value.'
            ]
        },
        {
            version: '0.0.3',
            date: 'Feb 8, 2016',
            showDate: true,
            logs: [
                'Added search result index and total number.',
                'Show description by default. (Cannot edit for now).',
                'Can copy and paste nodes in different tree now.',
                'Got a new edit button'
            ]
        },
        {
            version: '0.0.2',
            date: 'Jan 29, 2016',
            showDate: true,
            logs: [
                'Innovation Screen is now available.',
                'Scatter Plot is now available.',
                'CFO Chart is now available.',
                'Added confirmation for deleting a tree node.'
            ]
        },
        {
            version: '0.0.1',
            title: 'Alpha',
            date: 'Jan 22, 2016',
            showDate: true,
            logs: [
                'This is the alpha version of the workbench.',
                'Navigation modules on.',
                'Compare Uncertainty is now available.',
                'Compare Value is now available.',
                'Add Tables is now available.'
            ]
        },
        {
            version: '0.0.0',
            apiVersion: '',
            title: 'Unfinished',
            date: 'Jan 22, 2016',
            showDate: true,
            logs: [
                'Navbar, tree and menu is now online.'
            ]
        },
    ]
}