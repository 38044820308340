import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import SmartOrgService from "@app/services/smartorg.service";

@Component({
    selector: "app-reset-password",
    templateUrl: "./reset-password.component.html",
    styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
    username: string;
    verified: boolean;
    key: string;
    verificationResult: boolean = false;
    logingIn: boolean;
    constructor(private smartorgService: SmartOrgService, private route: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {
        this.route.paramMap.subscribe((params: ParamMap) => {
            this.username = params.get("username") || '';
            this.key = params.get("key") || '';
            this.verifyUserAndKey();
        });
    }

    resetPassword ({newPsw}: any) {
        if (this.username && this.key && this.verificationResult) {
            this.smartorgService
                .resetPasswordThroughEmail(this.username, this.key, newPsw)
                .then((rs: any) => {
                    if (rs) {
                        this.logingIn = true;
                        this.login(this.username, newPsw);
                    } else {
                        alert("Error, wrong key or key expired, please try again.");
                    }
                })
                .catch((err: any) => {
                    console.error(err);
                    alert(err.message);
                });
        }
    };
    backToLogin() {
        this.router.navigate(["/login"]);
    }

    /**
     * Login to workbench.
     */
    login (username: string, password: string){
      this.smartorgService.authenticate({
          'username': username,
          'password': password
      }).then((userInfo: any) => {
          // console.log(userInfo);
          this.smartorgService.protocols.credentials.isAdmin =
              userInfo.is_admin;

          setTimeout(() => {
              this.loggedIn();
          }, 3);
      }).catch((err: any) => {
          alert("Unexpected error. Please contact administrator.");
          console.error(err);
      });
  };

  /**
   * What to do after successfully login.
   */
  loggedIn() {
      // Should check if needs to go into first page.
      this.router.navigate(["/workbench/home"]);
      
  };

  finishVerification() {
      this.verified = true;
  };

  verifyUserAndKey() {
      this.verified = false;
      if (this.username && this.key) {
          this.smartorgService.checkResetPasswordKey(
              this.username,
              this.key
          ).then((rs: any) => {
              this.verificationResult = rs;
              this.finishVerification();
              
          }).catch((err: any) => {
              console.error(err);
              this.finishVerification();
              window.location.href = "404.html";
          });
      }
  };
}
