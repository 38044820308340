import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { RequestResetComponent } from './pages/request-reset/request-reset.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { VerifyComponent } from './pages/verify/verify.component';
import { ShortUrlComponent } from './pages/short-url/short-url.component';
import { AzureLoginComponent } from './pages/azure-login/azure-login.component';
import { AzureLoginFailedComponent } from './pages/azure-login-failed/azure-login-failed.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'login2', component: LoginComponent },
  { path: 'azure-login', component: AzureLoginComponent },
  { path: 'azure-login-fail', component: AzureLoginFailedComponent },
  { path: 'request-reset', component: RequestResetComponent },
  { path: 'rp/:username/:key', component: ResetPasswordComponent },
  { path: 'verify', component: VerifyComponent },
  { path: 's/:shortUrl', component: ShortUrlComponent },
  { path: 'verify/:username/:key', component: VerifyComponent },
  { path: 'workbench', loadChildren: () => import('./workbench/workbench.module').then(mod => mod.WorkbenchModule) },
  { path: '', redirectTo: 'workbench', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
