<div *ngIf="!showConfirm" class="login-container">
    <div class="login-sub-container">
      <form class="login">
        <div class="login-logo">
          <img width="70%" src="assets/images/smartorg_logo.png"/>
        </div>
        <div>
          <h2>Having trouble signing in?</h2>
          <p>Please provide additional information to aid in the recovery
            process.
            If you also forgot your username,
            please contact your local admin or send a request to
            <a href="mailto:support@smartorg.com">support&#64;smartorg.com</a>.
          </p>
        </div>
  
        <div class="login-main">
          <div>
            <label for="inputUsername" class="login-label">Username</label>
            <input id="inputUsername" type="text" class="form-control"
                   placeholder="Username" required="" [(ngModel)]="username"
                   [ngModelOptions]="{standalone: true}"
                   style="border-radius: 4px;"
                   [ngClass]="{'form-error-red-border': usernameWarning}"
                   (keyup)="triggerEventByEnter($event)"/>
          </div>
  
          <div style="margin-top: 10px;">
            <label for="inputPassword"
                   class="login-label login-label-s">Email</label>
            <input id="inputPassword" type="email" class="form-control"
                   placeholder="Email" required="" [(ngModel)]="email"
                   [ngModelOptions]="{standalone: true}"
                   style="border-radius: 4px;"
                   [ngClass]="{'form-error-red-border': emailWarning}"
                   (keyup)="triggerEventByEnter($event)"/>
          </div>
  
          <div class="login-error">
            {{errorMessage}}
          </div>
          <div style="margin-top: 24px;">
            <button class="btn btn-lg btn-primary btn-block" type="submit"
                    (click)="requestReset()">Request Password Reset
            </button>
          </div>
          <div class="text-center" style="margin-top: 16px;">
            <a [routerLink]="['/login']">
              Return to sign in
            </a>
          </div>
        </div>
      </form>
    </div>
  
    <div class="navbar-fixed-bottom login-copyright">
      <div>© 2016 SmartOrg, inc. All rights reserved.</div>
    </div>
  </div>
  
  <div *ngIf="showConfirm" class="login-container">
    <div class="login-sub-container" style="text-align: center">
      <h3>We sent an email to you, please check your email.</h3>
      <h4>Redirect you to login page in 10 seconds.
        Or click <a (click)="stopAutoRedirect()"
                    href="#/login">here</a>.</h4>
      <h4>If you don't receive an email within 5 minutes, please
        check your spam box before you contacting
        <a href="mailto:support@smartorg.com">support&#64;smartorg.com</a>.</h4>
    </div>
  </div>