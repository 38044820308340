// remember-me.service.ts
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class RememberMeService {

  constructor(@Inject(DOCUMENT) private document: Document) { }

  remember(key: string, value: string) {
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + 14);
    this.document.cookie = `${key}=${value}; expires=${expireDate.toUTCString()}; path=/`;
  }

  forget(key: string) {
    this.document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  }

  read(key: string): string | null {
    const name = `${key}=`;
    const decodedCookie = decodeURIComponent(this.document.cookie);
    const cookies = decodedCookie.split(';');
    for (let c of cookies) {
      c = c.trim();
      if (c.indexOf(name) === 0) {
        return c.substring(name.length);
      }
    }
    return null;
  }
}
