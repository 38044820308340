//@ts-nocheck
export class APIError extends Error {

    constructor({status, message, statusText, timeout = 0}) {   //destructuring - similar to named params in python
        if (message === undefined) {
            message = "UnDefined!";
        }
        super(message);
        this.status = status;
        this.message = message;
        this.statusText = statusText;
        this.timeout = timeout;
    }

    toString() {
        return `APIError is ${this.message} and Status is ${this.statusText}`;
    }
}

export class AuthenticationError extends Error {

    constructor({message}) {
        super(message);
        this.message = message;
    }
}

export class ParameterError extends Error {

    constructor({message}) {
        super(message);
        this.message = message;
    }
}