import { Component, Input, Output, EventEmitter } from "@angular/core";
import passwordLogic from './logic/password';
import { SimpleChanges } from "@angular/core";

@Component({
  selector: "app-password-kit",
  templateUrl: "./password-kit.component.html",
  styleUrls: ["./password-kit.component.css"],
})
export class PasswordKitComponent {
  @Input() rule: string;
  @Input() hideCancel: string;
  @Input() pageTitle: string;
  @Input() passwordSaveFeedback: any;
  @Output() saveFn: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelFn: EventEmitter<any> = new EventEmitter<any>();

  oldValue: string;
  value: string;
  revalue: string;
  err: Array<string> = [];
  lv: Array<string> = [
    "Weak",
    "Fair",
    "Good",
    "Strong",
    "Very Strong",
    "Very Strong",
    "Very Strong",
    "Armored",
    "Bulletproof",
    "Nonbreakable",
    "Invulnerable",
    "Everlasting",
    "Hi James",
    "Call the Avengers",
    "Maybe the League?",
    "I'm warning you",
    "Stop right there!",
    "System Failed",
  ];
  rawScore = 0;
  score = 0;
  strength = 0;
  passRetype = false;
  passwordDescription = "";

  constructor() {}

  ngOnInit() {
    this.oldValue = "";
    this.value = "";
    this.revalue = "";
    this.passwordDescription =
      "Passwords must contain at least 8 characters in length, lowercase and uppercase letters, digits, special characters (e.g. @$!%*?&)";
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['passwordSaveFeedback']) {
      const newValue: any = changes['passwordSaveFeedback'].currentValue;
      this.err = [];
      if (newValue.status > 0) {
          // scope.err = args.message;
          this.err.push(newValue.message);
      } else {
          this.cleanup();
      }
    }
  }

  scorePassword() {
    this.rawScore = passwordLogic.scorePassword(this.value);
    this.score = Math.min(this.rawScore, 80) * 1.25;
    this.strength = Math.floor(this.rawScore / 20.5);
  }

  checkRetype() {
    this.passRetype = this.revalue === this.value;
    if (this.passRetype) {
      this.checkPass();
    }
  }

  hideButton = true;
  checkPass() {
    let errors: any[] = [];
    passwordLogic.checkers.forEach((checker) => {
      if (!checker.check(this.value)) {
        errors.push(checker.errorMessage());
      }
    });
    this.err = errors;
    if (this.revalue !== "" && errors.length === 0 && this.passRetype) {
      this.hideButton = false;
    }
  }

  savePassword() {
    this.err = [];
    if (!this.value) {
      this.err.push("Please enter a password.");
    } else if (this.revalue !== this.value) {
      this.err.push("Please enter a password.");
    } else {
      this.saveFn.emit({
        oldPsw: this.oldValue,
        newPsw: this.value,
      });
    }
  }

  cleanup() {
    this.oldValue = "";
    this.value = "";
    this.revalue = "";
    this.err = [];
    this.rawScore = 0;
    this.score = 0;
    this.strength = 0;
  }

  cancel() {
    this.cleanup();
    this.cancelFn.emit();
  }
}
