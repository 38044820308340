import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { versionLog } from '@app/services/versions';

//import chomolongma from '../logic/login-logic';
import { API_ENDPOINT_ENDPOINT, BACKEND_DOMAIN, USERNAME_COOKIE_KEY } from '@app/services/config';
import SmartOrgService from '@app/services/smartorg.service';

const NO_HEADER_ERROR = 'No authentication info or authentication expired.';
export function apiVersionCheck(required: string, current: string): boolean {
    if (!required) {
        return true;
    }

    if (!current) {
        return false;
    }

    if (required.trim() === current.trim()) {
        return true;
    }

    let result = false;

    const reqNumbers = required.split('.');
    const curNumbers = current.split('.');

    if (reqNumbers.length === curNumbers.length) {
        for (let i = 0; i < reqNumbers.length; i++) {
            if (parseInt(curNumbers[i]) > parseInt(reqNumbers[i])) {
                result = true;
                break;
            } else if (parseInt(curNumbers[i]) < parseInt(reqNumbers[i])) {
                break;
            }
        }
    }

    return result;
}
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LoginComponent {
    form: FormGroup;
    loading = true;
    signInMethod = 'normal';
    azureUrl = `${BACKEND_DOMAIN}/${API_ENDPOINT_ENDPOINT}/framework/login/c`;
    loginDisabled = false;
    loginMessage = '';
    usernameWarning = false;
    passwordWarning = false;
    rememberCheck = false;
    currentVersion = '';
    versionTitle = '';
    usePsw: boolean = false;
    loggingIn: boolean;
    errorMessage: any;
    requiredApiVersion: any;
    showPasswordLogin: boolean;
    //private smartorg;
    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private cookieService: CookieService,
        private smartorgService: SmartOrgService,
        private activatedRoute: ActivatedRoute
    ) {
        this.form = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            rememberCheck: [false],
        });
        this.activatedRoute.url.subscribe((params: UrlSegment[]) => {
            if (params[0]?.path && params[0].path == 'login2') {
                this.usePsw = true;
            }
        });
        //this.smartorg = new SmartOrg(BACKEND_DOMAIN, API_ENDPOINT_ENDPOINT);
    }

    ngOnInit() {
        this.loadUsernameFromCookie();
        this.autoLogin();
        //this.loadVersions(this.autoLogin.bind(this));

        const latestVersion: any = versionLog.versions.length > 0 ? versionLog.versions[0] : {};
        this.requiredApiVersion = latestVersion.apiVersion;
        this.currentVersion = latestVersion.version;
    }

    login() {
        this.loggingIn = true;
        localStorage.removeItem('PortfolioConfig');
        this.smartorgService.protocols.logout();

        const username = this.form.value.username;
        const password = this.form.value.password;

        this.usernameWarning = !username;
        this.passwordWarning = !password;

        if (!username || !password) {
            this.onErrorLogin('Please enter username and password.');
            return;
        } else {
            this.smartorgService
                .authenticate({ username, password })
                .then((userInfo: any) => {
                    this.smartorgService.protocols.credentials.isAdmin = userInfo.is_admin;

                    if (this.form.value.rememberCheck) {
                        this.rememberMe(USERNAME_COOKIE_KEY, username);
                    } else {
                        this.forgetMe(USERNAME_COOKIE_KEY);
                    }

                    if (userInfo.username) {
                        this.smartorgService.protocols.credentials.username = userInfo.username;
                    }

                    this.loginMessage = '';
                    this.loggedIn();
                    this.loggingIn = false;
                })
                .catch((err: any) => {
                    this.loggingIn = false;
                    if (err === NO_HEADER_ERROR) {
                        this.onErrorLogin('Error logging in, either header missing or database down. Please contact Administrator.');
                    } else if (err.status === 403) {
                        this.onErrorLogin('Username or password does not match our record!');
                    } else {
                        this.onErrorLogin(err);
                    }
                });
        }
    }

    onErrorLogin(errorMessage: any) {
        this.errorMessage = errorMessage;
        this.loggingIn = false;
    }

    switchLoginMethod(t: string) {
        this.signInMethod = t;
    }

    loggedIn() {
        this.router.navigate(['/workbench/home']);
    }

    triggerLoginByEnter(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.login();
        }
    }

    loadVersions() {
        this.loading = true;
        this.smartorgService
            .getServerConfig()
            .then((response: any) => {
                const { defaultAuth, apiVersion } = response;

                if (defaultAuth === 'AZURE' && !this.usePsw) {
                    this.azureUrl = `${BACKEND_DOMAIN}/${API_ENDPOINT_ENDPOINT}/framework/login/c`;
                    this.showPasswordLogin = false;
                    window.location.replace(this.azureUrl);
                    return;
                }

                if (defaultAuth === 'APPLE' && !this.usePsw) {
                    this.azureUrl = `${BACKEND_DOMAIN}/${API_ENDPOINT_ENDPOINT}/framework/login/d`;
                    this.showPasswordLogin = false;
                    window.location.replace(this.azureUrl);
                    return;
                }

                let msg = '';
                if (apiVersion.calcEngineMessage.unreachable) {
                    msg += apiVersion.calcEngineMessage.msg;
                } else {
                    if (!apiVersionCheck(this.requiredApiVersion, apiVersion.controllerVersion)) {
                        msg += 'Controller version is too old.';
                    }
                    if (apiVersion.calcEngineMessage.tooOld) {
                        msg += apiVersion.calcEngineMessage.msg;
                    }
                    if (apiVersion.monoMessage.tooOld) {
                        msg += apiVersion.monoMessage.msg;
                    }
                }

                this.stopLoadingSpin();
                if (msg !== '') {
                    this.disableLogin(msg);
                }
            })
            .catch((err: any) => {
                this.stopLoadingSpin();
                if (err.status == 404) {
                    this.disableLogin('Controller version is too old.');
                } else {
                    // if (err.status == 0)
                    this.disableLogin('Cannot access API. Hostname being used is likely a mismatch.');
                }
                console.warn(err);
            });
    }

    private loadUsernameFromCookie() {
        const username = this.cookieService.get(USERNAME_COOKIE_KEY);
        if (username) {
            this.form.patchValue({ username });
            this.rememberCheck = true;
            setTimeout(() => {
                const passwordInput = document.getElementById('inputPassword');
                if (passwordInput) {
                    passwordInput.focus();
                }
            });
        } else {
            setTimeout(() => {
                const usernameInput = document.getElementById('inputUsername');
                if (usernameInput) {
                    usernameInput.focus();
                }
            });
        }
    }

    autoLogin() {
        if (this.smartorgService.protocols.hasToken()) {
            this.smartorgService
                .authenticateThroughToken()
                .then((userInfo: any) => {
                    this.smartorgService.protocols.credentials = {
                        username: userInfo.username,
                        isAdmin: userInfo.is_admin,
                    };
                    this.loggedIn();
                })
                .catch((err: any) => {
                    console.error(err);
                    this.stopLoadingSpin();
                    this.loadVersions();
                });
        } else {
            this.stopLoadingSpin();
            this.loadVersions();
        }
    }

    stopLoadingSpin() {
        this.loading = false;
    }

    rememberMe(key: string, value: string) {
        const expireDate = new Date();
        expireDate.setDate(expireDate.getDate() + 14);
        this.cookieService.set(key, value, expireDate);
    }

    forgetMe(key: string) {
        this.cookieService.delete(key);
    }

    disableLogin(msg: string) {
        this.loginDisabled = true;
        this.loginMessage = msg;
    }
}
