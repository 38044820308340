import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class NavigationService {
    previousUrl: string;
    selectedPortfolioId: string = "";

    constructor(private router: Router) {}

    back(): void {
        if (this.selectedPortfolioId === "") {
            this.router.navigate(["/workbench/home"], { skipLocationChange: true });
        } else {
            this.router.navigate(["/workbench/home/portfolio"], { queryParams: { treeID: this.selectedPortfolioId }, skipLocationChange: true });
        }
    }

    navigateByURL(url: string): void {
        this.router.navigateByUrl(url);
    }

    navigate(route: string, params: any = {}): void {
      this.router.navigate([route], {
        queryParams: params, skipLocationChange: true
      })
    }
}
