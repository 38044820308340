export const USERNAME_COOKIE_KEY = 'wb_name';

// export const BACKEND_DOMAIN = 'https://localhost';
// export const API_ENDPOINT_ENDPOINT = 'kirk';

// export const BACKEND_DOMAIN = 'http://localhost:5000';
// export const API_ENDPOINT_ENDPOINT = '';

// export const BACKEND_DOMAIN = 'https://master.smartorg.com';
// export const API_ENDPOINT_ENDPOINT = 'kirk';

// export const BACKEND_DOMAIN = 'https://demos.smartorg.com/';
// export const API_ENDPOINT_ENDPOINT = 'kirk';

// export const BACKEND_DOMAIN = 'https://inav.smartorg.com';
// export const API_ENDPOINT_ENDPOINT = 'kirk';

// export const BACKEND_DOMAIN = 'https://master.smartorg.com';
// export const API_ENDPOINT_ENDPOINT = 'kirk';

// export const BACKEND_DOMAIN = 'https://qa.smartorg.com';
// export const API_ENDPOINT_ENDPOINT = 'kirk';

// export const BACKEND_DOMAIN = 'https://staging.smartorg.com';
// export const API_ENDPOINT_ENDPOINT = 'kirk';

export const BACKEND_DOMAIN = 'https://qa.smartorg.com';
export const API_ENDPOINT_ENDPOINT = 'kirk';

//OUTSIDE VM (Change IP on each boot)
// export const BACKEND_DOMAIN = 'http://192.168.52.138:5000';
// export const API_ENDPOINT_ENDPOINT = '';