import { Injectable } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Injectable()
export class InavDialogService {

  constructor(private dialogService: DialogService) { }

  openDialog(componentRef: any, params: any) {
    const ref = this.dialogService.open(componentRef, {
      data: {
        ...params.data,
        closeCallback: () => {
          ref.close();
        }
      },
      position: params.position ?? 'top',
      header: params.heading,
      showHeader: (typeof params.showHeader == undefined ? true : params.showHeader),
      width: params.width || '',
      height: params.height || '',
      // contentStyle: { "max-height": "500px", "overflow": "auto" },
      contentStyle: { ...params.contentStyle, "min-width": params.customWidth ? params.customWidth : "500px" },
      draggable: params.draggable !== undefined ? params.draggable : false,
      modal: params.modal !== undefined ? params.modal : true,
      focusOnShow: false
    });
    if (params.draggable) {
      ref.onDragEnd.subscribe(() => {
        this.restrictDialogPosition(ref);
      });
    }
    return ref;
  }

  restrictDialogPosition(ref: DynamicDialogRef) {
    // Access the dialog element using the document query selector
    const dialogElement = document.querySelector('.p-dialog');

    if (!dialogElement) {
      return; // Ensure the dialog element exists
    }

    // Get the dimensions of the viewport
    const { innerWidth, innerHeight } = window;

    // Get the bounding rectangle of the dialog
    const rect = dialogElement.getBoundingClientRect();

    // Calculate new position based on the current dragged position
    let newLeft = rect.left;
    let newTop = rect.top;

    let needsUpdate = false;

    // Restrict left position
    if (newLeft < 0) {
      newLeft = 0; // Align to the left edge
      needsUpdate = true;
    } else if (newLeft + rect.width > innerWidth) {
      newLeft = innerWidth - rect.width; // Align to the right edge
      needsUpdate = true;
    }

    // Restrict top position
    if (newTop < 0) {
      newTop = 0; // Align to the top edge
      needsUpdate = true;
    } else if (newTop + rect.height > innerHeight) {
      newTop = innerHeight - rect.height; // Align to the bottom edge
      needsUpdate = true;
    }

    // Only update the position if it's outside of the viewport
    if (needsUpdate) {
      // Set the new position of the dialog
      //@ts-ignore
      dialogElement.style.left = `${newLeft}px`;
      //@ts-ignore
      dialogElement.style.top = `${newTop}px`;
    }
  }


  closeAllDialog() {
    this.dialogService.dialogComponentRefMap.forEach(dialog => {
      dialog.destroy();
    });
  }
}
