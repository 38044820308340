import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import SmartOrgService from "@app/services/smartorg.service";

@Component({
    selector: "app-verify",
    templateUrl: "./verify.component.html",
    styleUrls: ["./verify.component.scss"],
})
export class VerifyComponent implements OnInit {
    verifying: boolean = true;
    verificationResult: any = false;
    username: string = "";
    constructor(private smartorgService: SmartOrgService, private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.activatedRoute.params.subscribe((params: any) => {
          this.verifyEmail(params);
        });
    }
    verifyEmail(params: any) {
        if (params.username && params.key) {
            this.username = params.username;
            this.smartorgService
                .confirmUserEmail(params.username, params.key)
                .then((result: any) => {
                    this.verificationResult = result;
                    this.verifying = false;
                })
                .catch((err: Error) => {
                    this.verifying = false;
                    console.log(err);
                    window.location.href = "404.html";
                });
        }
    }
}
