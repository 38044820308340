<div style="display: table-cell; vertical-align: middle;"
      *ngIf="verifying && username">
    <img src="assets/images/smartorg_logo.png"/>
    <h2>Welcome, {{username}}</h2>
    <p>Verifying your email address, please wait.</p>

    <div style="margin-top: 50px;">
      <a class="btn btn-primary" href="/login">Continue to Login</a>
    </div>
  </div>
  <div style="display: table-cell; vertical-align: middle;"
      *ngIf="!verifying && verificationResult && username">
    <img src="assets/images/smartorg_logo.png"/>
    <h2>Welcome, {{username}}</h2>
    <p>Your email is successfully verified.</p>

    <div style="margin-top: 50px;">
      <a class="btn btn-primary" href="/login">Continue to Login</a>
    </div>
  </div>
  <div style="display: table-cell; vertical-align: middle;"
      *ngIf="!verifying && !verificationResult && username">
    <img src="assets/images/smartorg_logo.png"/>
    <h2>Hello, {{username}}</h2>
    <p>Your email verification is failed.</p>
    <p>Please contact
      <a href="mailto:support@smartorg.com">support&#64;smartorg.com.</a></p>
  </div>

  <div style="display: table-cell; vertical-align: middle;"
      *ngIf="!verifying && !username">
    <img src="assets/images/smartorg_logo.png"/>
    <h2>Hello, whoever you are,</h2>
    <p>You are in a wrong place.</p>
    <p>Please head to the login page.</p>

    <div style="margin-top: 50px;">
      <a class="btn btn-primary" href="/login">Lets Go</a>
    </div>
  </div>