<div [hidden]="verified" class="login-container">
    <h3 class="login-sub-container" style="text-align: center">
      Verifying information, please wait..
    </h3>
  </div>
  
  <div *ngIf="verified"
      style="width: 100%; height: 100%; display: table;
      text-align: center; font-size: 18px; color: #777777;">
    <div style="display: table-cell; vertical-align: middle;"
        *ngIf="verificationResult && username">
      <img src="assets/images/smartorg_logo.png"/>
      <h2>Welcome, {{username}}</h2>
      <p>Please set your new password.</p>
  
      <div style="width: 600px; margin: 0 auto;">
        <app-password-kit
            (saveFn)="resetPassword($event)"></app-password-kit>
      </div>
  
    </div>
    <div style="display: table-cell; vertical-align: middle;"
        *ngIf="!verificationResult && username">
      <img src="assets/images/smartorg_logo.png"/>
      <h2>Hello, {{username}}</h2>
      <p>The link is invalid or expired, please try again.</p>
      <p>If you have any question please contact
        <a href="mailto:support@smartorg.com">support&#64;smartorg.com.</a></p>
    </div>
  
    <div style="display: table-cell; vertical-align: middle;"
        *ngIf="!username">
      <img src="assets/images/smartorg_logo.png"/>
      <h2>Hello, whoever you are,</h2>
      <p>You are in a wrong place.</p>
      <p>Please head to the login page.</p>
  
      <div style="margin-top: 50px;">
        <a class="btn btn-primary" [routerLink]="['/login']">Lets Go</a>
      </div>
    </div>
  </div>
  
  <div *ngIf="logingIn">
    <h3 class="login-sub-container" style="text-align: center">
      Success. Log you in now.
    </h3>
  </div>