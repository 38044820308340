//@ts-nocheck
import axios from "axios";

let CryptoJS = require("crypto-js");

import { APIError as APIErr } from "./errors";
import { AuthenticationError } from "./errors";

const createHMACget = Symbol("createHMACget");
const createHMACother = Symbol("createHMACother");

const TOKEN_COOKIE_KEY = "smartorg_api_jwt";
const TOKEN_COOKIE_KEY_TIME = "smartorg_api_jwt_time";

function setCookie(cname, cvalue, exmins) {
  let d = new Date();
  d.setTime(d.getTime() + exmins * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function eraseCookie(name) {
  setCookie(TOKEN_COOKIE_KEY, "", -1);
}

function saveToken(token) {
  setCookie(TOKEN_COOKIE_KEY, token, 35);
  setCookie(TOKEN_COOKIE_KEY_TIME, new Date().getTime(), 35);
}

function getToken() {
  return getCookie(TOKEN_COOKIE_KEY);
}

class Protocols {
  MAX_RETRY: number;
  RETRY_DELAY: number;
  endpoint_: string;
  defaultpath: string;
  path1: string;
  credentials: any;
  networkErrorHandler: Function;
  parentRef: any;

  constructor(endpoint, path_, callbackContainer) {
    this.MAX_RETRY = 10000;
    this.RETRY_DELAY = 15000; // milliseconds

    this.endpoint_ = endpoint;
    this.parentRef = callbackContainer;

    this.networkErrorHandler = (error = undefined) => {
      if (callbackContainer.networkErrorHandler) {
        callbackContainer.networkErrorHandler(error);
      } else if (error) {
        return Promise.reject(
          new APIErr({
            status: -1,
            message: error.message,
          })
        );
      }
    };

    if (typeof endpoint === "undefined" || !endpoint) {
      let endPointErrMsg =
        "Please pass in a valid endpoint - eg https://localhost:443 ";
      console.error(endPointErrMsg);
      console.warn("abbending program and exiting");
      return;
    } else {
      console.info(`endpoint supplied as ${endpoint}`);
    }

    this.defaultpath = "";
    if (typeof path_ === "undefined" || !path_) {
      console.log(`path not provided -- defaulting to ${this.defaultpath}`);
      this.path1 = this.defaultpath;
    } else {
      this.path1 = path_;
      console.log(`User defined path passed in is: ${path_}`);
    }
  }

  hasToken() {
    return getToken() || false;
  }

  logout() {
    eraseCookie(TOKEN_COOKIE_KEY);
  }

  redirectToLogin() {
    this.logout();
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
    if (!window.location.pathname.startsWith("/login")) {
      //window.location.href = "/login";
      this.parentRef.navigateToLogin();
    }
  }

  makeCredentials(credentials) {
    this.credentials = {
      username: credentials.username,
      secret: CryptoJS.MD5(credentials.password).toString(),
    };
  }

  createHmac(path, body) {
    if (
      !this.credentials ||
      !this.credentials.secret ||
      !this.credentials.username
    ) {
      let errMsg = "Please authenticate() before calling other API values";
      console.warn(this.credentials);
      console.error(errMsg);
      throw new AuthenticationError({ message: errMsg });
    } else {
      let hmac = CryptoJS.algo.HMAC.create(
        CryptoJS.algo.SHA256,
        this.credentials.secret
      );
      hmac.update(decodeURIComponent(path));

      if (typeof body !== "undefined") {
        hmac.update(JSON.stringify(body));
      }

      // let sig_ = `applicationname ${this.credentials.username}:${hmac.finalize()}`;
      // console.log(`creating hmac for ${this.credentials.username} on path ${path}`);
      // console.log(sig_);
      return `applicationname ${this.credentials.username}:${hmac.finalize()}`;
    }
  }

  createAuthString() {
    let token = getToken();
    if (token) {
      return `jwttoken ${token}`;
    } else {
      this.redirectToLogin();
      return "";
    }
  }

  _getPath(action) {
    if (!this.path1) {
      return `/${action}`;
    } else {
      return `/${this.path1}/${action}`;
    }
  }

  /**
   * Use promise to wrap a return value for setTimeout.
   * This function handles retry when network issue happens.
   *
   * @param inputFunc
   * @param retry
   * @return {*}
   */
  retry(path, authRequired, inputFunc, retry = 1) {
    if (retry <= this.MAX_RETRY) {
      let that = this;

      this.networkErrorHandler(
        new APIErr({
          status: -1,
          message: "Network connection broken.",
          timeout: this.RETRY_DELAY / 1000, // Retry time.
        })
      );

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(that.apiPromise(path, authRequired, inputFunc, retry));
        }, that.RETRY_DELAY);
      });
    } else {
      let err = new APIErr({
        status: -1,
        message: "Network Error. Maximum retry reached.",
      });

      this.networkErrorHandler(err);
      return Promise.reject(err);
    }
  }

  /**
   * Handle api call promise resolve and reject.
   *
   * @param path {string} Api path.
   * @param authRequired {boolean} Is authentication required for this call.
   * @param inputFunc {Function} Promise object.
   * @param retry
   * @param redirect {boolean} Whether redirect to login or not.
   * @returns {*|Promise|axios.Promise}
   */
  apiPromise(path, authRequired, inputFunc, retry = 0, redirect = true) {
    let url = `${this.endpoint_}${path}`;
    const authHeader = authRequired ? this.createAuthString() : null;
    if (authRequired && !authHeader) {
      return Promise.reject(
        "No authentication info or authentication expired."
      );
    }

    let promise = inputFunc(url, authHeader);
    let that = this;

    return promise
      .then((response) => {
        this.networkErrorHandler();
        if (response.data.token) {
          saveToken(response.data.token);
          return response.data.data;
        } else {
          return response.data;
        }
      })
      .catch((error) => {
        if (error.status > 0 || error.response) {
          if (error.response.status === 403) {
            const statusText = error.response.data.message;
            let errorMessage;
            if (statusText === "TOKEN_EXPIRED") {
              errorMessage = "Section time out, redirecting to login page...";
            } else if (statusText === "TOKEN_EXPIRED") {
              errorMessage = "Invalid section, redirecting to login page...";
            } else if (statusText === "AUTHENTICATION_FAILED") {
              errorMessage =
                "Failed to authenticate, redirecting to login page...";
            } else {
              errorMessage = `System Error: ${statusText}`;
            }

            let that = this;
            if (redirect) {
              setTimeout(function () {
                that.redirectToLogin();
              }, 3000);
            }

            eraseCookie(TOKEN_COOKIE_KEY);
            return Promise.reject(
              new APIErr({
                status: error.response.status,
                statusText: error.response.statusText,
                message: errorMessage,
              })
            );
          } else {
            return Promise.reject(
              new APIErr({
                status: error.response.status,
                statusText: error.response.statusText,
                message: error.response.data.message,
              })
            );
          }
        } else {
          // FIXME: This will be called if user encounter a 404.
          return that.retry(path, authRequired, inputFunc, ++retry);
        }
      });
  }

  /**
   * Handle api get request.
   *
   * @param path {string} Path of api call without endpoint.
   * @returns {*|Promise|axios.Promise}
   */
  apiGet(path) {
    return this.apiPromise(path, true, (url, authHeader) => {
      return axios.get(url, {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache", // clears cache in IE11
          Authorization: authHeader,
        },
      });
    });
  }

  /**
   * Handle api get request bypassing authentication
   *
   * @param path {string} Path of api call without endpoint.
   * @returns {*|Promise|axios.Promise}
   */
  apiGetNoAuth(path) {
    return this.apiPromise(path, false, (url, authHeader) => {
      return axios.get(url);
    });
  }

  /**
   * Handle api delete request.
   *
   * @param path {string} Path of api call without endpoint.
   * @returns {*|Promise|axios.Promise}
   */
  apiDelete(path) {
    return this.apiPromise(path, true, (url, authHeader) => {
      return axios.delete(url, {
        headers: { Authorization: authHeader },
      });
    });
  }

  /**
   * Handle api post request.
   *
   * @param path {string} Path of api call without endpoint.
   * @param body {object} Body object contains other non-string info..
   * @returns {*|Promise|axios.Promise}
   */
  apiPost(path, body) {
    return this.apiPromise(path, true, (url, authHeader) => {
      return axios.post(url, body, {
        headers: {
          Authorization: authHeader,
        },
      });
    });
  }

  apiAuth(path, body) {
    this.logout();

    const inputFunc = (url, authHeader) => {
      return axios.post(url, body, {
        headers: { Authorization: this.createHmac(path, body) },
      });
    };

    return this.apiPromise(path, false, inputFunc, 0, false);
  }

  /**
   * Handle api post request bypassing authentication.
   *
   * @param path {string} Path of api call without endpoint.
   * @param body {object} Body object contains other non-string info..
   * @returns {*|Promise|axios.Promise}
   */
  apiPostNoAuth(path, body) {
    return this.apiPromise(path, false, (url, authHeader) => {
      return axios.post(url, body);
    });
  }

  /**
   * Handle api put request.
   *
   * @param path {string} Path of api call without endpoint.
   * @param body {object} Body object contains other non-string info..
   * @returns {*|Promise|axios.Promise}
   */
  apiPut(path, body) {
    return this.apiPromise(path, true, (url, authHeader) => {
      return axios.put(url, body, {
        headers: {
          Authorization: authHeader,
        },
      });
    });
  }

  /**
   * Handle api put request bypassing authentication.
   *
   * @param path {string} Path of api call without endpoint.
   * @param body {object} Body object contains other non-string info..
   * @returns {*|Promise|axios.Promise}
   */
  apiPutNoAuth(path, body) {
    return this.apiPromise(path, false, (url, authHeader) => {
      return axios.put(url, body);
    });
  }
}

export default Protocols;
