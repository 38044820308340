import { createAction, props } from '@ngrx/store'
import { ExperimentItem, IssueItem } from '../../interfaces/issue'

export const getIssues = createAction('[Issues] Get Issues', props<{ nodeID: string }>())
export const getIssuesSuccess = createAction('[Issues] Get Issues success', props<{ issues: any, configData: any, experimentConfig?: any }>())
export const getIssuesFailure = createAction('[Issues] Get Issues failure', props<{ error: string }>())

export const getExperimentConfig = createAction('[Issues] Get Experiment Config', props<{ nodeID: string }>())
export const getExperimentConfigSuccess = createAction('[Issues] Get Experiment Config success', props<{ experimentConfig: any }>())
export const getExperimentConfigFailure = createAction('[Issues] Get Experiment Config failure', props<{ error: string }>())

export const createIssue = createAction('[Issues] Create Issue', props<{ issueGroup: string, nodeID: string, issueData: any }>())
export const createIssueFailure = createAction('[Issues] Create Issue failure', props<{ error: string }>())
export const createIssueSuccess = createAction('[Issues] Create Issue Success', props<{ issue: IssueItem }>())

export const editIssue = createAction('[Issues] Edit Issue Data', props<{ issueGroup: string, nodeID: string, issueData: IssueItem, showAlert?: boolean }>())
export const editIssueFailure = createAction('[Issues] Edit Issue failure', props<{ error: string }>())
export const editIssueSuccess = createAction('[Issues] Edit Issue Success', props<{ issue: IssueItem }>())

export const editIssueExperiment = createAction('[Issues] Edit Issue Experiment Data', props<{ issueID: string, issueGroup: string, experimentData: any }>())
export const editIssueWithExperiment = createAction('[Issues] Edit Issue and Experiment Data', props<{ issueData: IssueItem, experimentData: any, nodeID: string }>())
export const editIssueWithExperimentFailure = createAction('[Issues] Edit Issue and Experiment failure', props<{ error: string }>())
export const editIssueWithExperimentSuccess = createAction('[Issues] Edit Issue and Experiment Success', props<{ issueData: IssueItem, issueGroup: string, experimentData: any }>())

export const deleteIssue = createAction('[Issues] Delete Issue', props<{ issueGroup: string, nodeID: string, issueID: string }>())
export const deleteIssueFailure = createAction('[Issues] Delete Issue failure', props<{ error: string }>())
export const deleteIssueSuccess = createAction('[Issues] Delete Issue Success', props<{ success: any, issueID: any, issueGroup: string }>())
export const clearSuccess = createAction('[Issue] Clear Success')

export const moveIssue = createAction('[Issues] Move Issue', props<{ sourceIssueGroup: string, targetIssueGroup: string, issueData: any, nodeID: string }>())
export const moveIssueFailure = createAction('[Issues] Move Issue failure', props<{ error: string }>())
export const moveIssueSuccess = createAction('[Issues] Move Issue Success', props<{ issue: IssueItem }>())

