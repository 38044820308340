// @ts-nocheck
import { Inject, Injectable, InjectionToken } from "@angular/core";
import Protocols from "./protocols";
import Wizard from "./wizard";
import Messaging from "./messaging";
import { Observable, Subject, BehaviorSubject, takeUntil, catchError, throwError, from } from "rxjs";
import { Router } from "@angular/router";
import { AppStateInterface } from "@app/workbench/interfaces/appState.interface";
import { Store } from "@ngrx/store";
import { editIssue } from "@app/workbench/store/issues/actions";
import { HttpClient } from "@angular/common/http";

export const BACKEND_DOMAIN_TOKEN = new InjectionToken<string>("BACKEND_DOMAIN");
export const API_ENDPOINT_ENDPOINT_TOKEN = new InjectionToken<string>("API_ENDPOINT_ENDPOINT");

@Injectable({
    providedIn: "root",
})
export class SmartOrgService {
    protocols: any;
    wizard: any;
    messaging: any;
    networkErrorHandler: any;
    draggedNode: any = null;
    selectedNode: any;
    selectedActionName: string = "";
    compareToNode: any = null;
    isInav = false;
    showDescriptionAfterNodeSelection: boolean = false;

    private cancelPreviousRequests = new Subject<void>();
    alertSubject: Subject = new Subject();
    categoryConfigSubject: Subject = new Subject();
    userCredentials: BehaviorSubject = new BehaviorSubject();
    reportOptionsSubject: BehaviorSubject = new BehaviorSubject();

    private isGlobalDirtySubject = new BehaviorSubject<boolean>(false);
    isGlobalDirty$ = this.isGlobalDirtySubject.asObservable();

    private isWorkbenchLoadingSubject = new Subject<boolean>();
    isWorkbenchLoading$ = this.isWorkbenchLoadingSubject.asObservable();

    private saveWorkspaceSubject = new Subject<void>();
    saveWorkspace$ = this.saveWorkspaceSubject.asObservable();

    private portfolioAction = new Subject<string>();
    portfolioAction$ = this.portfolioAction.asObservable();
    
    private notificationAction = new Subject<string>();
    notificationAction$ = this.notificationAction.asObservable();

    private lastUpdatedSubject = new Subject<void>();
    lastUpdated$ = this.lastUpdatedSubject.asObservable();

    private resetWorkspaceSubject = new Subject<void>();
    resetWorkspace$ = this.resetWorkspaceSubject.asObservable();

    private updateDataIsValidSubject = new Subject<any>();
    dataIsValid$ = this.updateDataIsValidSubject.asObservable();

    private updateNodeDescriptionSubject = new Subject<any>();
    nodeDescription$ = this.updateNodeDescriptionSubject.asObservable();

    private workspaceTitleSubject = new Subject<string>();
    workspaceTitle$ = this.workspaceTitleSubject.asObservable();

    private loadNewPortfolioSubject = new Subject<string>();
    loadNewPortfolio$ = this.loadNewPortfolioSubject.asObservable();

    private portfolioConfigSubject = new Subject<any>();
    portfolioConfig$ = this.portfolioConfigSubject.asObservable();

    private universalTableInitSubject = new Subject<string>();
    universalTableInit$ = this.universalTableInitSubject.asObservable();

    private displayDropdownOptionsSubject = new BehaviorSubject<any>([]);
    displayDropdownOptions$ = this.displayDropdownOptionsSubject.asObservable();

    private categoryDataSubject = new Subject<any>();
    categoryData$ = this.categoryDataSubject.asObservable();

    private reloadRootSubject = new Subject<string>();
    reloadRoot$ = this.reloadRootSubject.asObservable();

    private reloadActionSubject = new Subject<string>();
    reloadAction$ = this.reloadActionSubject.asObservable();

    private updateImpactTypeSubject = new Subject<any>();
    updateImpactType$ = this.updateImpactTypeSubject.asObservable();

    reportOptionStrSubject = new BehaviorSubject<any>({});
    treeFiltersSubject = new BehaviorSubject<any>({});
    categoriesConfigData: any;

    /**
     * Any interaction with the SmartOrg API starts by creating a SmartOrg object.
     * @example
     * // If your API is at https://localhost/kirk
     * var endpoint = "https://localhost";
     * var path = "kirk";
     * var smartorg = new SmartOrg(endpoint, path);
     *
     * @param {string} endpoint The SmartOrg server endpoint
     * @param {string} path_ The path where the API is accessible
     */
    //@Inject('recaptchaContainerId') private recaptchaContainerId: string
    constructor(
        @Inject(BACKEND_DOMAIN_TOKEN) private endpoint: string,
        @Inject(API_ENDPOINT_ENDPOINT_TOKEN) private path_: string,
        private router: Router,
        private store: Store<AppStateInterface>,
        private http: HttpClient
    ) {
        this.protocols = new Protocols(this.endpoint, this.path_, this);
        this.wizard = new Wizard(this.protocols);
        this.messaging = new Messaging(this.protocols);
        console.info("SmartOrg Javascript API.");
        console.log("Manhattan Build");
        console.log("version 2.2");
        this.networkErrorHandler = null;
    }

    setCompareToNode(node: any): void {
        this.compareToNode = node;
    }

    removePortfolio(portfolioName: string) {
        this.portfolioAction.next(portfolioName);
    }
    
    refreshNotifications(notifications: any) {
        this.notificationAction.next(notifications);
    }

    setTreeFilters(filtersObj: any): void {
        this.treeFiltersSubject.next(filtersObj);
    }

    setIsGlobalDirty(value: boolean): void {
        this.isGlobalDirtySubject.next(value);
    }

    startWorkbenchLoading() {
        this.isWorkbenchLoadingSubject.next(true);
    }
    stopWorkbenchLoading() {
        this.isWorkbenchLoadingSubject.next(false);
    }

    setDisplayDropdown(options: any): void {
        this.displayDropdownOptionsSubject.next(options);
    }

    setCategoryData(data: any): void {
        this.categoryDataSubject.next(data);
    }

    updateImpactType(data: any): void {
        this.updateImpactTypeSubject.next(data);
    }

    triggerSaveWorkspace(): void {
        this.saveWorkspaceSubject.next();
    }

    triggerResetWorkspace(): void {
        this.resetWorkspaceSubject.next();
    }

    triggerLastUpdated(): void {
        this.lastUpdatedSubject.next();
    }

    triggerReloadRoot(data: string = ""): void {
        this.reloadRootSubject.next(data);
    }

    triggerReloadAction(): void {
        this.reloadActionSubject.next(null);
    }

    updateDataIsValid(data: any): void {
        this.updateDataIsValidSubject.next(data);
    }

    updateNodeDescription(data: any): void {
        this.updateNodeDescriptionSubject.next(data);
    }

    setWorkspaceTitle(value: string): void {
        setTimeout(() => {
            this.workspaceTitleSubject.next(value);
        }, 100);
    }

    setPortfolioConfig(value: any): void {
        this.portfolioConfigSubject.next(value);
    }

    loadNewPortfolio(value: string): void {
        this.loadNewPortfolioSubject.next(value);
    }

    triggerUniversalTableInit(value: string): void {
        this.universalTableInitSubject.next(value);
    }

    navigateToLogin(): void {
        this.preserveShortenURL();
        this.router.navigate(['/login']);
    }

    getAlertSubject(): Observable {
        return this.alertSubject.asObservable();
    }

    getCategoryConfigSubject(): Observable {
        return this.categoryConfigSubject.asObservable();
    }

    showAlert(type, title, message) {
        this.alertSubject.next({ type, title, message });
    }

    toggleFullScreen() {
        let isFullScreen = false;
        const elem = document.documentElement; // This selects the whole webpage
        if (!document.fullscreenElement) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
                isFullScreen = true
            }
            else if (elem.mozRequestFullScreen) { // Firefox
                elem.mozRequestFullScreen();
                isFullScreen = true
            }
            else if (elem.webkitRequestFullscreen) { // Chrome, Safari and Opera
                elem.webkitRequestFullscreen();
                isFullScreen = true
            } else if (elem.msRequestFullscreen) { // IE/Edge
                elem.msRequestFullscreen();
                isFullScreen = true
            }
        }
        else {
            // Exit fullscreen
            if (document.exitFullscreen) {
                document.exitFullscreen();
                isFullScreen = false
            }
            else if (document.mozCancelFullScreen) { // Firefox
                document.mozCancelFullScreen();
                isFullScreen = false

            }
            else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
                document.webkitExitFullscreen();
                isFullScreen = false

            }
            else if (document.msExitFullscreen) { // IE/Edge
                document.msExitFullscreen();
                isFullScreen = false
            }
        }
        return isFullScreen;
    }

    preserveShortenURL() {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams && searchParams.has('s')) {
            const key = searchParams.get('s');
            localStorage.setItem('shortUrl', key);
        }
    }

    generateReportOptionsForSubmit(reportOptions: any = {}, treeFilter: any) {
        if (treeFilter && Object.keys(treeFilter).length > 0) {
            if (reportOptions.filterLogic) {
                reportOptions.filterLogic = `(${reportOptions.filterLogic}) and (${treeFilter.filterLogic})`
            } else {
                reportOptions.filterLogic = treeFilter.filterLogic || '';
            }
        }

        return btoa(JSON.stringify(reportOptions));
    }

    updateStoreIssue(issue: any, showAlert?: boolean = false) {
        this.store.dispatch(
            editIssue({
                issueGroup: issue.type,
                nodeID: this.selectedNode._id,
                issueData: issue,
                showAlert
            })
        );
    }

    get_browser_info() {
        let ua = navigator.userAgent,
            tem,
            M = ua.match(/(opera|chrome|safari|firefox|msie|edge|trident(?=\/))\/?\s*(\d+)/i) || [];
        let edge = ua.indexOf("Edge/");
        if (edge > 0) {
            let v = parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
            return { name: "Edge", version: v };
        }
        let chromiumEdge = ua.indexOf("Edg/");
        if (M[1] && chromiumEdge > 0) {
            let v = parseInt(ua.substring(chromiumEdge + 4, ua.indexOf(".", chromiumEdge)), 10);
            return { name: "Edge", version: v };
        }
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return { name: "IE ", version: tem[1] || "" };
        }
        if (M[1] === "Chrome") {
            tem = ua.match(/\bOPR\/(\d+)/);
            if (tem != null) {
                return { name: "Opera", version: tem[1] };
            }
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) {
            M.splice(1, 1, tem[1]);
        }
        return {
            name: M[0],
            version: M[1],
        };
    }

    onNetworkError(callBackFunction) {
        this.networkErrorHandler = callBackFunction;
    }

    // REGION: Versions

    getServerConfig() {
        var path_ = this.protocols._getPath("framework/config");

        return this.protocols.apiGetNoAuth(path_);
    }

    /**
     * Get current api version number.
     *
     * @returns {*} Api version number promise.
     *
     *      //to do: Fan this is not complete needs @example
     *     then: string of version number.
     *     catchL {status: err code, statusText: status text,
     *         message: err message}
     */
    getApiVersionNumber() {
        var path_ = this.protocols._getPath("framework/api/version/number");

        return this.protocols.apiGetNoAuth(path_);
    }

    /**
     * Save INAV impact type.
     *
     * @param nodeID {string}
     * @param impactType {any}
     * @returns {boolean}
     *
     */
    saveInavImpactType(nodeID, impactType) {
        let path_ = this.protocols._getPath("domain/inav/impact_type");
        path_ = `${path_}/${nodeID}`;
        let body_ = { impactType: impactType };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Get calculation engine version number and log level.
     *
     * @return {{}} Promise.
     *     then: {logLevel: "something", versionNumber: "something"}
     *     catch: Error.
     */
    getCalculationEngineInfo() {
        var path_ = this.protocols._getPath("framework/calcengine/version");

        return this.protocols.apiGetNoAuth(path_);
    }

    /**
     * Get server date and timezone.
     *
     * @return date {string}
     */
    getServerDateTime() {
        let path_ = this.protocols._getPath("framework/datetime");

        return this.protocols.apiGetNoAuth(path_);
    }

    // REGION: Authentication

    /** @typedef {object} UserInfo
     * @property {string} uid - User ID generated from server
     */

    /**
     * @external Promise
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise Promise}
     */

    /**
     * This callback is called when the result is loaded.
     *
     * @callback PromiseAuthenticateCallbackSuccess
     * @param {UserInfo} ret - The result is loaded.
     */

    /**
     * This callback is called when the result fails to load.
     *
     * @callback PromiseAuthenticateCallbackError
     * @param {Error} err - The error that occurred while loading the result.
     */

    /** Resolves with a {@link PromiseAuthenticateCallbackSuccess}, fails with a {@link PromiseAuthenticateCallbackError}
     *
     * @typedef {Promise} PromiseAuthenticate
     */

    /**
     * Authenticate the user using the credentials provided.
     * This is the first thing that needs to happen after the SmartOrg object
     * has been created.
     *
     * @param {Object} credentials The credentials with which the user is to be authenticated.
     * @param {string} credentials.username The username
     * @param {string} credentials.password The password
     * @returns {PromiseAuthenticate} A promise to authenticate
     *
     * @example
     * // Let's assume the SmartOrg API is accessible at https://localhost/kirk
     * var smartorg = new SmartOrg("https://localhost","kirk");
     * var username = ...; // get the username from somewhere
     * var password = ...; // get the password from somewhere
     * smartorg.authenticate({'username': username, 'password': password})
     *  .then(function (userInfo) {
     *      console.info("user ID " + userInfo.uid + " logged in");
     *  })
     *  .catch(function (err) {
     *      console.error("Error occurred "+err);
     *  });
     */
    authenticate(credentials) {
        this.protocols.makeCredentials(credentials);
        var path_ = this.protocols._getPath("framework/login/a");
        path_ = `${path_}/${credentials.username}`;
        var browser = this.get_browser_info();
        var body_ = {
            browserName: browser.name,
            browserVersion: browser.version,
        };
        return this.protocols.apiAuth(path_, body_);
    }

    authenticateThroughToken() {
        var path_ = this.protocols._getPath("framework/login/b");
        var browser = this.get_browser_info();
        var body_ = {
            browserName: browser.name,
            browserVersion: browser.version,
        };
        return this.protocols.apiPost(path_, body_);
    }

    authenticateThroughAzure(credentials) {
        this.protocols.makeCredentials(credentials);
        var path_ = this.protocols._getPath("framework/login/c");
        path_ = `${path_}/${credentials.username}`;

        return this.protocols.apiAuth(path_, {});
    }

    // REGION: Verify email

    /**
     * Confirm email address by username and key. At this point user
     * should not be logged in, so this call will bypass authentication
     * and use username.
     *
     * @param username {string} Username, should be unique.
     * @param key {string} System generated one time key.
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    confirmUserEmail(username, key) {
        // Maybe user id is a better idea.
        var path_ = this.protocols._getPath("framework/user/email/confirm");
        var body_ = {
            username: username,
            key: key,
        };

        return this.protocols.apiPostNoAuth(path_, body_);
    }

    // REGION: Forgot password

    /**
     * Request reset password.
     * @param username {string}
     * @param email {string}
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    requestResetPassword(username, email) {
        var path_ = this.protocols._getPath("framework/password/request");
        var body_ = {
            username: username,
            email: email,
        };

        return this.protocols.apiPostNoAuth(path_, body_);
    }

    /**
     * Check if the username and key matches and not expired.
     *
     * @param username {string}
     * @param key {string}
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    checkResetPasswordKey(username, key) {
        var path_ = this.protocols._getPath("framework/password/reset");
        var body_ = {
            username: username,
            key: key,
        };

        return this.protocols.apiPostNoAuth(path_, body_);
    }

    /**
     * Reset password to new password. Will check the username and
     * key again to make sure everything is correct.
     *
     * @param username {string}
     * @param key {string}
     * @param newPassword {string}
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    resetPasswordThroughEmail(username, key, newPassword) {
        var path_ = this.protocols._getPath("framework/password/reset");
        var body_ = {
            username: username,
            key: key,
            newpass: newPassword,
        };

        return this.protocols.apiPutNoAuth(path_, body_);
    }

    // REGION: Super admin methods

    /**
     * Fetch lines from bottom of calculation engine log
     * @param linesFromBottom
     * @returns {string} msg - fetched lines from bottom of calculation engine log
     * @example
     * smartorg.fetchCalculationEngineLog(treeID)
     * .then(function (ret) {
     *      console.warn(ret);
     *  })
     * .catch(function (err) {
     *      handleError(err);
     *  })
     */
    fetchCalculationEngineLog(linesFromBottom) {
        var path_ = this.protocols._getPath("framework/admin/calcengine/log");
        path_ = `${path_}/${linesFromBottom}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * Fetch lines from bottom of pypeer log
     * @param daysFromToday
     * @param startTime
     * @param endTime
     * @param userName
     * @param logType
     * @returns {string} msg - fetched lines from bottom of pypeer log
     * @example
     * smartorg.fetchPypeerLog(daysFromToday, startTime, endTime, userName, logType)
     * .then(function (ret) {
     *      console.warn(ret);
     *  })
     * .catch(function (err) {
     *      handleError(err);
     *  })
     */
    fetchPypeerLog(daysFromToday, startTime, endTime, userName, logType) {
        var path_ = this.protocols._getPath("framework/admin/pypeer/log");
        var body_ = {
            daysFromToday: daysFromToday,
            startTime: startTime,
            endTime: endTime,
            userName: userName,
            logType: logType,
        };
        return this.protocols.apiPost(path_, body_);
    }

    fetchAllPypeerLog() {
        var path_ = this.protocols._getPath("framework/admin/pypeer/all_logs");
        return this.protocols.apiPost(path_);
    }

    fetchBroadcastMessages() {
        var path_ = this.protocols._getPath("framework/broadcast/messages/list");
        return this.protocols.apiPost(path_);
    }

    /**
     * Get welcome message, including license and security warning.
     *
     * @param messageType {string} Can be LICENSE and SECURITY_WARNING.
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    getWelcomeMessage(messageType) {
        var path_ = this.protocols._getPath("framework/admin/welcome/message");
        path_ = `${path_}/${messageType}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * Save impact mapping data in astro_data collection.
     *
     * @param nodeID {string}
     * @param dataGot {any}
     * @returns {boolean}
     *
     */
    saveImpactMappingData(nodeID, dataGot) {
        let path_ = this.protocols._getPath("domain/inav/impact_mapping");
        path_ = `${path_}/${nodeID}`;
        let body_ = { data: dataGot };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Set welcome message. This function will push whatever string
     * passed in without doing any encoding. So encode the message
     * before calling this.
     *
     * @param messageType {string} Can be LICENSE and SECURITY_WARNING.
     * @param message {string} Message to be save into database.
     * @param state {boolean} True for always display.
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    setWelcomeMessage(messageType, message, state) {
        var path_ = this.protocols._getPath("framework/admin/welcome/message");
        path_ = `${path_}/${messageType}`;

        var body_ = {
            message: message,
            state: state,
        };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Accept license or security warnings.
     *
     * @param messageType {string} Can be LICENSE and SECURITY_WARNING.
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    acceptWelcomeMessage(messageType) {
        var path_ = this.protocols._getPath("framework/admin/welcome/accept");
        path_ = `${path_}/${messageType}`;

        return this.protocols.apiPost(path_, {});
    }

    /**
     * Ignore user browser warnings.
     *
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    ignoreUserBrowserWarning() {
        var path_ = this.protocols._getPath("framework/user/browser/ignore");

        return this.protocols.apiPost(path_, {});
    }

    /**
     * Import portfolio
     * @param includeData
     * @param pathToImportFiles64
     * @param newTreeName64
     * @returns {string} msg - portfolio is imported
     * @example
     * smartorg.exportPortfolio(treeID)
     * .then(function (ret) {
     *      console.warn(ret);
     *  })
     * .catch(function (err) {
     *      handleError(err);
     *  })
     */
    importPortfolio(includeData, pathToImportFiles64, newTreeName64) {
        var path_ = this.protocols._getPath("domain/admin/portfolio/import");
        var body_ = {
            include_data: includeData,
            path_to_import_files64: pathToImportFiles64,
            new_tree_name64: newTreeName64,
        };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Export portfolio
     * @param nodeID
     * @returns {string} msg - entire portfolio is exported
     * @example
     * smartorg.exportPortfolio(treeID)
     * .then(function (ret) {
     *      console.warn(ret);
     *  })
     * .catch(function (err) {
     *      handleError(err);
     *  })
     */
    exportPortfolio(nodeID) {
        var path_ = this.protocols._getPath("domain/admin/portfolio/export");
        path_ = `${path_}/${nodeID}`;

        return this.protocols.apiPost(path_, {});
    }

    /**
     * Archive portfolio
     * @param nodeID
     * @param newPortfolioName
     * @param oldPortfolioName
     * @param readOnly
     * @param acl
     * @returns {string} msg - entire portfolio is archived
     * @example
     * smartorg.archivePortfolio(treeID)
     * .then(function (ret) {
     *      console.warn(ret);
     *  })
     * .catch(function (err) {
     *      handleError(err);
     *  })
     */
    archivePortfolio(nodeID, newPortfolioName, oldPortfolioName, readOnly, acl) {
        var path_ = this.protocols._getPath("domain/admin/portfolio/archive");

        var body_ = {
            nodeID: nodeID,
            newPortfolioName: newPortfolioName,
            oldPortfolioName: oldPortfolioName,
            readOnly: readOnly,
            permission: acl,
        };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Fetch all exported portfolio paths
     * @returns {string} exported portfolio path list
     * @example
     * smartorg.fetchAllExportedPortfolioPaths(treeID)
     * .then(function (ret) {
     *      console.warn(ret);
     *  })
     * .catch(function (err) {
     *      handleError(err);
     *  })
     */
    fetchAllExportedPortfolioPaths() {
        var path_ = this.protocols._getPath("domain/admin/portfolio/exported");
        path_ = `${path_}`;

        return this.protocols.apiGet(path_);
    }

    // REGION: System admin utility methods

    /**
     * This callback is called when the UserProfileByIdPromise
     * response succeeds
     *
     * @callback UserProfileByIdPromiseCallbackSuccess
     * @param {object} userInfo - A user object with all property
     *     except password
     */

    /**
     * This callback is called when the UserProfileByIdPromise
     * response fails
     *
     * @callback UserProfileByIdPromiseCallbackError
     * @param {string} message - plaintext message
     */

    /**
     * Resolves with a {@link UserProfileByIdPromiseCallbackSuccess},
     * fails with a {@link UserProfileByIdPromiseCallbackError}
     *
     * @typedef {Promise} UserProfileByIdPromise
     */

    /**
     * Read user profile by his id.
     *
     * @param userId {string} Unique user database id.
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    getUserProfileByID(userId) {
        var path_ = this.protocols._getPath("framework/admin/user/doc");
        path_ = `${path_}/${userId}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * This callback is called when the NewUserPromise
     * response succeeds
     *
     * @callback NewUserPromiseCallbackSuccess
     * @param {string} message - plaintext message
     */

    /**
     * This callback is called when the NewUserPromise
     * response fails
     *
     * @callback NewUserPromiseCallbackError
     * @param {string} message - plaintext message
     */

    /**
     * Resolves with a {@link NewUserPromiseCallbackSuccess},
     * fails with a {@link NewUserPromiseCallbackError}
     *
     * @typedef {Promise} NewUserPromise
     */

    /**
     * Creates a new user
     * @param username {string} Unique username. Server will double
     *    check if it is unique.
     * @param name {string} Full name of user in format "Last, First".
     * @param password {string} Password in plain text. x_x
     * @param email {string}
     * @param phone1 {string} In format +14081231234.
     * @param organisation {string}
     * @param defaultGroupName {string} Group name instead of id.
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    newUser(username, name, password, email, phone1, organisation, defaultGroupName) {
        //debugger;
        var path_ = this.protocols._getPath("framework/admin/user/doc");

        var body_ = {
            username: username,
            name: name,
            password: password,
            phone1: phone1,
            email: email,
            organisation: organisation,
            defaultGroup: defaultGroupName,
        };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * This callback is called when the ModifyUserPromise
     * response succeeds
     *
     * @callback ModifyUserPromiseCallbackSuccess
     * @param {string} message - plaintext message
     */

    /**
     * This callback is called when the ModifyUserPromise
     * response fails
     *
     * @callback ModifyUserPromiseCallbackError
     * @param {string} message - plaintext message
     */

    /**
     * Resolves with a {@link ModifyUserPromiseCallbackSuccess},
     * fails with a {@link ModifyUserPromiseCallbackError}
     *
     * @typedef {Promise} ModifyUserPromise
     */

    /**
     * Similar to save user profile. This one is for address book use.
     *
     * @param userID {string} Unique user database id.
     * @param username {string} Unique username. Server will double
     *    check if it is unique.
     * @param name {string} Full name of user in format "Last, First".
     * @param email {string}
     * @param phone1 {string} In format +14081231234.
     * @param organisation {string}
     * @param defaultGroupName {string} Group name instead of id.
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    modifyExistingUser(userID, username, name, email, phone1, organisation, defaultGroupName) {
        var path_ = this.protocols._getPath("framework/admin/user/doc");

        var body_ = {
            userID: userID,
            username: username,
            name: name,
            phone1: phone1,
            email: email,
            organisation: organisation,
            defaultGroup: defaultGroupName,
        };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Update user admin settings, including force user to change
     * password on login and reset user to first login status.
     *
     * @param userId {string} Unique user id (couch db doc id).
     * @param adminSettings {object} A dict contains everything needs
     *     to be set. For now it has passwordChange and
     *     resetToFirstLogin. Anything come in later just add to the
     *     dict object.
     *
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     *     Result status promise.
     *     then: status 0, no data.
     *     catch: status not 0, exception object or message array.
     */
    updateUserAdminSettings(userId, adminSettings) {
        var path_ = this.protocols._getPath("framework/admin/user/settings");
        var body_ = {
            userID: userId,
            passwordChange: adminSettings["passwordChange"],
            resetToFirstLogin: adminSettings["resetToFirstLogin"],
        };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * This callback is called when the ListUserPromise
     * response succeeds
     *
     * @callback ListUserPromiseCallbackSuccess
     * @param {Array<object>} userlist - List of user objects.
     */

    /**
     * This callback is called when the ListUserPromise
     * response fails
     *
     * @callback ListUserPromiseCallbackError
     * @param {string} message - plaintext message
     */

    /**
     * Resolves with a {@link ListUserPromiseCallbackSuccess},
     * fails with a {@link ListUserPromiseCallbackError}
     *
     * @typedef {Promise} ListUserPromise
     */

    /**
     * Get list of all user with all info except password.
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    getListOfUsers() {
        var path_ = this.protocols._getPath("framework/admin/user/list");

        return this.protocols.apiGet(path_);
    }

    /**
     * Check if email is already being used by another user.
     *
     * @param userInfo {object} user details.
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    checkUserEmail(userInfo) {
        var path_ = this.protocols._getPath("framework/admin/user/email");
        var body_ = {
            user_info: userInfo,
        };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * This callback is called when the DeleteUserPromise
     * response succeeds
     *
     * @callback DeleteUserPromiseCallbackSuccess
     * @param {string} message - plaintext message
     */

    /**
     * This callback is called when the DeleteUserPromise
     * response fails
     *
     * @callback DeleteUserPromiseCallbackError
     * @param {string} message - plaintext message
     */

    /**
     * Resolves with a {@link DeleteUserPromiseCallbackSuccess},
     * fails with a {@link DeleteUserPromiseCallbackError}
     *
     * @typedef {Promise} DeleteUserPromise
     */

    /**
     * Delete a user from database by his id.
     *
     * @param userId {string} User unique id.
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    deleteUser(userId) {
        var path_ = this.protocols._getPath("framework/admin/user/doc");
        path_ = `${path_}/${userId}`;

        return this.protocols.apiDelete(path_);
    }

    /**
     * Add a user to a group.
     *
     * @param groupId {string} Group to add a user to.
     * @param userId {string} Add this user to group.
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    groupAddUser(groupId, userId) {
        var path_ = this.protocols._getPath("framework/admin/usergroup");
        var body_ = {
            user_id: userId,
            group_id: groupId,
            action: "add",
        };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Remove a user from a group.
     *
     * @param groupId {string} Group to remove user.
     * @param userId {string} This user will be removed from given
     *     group.
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    groupRemoveUser(groupId, userId) {
        var path_ = this.protocols._getPath("framework/admin/usergroup");
        var body_ = {
            user_id: userId,
            group_id: groupId,
            action: "delete",
        };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Get a list of all groups with name, id, and list of users of
     * groups.
     *
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    getListOfGroups() {
        var path_ = this.protocols._getPath("framework/admin/group/list");

        return this.protocols.apiGet(path_);
    }

    /**
     * Get the list of chosen groups.
     *
     * @param treeId {string} Root node name as tree id.
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    getListOfChosenGroups(treeId) {
        var path_ = this.protocols._getPath("framework/admin/chosen/group");
        path_ = `${path_}/${encodeURIComponent(treeId)}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * Create a new group.
     *
     * @param groupName {string} Group name, should be unique.
     * @param description {string} Description of the group.
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    createNewGroup(groupName, description, downloadModel) {
        var path_ = this.protocols._getPath("framework/admin/group/doc");

        var body_ = {
            groupName: groupName,
            groupDescription: description,
            canDownloadModel: downloadModel,
        };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Edit a group info. Only group description can be change.
     *
     * @param groupId {string} Unique database id for group.
     * @param groupName {string} Unique group name.
     * @param description {string}
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    editGroup(groupId, groupName, description, downloadModel) {
        // Group name should be able to change but now we are
        // using group name as id in some place, so we must keep it
        // unchanged.

        var path_ = this.protocols._getPath("framework/admin/group/doc");
        path_ = `${path_}/${groupId}`;

        var body_ = {
            groupName: groupName,
            groupDescription: description,
            canDownloadModel: downloadModel,
        };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Delete a group from database.
     *
     * @param groupId {string} Unique database id for group.
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    deleteGroup(groupId) {
        // Chosen group will remain there. Need to fix sometime.
        var path_ = this.protocols._getPath("framework/admin/group/doc");
        path_ = `${path_}/${groupId}`;

        return this.protocols.apiDelete(path_);
    }

    /**
     * Admin users call this function to set other user's password.
     * Server will check if the user calling this function is admin.
     *
     * @param username {string} This function use username instead of
     *     user id. Username should also be unique.
     * @param password {string} Plain text new user password
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    adminSetPassword(username, password) {
        var path_ = this.protocols._getPath("framework/admin/password");

        var body_ = {
            username: username,
            newpassword: password,
        };

        return this.protocols.apiPost(path_, body_);
    }

    // REGION: Administrator portfolio management methods

    /**
     * Create new Portfolio
     * @param {string} newPortfolioName Name of the new portfolio
     * @returns {string} msg - create Portfolio status message
     * @example
     * smartorg.createPortfolio(newPortfolioName)
     * .then(function (ret) {
     *      console.warn(ret);
     *  })
     * .catch(function (err) {
     *      handleError(err);
     *  })
     */
    createPortfolio(newPortfolioName) {
        var path_ = this.protocols._getPath("domain/admin/portfolio/new");

        var body_ = {
            treeID: newPortfolioName,
        };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Create new Portfolio from existing portfolio
     * @param {string} newPortfolioName Name of the new portfolio
     * @param categoriesConfig
     * @param acl
     * @param univSchema
     * @param chosenTemplates
     * @param chosenGroups
     * @returns {string} msg - create Portfolio status message
     * @example
     * smartorg.createPortfolioFromExisting(newPortfolioName, categoriesConfig, acl)
     * .then(function (ret) {
     *      console.warn(ret);
     *  })
     * .catch(function (err) {
     *      handleError(err);
     *  })
     */
    createPortfolioFromExisting(newPortfolioName, categoriesConfig, acl, univSchema, chosenTemplates, chosenGroups) {
        var path_ = this.protocols._getPath("domain/admin/portfolio/new/from-exist");
        var body_ = {
            treeID: newPortfolioName,
            categoriesConfig: categoriesConfig,
            acl: acl,
            univSchema: univSchema,
            chosenTemplates: chosenTemplates,
            chosenGroups: chosenGroups,
        };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Get all templates. Maybe duplicated to templatesFor when node
     * id is empty.
     *
     * @param node_id {string}
     *
     * @returns {*}
     */
    getAllTemplates(node_id) {
        var path_ = this.protocols._getPath("domain/admin/templates/all");
        path_ = `${path_}/${encodeURIComponent(node_id)}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * Get template restriction by tree id.
     *
     * @param treeId {string} Root node name as tree id.
     *
     * @returns {*|Promise.<T>|Promise|axios.Promise} Resolved will
     *     be a list of chosen template names and a list of unchosen.
     */
    getTemplateRestrictions(treeId) {
        var path_ = this.protocols._getPath("domain/admin/portfolio/restrict/template");
        path_ = `${path_}/${encodeURIComponent(treeId)}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * Set template restriction.
     *
     * @param treeId {string} Root node name as tree id.
     * @param chosenTemplates {Array<string>} Array of chosen template
     *     names. We do not save unchosen ones.
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    setTemplateRestrictions(treeId, chosenTemplates) {
        var path_ = this.protocols._getPath("domain/admin/portfolio/restrict/template");
        path_ = `${path_}/${encodeURIComponent(treeId)}`;

        var body_ = { chosen: chosenTemplates };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Get group restrictions. Same thing as templates.
     *
     * @param treeId {string} Root node name as tree id.
     * @returns {*|Promise.<T>|Promise|axios.Promise} Two arrays of
     *     chosen and unchosen.
     */
    getGroupRestrictions(treeId) {
        var path_ = this.protocols._getPath("domain/admin/portfolio/restrict/group");
        path_ = `${path_}/${encodeURIComponent(treeId)}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * Set group restrictions.
     *
     * @param treeId {string} Root node name as tree id.
     * @param chosenGroups {Array<string>} Array of chosen group
     *     names. We do not save unchosen ones.
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    setGroupRestrictions(treeId, chosenGroups) {
        // Deleted groups will still be in this list. Need to fix.
        var path_ = this.protocols._getPath("domain/admin/portfolio/restrict/group");
        path_ = `${path_}/${encodeURIComponent(treeId)}`;

        var body_ = { chosen: chosenGroups };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Set group restrictions and template restrictions at the same
     * time.
     *
     * @param treeId {string} Root node name as tree id.
     * @param groups {Array<string>} Array of chosen group names.
     * @param templates {Array<string>} Array of chosen template
     *     names.
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    setGroupsAndTemplatesRestrictions(treeId, groups, templates) {
        var path_ = this.protocols._getPath("domain/admin/portfolio/restrict/both");
        path_ = `${path_}/${encodeURIComponent(treeId)}`;

        var body_ = {
            groups: groups,
            templates: templates,
        };

        return this.protocols.apiPut(path_, body_);
    }

    getListOfGlobalTables() {
        const path = this.protocols._getPath(`domain/admin/global-tables`);
        return this.protocols.apiGet(path);
    }

    deleteGlobalTable(tableName) {
        const path = this.protocols._getPath(`domain/admin/global-tables?tableName=${tableName}`);
        return this.protocols.apiDelete(path);
    }

    uploadGlobalTableExcel(tableName, excelFile) {
        const body = new FormData();
        body.append("file", excelFile, excelFile.name);

        const path = this.protocols._getPath(`domain/admin/global-tables?tableName=${tableName}`);
        return this.protocols.apiPost(path, body);
    }

    previewGlobalTable(tableName) {
        const path = this.protocols._getPath(`domain/admin/global-table?tableName=${tableName}`);
        return this.protocols.apiGet(path);
    }

    downloadGlobalTables($http, tableName) {
        const authHeader = this.protocols.createAuthString();
        const headers = {
            "Cache-Control": "no-cache",
            Pragma: "no-cache", // clears cache in IE11
            Authorization: authHeader,
            Accept: "application/vnd.ms-excel",
        };

        const path = this.protocols._getPath(`domain/admin/global-table-excel?tableName=${tableName}`);

        return $http.get(`${this.endpoint}${path}`, { headers, responseType: "blob" });
    }

    /**
     *
     * @param portfolioName {string}
     * @param reportOptions {{}}
     */
    setDefaultReportOptions(portfolioName, reportOptions) {
        var path_ = this.protocols._getPath("domain/admin/default-report-options");
        var body_ = { portfolioName, reportOptions };

        return this.protocols.apiPost(path_, body_);
    }

    setDefaultTreeViewFilters(portfolioName, newConfig) {
        var path_ = this.protocols._getPath("domain/admin/default-tree-filters");
        var body_ = { portfolioName, newConfig };

        return this.protocols.apiPost(path_, body_);
    }

    setDefaultTreeColors(portfolioName, showTreeColors) {
        var path_ = this.protocols._getPath("domain/admin/default-tree-colors");
        var body_ = { portfolioName, showTreeColors };

        return this.protocols.apiPost(path_, body_);
    }

    getReportOptionNumericIOList(nodeID, filter = "") {
        var path_ = this.protocols._getPath("domain/report-options/numeric/fields");
        path_ = `${path_}/${nodeID}/${encodeURIComponent(filter)}`;

        return this.protocols.apiGet(path_);
    }

    // REGION: Normal users utility methods

    /**
     * Get current logged in user profile.
     *
     * @returns {*} Get user profile promise.
     *     then: dict of user profile info.
     *     catch: error status, text and message.
     *
     * @example
     * smartorg.getUserProfile().then(
     *   function (result) {
     *     console.log(result);
     *   })
     *   .catch(function (err) {
     *     console.err(err);
     *   });
     */
    getUserProfile() {
        var path_ = this.protocols._getPath("framework/user/detail");

        return this.protocols.apiGet(path_);
    }

    /**
     * Get current logged in user group.
     *
     * @returns {*} Get user profile promise.
     *     then: dict of user profile info.
     *     catch: error status, text and message.
     *
     * @example
     * smartorg.getUserGroup().then(
     *   function (result) {
     *     console.log(result);
     *   })
     *   .catch(function (err) {
     *     console.err(err);
     *   });
     */
    getUserGroupDownloadModel() {
        var path_ = this.protocols._getPath("framework/user/groupDownloadModel");
        return this.protocols.apiGet(path_);
    }

    /**
     * This callback is called when the SaveUserProfilePromise
     * response succeeds
     *
     * @callback SaveUserProfilePromiseCallbackSuccess
     * @param {string} message - plaintext message
     */

    /**
     * This callback is called when the SaveUserProfilePromise
     * response fails
     *
     * @callback SaveUserProfilePromiseCallbackError
     * @param {string} message - plaintext message
     */

    /**
     * Resolves with a {@link SaveUserProfilePromiseCallbackSuccess},
     * fails with a {@link SaveUserProfilePromiseCallbackError}
     *
     * @typedef {Promise} SaveUserProfilePromise
     */

    /**
     * Save (update) user profile information. This one is for user
     * to change their own profiles.
     *
     * @param username {string} Username as login name.
     * @param name {string} User full name in "Lastname, Firstname"
     * @param email {string}
     * @param phone1 {string} Phone number, only + and numbers.
     * @param organisation {string}
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    saveUserProfile(username, name, email, phone1, organisation) {
        var path_ = this.protocols._getPath("framework/user/detail");

        var body_ = {
            username: username,
            name: name,
            phone1: phone1,
            email: email,
            organisation: organisation,
        };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Update user email address. Will be obselete soon.
     *
     * @param email {string} Email address.
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    updateUserEmail(email) {
        var path_ = this.protocols._getPath("framework/user/email");
        path_ = `${path_}/${email}`;

        var body_ = {};

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Call this api to change the password for user.
     *
     * @param oldpassword {string} - users: pass in clear text old
     *     password. admin use: pass in clear text string *optional*
     *     for admin reset ( must be called by user in administrators
     *     group )
     * @param newpassword {string} - clear text new user password
     * @returns {string} - status message of modifying password
     *     A promise.
     *     then: dict of result, status 0 as success, message as
     *         array of strings of messages.
     *     catch: error status, text and message.
     */
    resetPassword(oldpassword, newpassword) {
        var path_ = this.protocols._getPath("framework/user/password");

        var body_ = {
            oldpassword: oldpassword,
            newpassword: newpassword,
        };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Call this api to change password to current logged in user.
     * Special version for first time login and reset password.
     *
     * FIXME: Anyone can change current logged in users password,
     * FIXME: without knowing the current password.
     *
     * TODO: Fix this security glitch!
     *
     * @param newpassword {string} Clear text new user password
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     *     A promise.
     *     then: dict of result, status 0 as success, message as
     *         array of strings of messages.
     *     catch: error status, text and message.
     */
    changePasswordWithoutOld(newpassword) {
        var path_ = this.protocols._getPath("framework/user/password");

        var body_ = {
            newpassword: newpassword,
        };

        return this.protocols.apiPost(path_, body_);
    }

    // REGION: Zendesk

    /**
     * Generate zendesk authentication url (token) for one login.
     *
     * @returns {*} Get url promise.
     *     then: url string.
     *     catch: error status, text and message.
     *
     * @example
     * smartorg.zendeskAuthUrl().then(
     *   function (result) {
     *     console.log(result);
     *   })
     *   .catch(function (err) {
     *     console.err(err);
     *   });
     */
    zendeskAuthUrl(page) {
        var path_ = this.protocols._getPath("framework/zendesk/login");
        var body_ = {
            page: page,
        };
        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Generate zendesk authentication url (token) for one login.
     * Return to page specified by front end.
     *
     * If url is not start with "https://smartorg.zendesk.com",
     * it will redirect you to that page.
     *
     * @returns {*} Get url promise.
     *     then: url string.
     *     catch: error status, text and message.
     *
     * @example
     * smartorg.zendeskRedirect(returnTo).then(
     *   function (result) {
     *     console.log(result);
     *   })
     *   .catch(function (err) {
     *     console.err(err);
     *   });
     */
    zendeskRedirect(returnTo) {
        var path_ = this.protocols._getPath("framework/zendesk/redirect");
        var body_ = {
            returnTo: returnTo,
        };
        return this.protocols.apiPost(path_, body_);
    }

    // REGION: Portfolio Admin methods

    /**
     * This callback is called when the node is loaded.
     *
     * @callback PromiseNodeCallbackSuccess
     * @param {Node} node - The node object
     */

    /**
     * This callback is called when the result fails to load.
     *
     * @callback PromiseNodeCallbackError
     * @param {Error} err - The error that occurred while reading node.
     */

    /** Resolves with a {@link PromiseNodeCallbackSuccess}, fails with a {@link PromiseNodeCallbackError}
     *
     * @typedef {Promise} PromiseNodeResults
     */

    /**
     * Retrieves a node by its ID
     * @param {string} nodeID The ID of the node to get
     * @returns {PromiseNodeResults} A promise to return the node
     * @example
     * smartorg.nodeBy(nodeID)
     * .then(function (node) {
     *     console.log(node);
     *  })
     * .catch(function (err) {
     *       handleError(err);
     *  })
     */
    nodeBy(nodeID) {
        var path_ = this.protocols._getPath("domain/node/doc");
        path_ = `${path_}/${nodeID}`;

        return this.protocols.apiGet(path_);
    }

    nodeByPost(nodeID) {
        var path_ = this.protocols._getPath("domain/nodeByPost");
        var body_ = {
            nodeID: nodeID,
        };
        return this.protocols.apiPost(path_, body_);
    }

    getGuidance(nodeID) {
        var path_ = this.protocols._getPath("domain/guidance");
        path_ = `${path_}/${nodeID}`;

        return this.protocols.apiGet(path_);
    }

    saveGuidance(nodeID, guidance) {
        var path_ = this.protocols._getPath("domain/guidance");
        path_ = `${path_}/${nodeID}`;
        var body_ = {
            guidance: guidance,
        };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Delete Node
     * @param {string} nodeID The ID of the node to delete
     * @returns {string} msg - delete status message
     * @example
     * smartorg.deleteNode(nodeToDeleteID)
     * .then(function (ret) {
     *      console.warn(ret);
     *  })
     * .catch(function (err) {
     *      handleError(err);
     *  })
     */
    deleteNode(nodeID) {
        var path_ = this.protocols._getPath("domain/node/doc");
        path_ = `${path_}/${nodeID}`;

        return this.protocols.apiDelete(path_);
    }

    /**
     * Create a new Node under an existing portfolio
     * @param {string} parentNodeID parent node ID
     * @param {string} newNodeName new Node name
     * @param {string} templateName template name
     * @param {string} platformOrLeaf 'platform' or 'leaf'
     * @param {} parentTags
     * @param newNodeTags
     * @param newNodeDropdownTags
     * @returns {string} msg - create Node status message
     * @example
     * smartorg.createNode(parentNodeID, newNodeName, templateName, platformOrLeaf)
     *  .then(function (ret) {
     *      console.warn(ret);
     *  })
     * .catch(function (err) {
     *      handleError(err);
     *  })
     */
    createNode(parentNodeID, newNodeName, templateName, platformOrLeaf, parentTags, newNodeTags, newNodeDropdownTags) {
        var path_ = this.protocols._getPath("domain/node/doc");

        var body_ = {
            nodeID: parentNodeID,
            newNodeName: newNodeName,
            templateName: templateName,
            platformOrLeaf: platformOrLeaf,
            parentTags: parentTags,
            newNodeTags: newNodeTags,
            newNodeDropdownTags: newNodeDropdownTags,
        };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Edit Node
     * @param {string} nodeID The ID of the node to edit
     * @param {string} newNodeName The new node name
     * @param {string} templateName The template name
     * @param {Array<string>} children List of children ids.
     * @param {Array<string>} saveNodeTags
     * @returns {axios.Promise}
     * @example
     * smartorg.editNode(nodeID, newNodeName, templateName)
     * .then(function (ret) {
     *          console.warn(ret);
     *      })
     * .catch(function (err) {
     *          handleError(err);
     *      })
     *
     */
    editNode(nodeID, newNodeName, templateName, children, saveNodeTags) {
        var path_ = this.protocols._getPath("domain/node/doc");

        var body_ = {
            nodeID: nodeID,
            newNodeName: newNodeName,
            templateName: templateName,
            children: children,
            saveNodeTags: saveNodeTags,
        };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * This callback is called when the Paste Node succeeds
     *
     * @callback PromisePasteStatusCallbackSuccess
     * @param {Number} status - 0=success non-zero=failure
     * @param {string} message - e.g. 'pasted data'
     */

    /**
     * This callback is called when the Paste Node fails
     *
     * @callback PromisePasteStatusCallbackError
     * @param {Number} status - http status code, e.g. 500
     * @param {string} statusText -http status text, e.g. 'INTERNAL SERVER ERROR'
     * @param {string} message - e.g. incorrect _id 'ERROR PasteNode Exception ('_id')'
     */

    /** Resolves with a {@link PromisePasteStatusCallbackSuccess}, fails with a {@link PromisePasteStatusCallbackError}
     *
     * @typedef {Promise} PromisePasteStatus
     */

    /**
     * Paste Node
     * @param {string} targetParentNodeID parent Node ID
     * @param {string}  nodeToPaste Node ID to paste
     * @returns {PromisePasteStatus} A promise to provide status message of the Paste Node operation.

     *
     * @example
     * // to paste a node under a target parent node
     * smartorg.pasteNode(targetParentNodeID, nodeToPaste)
     *  .then(function (ret) {
     *      console.warn(ret);
     *  })
     *.catch(function (err) {
     *      handleError(err);
     *  })
     */
    pasteNode(targetParentNodeID, nodeToPaste) {
        var path_ = this.protocols._getPath("domain/node/paste");
        var body_ = {
            target_parent_id: targetParentNodeID,
            node_to_paste: nodeToPaste,
        };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Cut and paste sub-tree
     * @param targetParentNodeID
     * @param nodeToPaste
     * @returns {string} status
     * @example
     * smartorg.cutPasteNode(targetParentNodeID, nodeToPaste)
     * .then(function (ret) {
     *      console.warn(ret);
     *  })
     * .catch(function (err) {
     *      handleError(err);
     *  })
     */
    cutPasteNode(targetParentNodeID, nodeToPaste) {
        let path_ = this.protocols._getPath("domain/node/cutpaste");
        let body_ = {
            target_parent_id: targetParentNodeID,
            node_to_paste: nodeToPaste,
        };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Set a node (and its children) readonly
     * @param nodeID
     * @param includeAllChildren
     * @returns {string} msg - Made node (and its descendants) readonly
     * @example
     * smartorg.makeReadOnly(nodeID, includeAllChildren)
     * .then(function (ret) {
     *      console.warn(ret);
     *  })
     * .catch(function (err) {
     *      handleError(err);
     *  })
     */
    makeReadOnly(nodeID, includeAllChildren) {
        var path_ = this.protocols._getPath("domain/node/readonly");
        var body_ = {
            node_id: nodeID,
            attribute: "r",
            include_all_children: includeAllChildren,
        };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Set a node (and its children) editable
     * @param nodeID
     * @param includeAllChildren
     * @returns {string} msg - Made node (and its descendants) editable
     * @example
     * smartorg.makeEditable(nodeID, includeAllChildren)
     * .then(function (ret) {
     *      console.warn(ret);
     *  })
     * .catch(function (err) {
     *      handleError(err);
     *  })
     */
    makeEditable(nodeID, includeAllChildren) {
        var path_ = this.protocols._getPath("domain/node/readonly");
        var body_ = {
            node_id: nodeID,
            attribute: "rw",
            include_all_children: includeAllChildren,
        };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Fetch input data in a excel format string.
     *
     * @param treeID {string} Tree id, ie. root node name.
     * @param nodeID {string} Node id, can be any node. Will download
     *     input of a subtree from this node.
     *
     * @return {*|Promise.<T>|Promise|axios.Promise}
     *     Download input promise.
     *     then:
     *     catch:
     */
    fetchInputData(treeID, nodeID) {
        var path_ = this.protocols._getPath("domain/node/download/input");
        var body_ = {
            tree_id: treeID,
            node_id: nodeID,
        };

        return this.protocols.apiPost(path_, body_);
    }

    /** @typedef {object} CategoryConfig
     * @property {string} AppliesTo
     * @property {boolean} IsMultiSelect
     * @property {string} CategoryName - e.g. Region
     * @property {array.<string>} CategoryEntries - e.g US, Asia, Europe
     */

    /**
     * This callback is called when the categoriesConfigFor response succeeds
     *
     * @callback CategoryConfigPromiseCallbackSuccess
     * @param {CategoryConfig[]} categoryConfigs - array of CategoryConfig objectsj
     */

    /**
     * This callback is called when the categoriesConfigFor response fails
     *
     * @callback CategoryConfigPromiseCallbackError
     * @param {string} message - base64 encoded error message
     */

    /** Resolves with a {@link CategoryConfigPromiseCallbackSuccess}, fails with a {@link CategoryConfigPromiseCallbackSuccess}
     *
     * @typedef {Promise} CategoryConfigPromise
     */

    /**
     *
     *
     * @param rootNodeID
     * @returns {CategoryConfigPromise}
     * @example
     *  smartorg.categoriesConfigFor(rootNodeID)
     *    .then(function(categoryConfigs) {
     *          // categoryConfigs is an array of categoryConfig
     *          categoryConfigs.forEach(function(categoryConfig) {
     *                console.log(categoryConfig.AppliesTo);
     *                console.log(categoryConfig.IsMultiSelect);
     *                console.log(categoryConfig.CategoryName);                  //e.g. Region
     *                console.log(categoryConfig.AutoPropagateUp);
     *
     *                categoryConfig.CategoryEntries.forEach(function(entry) {
     *                      console.log("category entry "+ entry);               // e.g. US, Asia, Europe
     *                });
     *           });
     *    })
     *       .catch(function(err) {
     *           handleError(err);
     *   });
     */

    categoriesConfigFor(rootNodeID) {
        var path_ = this.protocols._getPath("domain/category/config/list");
        path_ = `${path_}/${rootNodeID}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * This callback is called when the saveCategoryConfig response succeeds
     *
     * @callback CategoryConfigSavePromiseCallbackSuccess
     * @param {string} statusMessage - status of saveCategoryConfig ( new or existing )
     */

    /** Resolves with a {@link CategoryConfigSavePromiseCallbackSuccess}, fails with a {@link CategoryConfigPromiseCallbackSuccess}
     *
     * @typedef {Promise} CategoryConfigSavePromise
     */

    /**
     * @param rootNodeID
     * @param {CategoryConfig} categoryConfig new or existing category config object ( not string )
     * @param renameEntriesTracker
     * @param changedCategoryName
     * @returns  {CategoryConfigSavePromise}
     * @example
     *  smartorg.saveCategoryConfig(rootNodeID, categoryConfig)
     *    .then(function(statusMessage) {
     *         console.log(statusMessage);
     *    })
     *       .catch(function(err) {
     *           handleError(err);
     *   });
     */
    saveCategoryConfig(rootNodeID, categoryConfig, renameEntriesTracker, changedCategoryName) {
        var path_ = this.protocols._getPath("domain/category/config/save");
        path_ = `${path_}/${rootNodeID}`;
        var body_ = { categoryConfig, renameEntriesTracker, changedCategoryName };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Delete a category from config.
     *
     * @param rootNodeID
     * @param categoryName {string} Category name, should be an existing one.
     * @returns {*}
     * @example
     *  smartorg.deleteCategoryConfig(rootNodeID, categoryName)
     *    .then(function(statusMessage) {
     *         console.log(statusMessage);
     *    })
     *       .catch(function(err) {
     *           handleError(err);
     *   });
     */
    deleteCategoryConfig(rootNodeID, categoryName) {
        var path_ = this.protocols._getPath("domain/category/config/delete");
        path_ = `${path_}/${rootNodeID}/${encodeURIComponent(categoryName)}`;

        return this.protocols.apiDelete(path_);
    }

    /**
     * Arrange categories in the config.
     *
     * @param rootNodeID
     * @param categoriesConfig
     * @returns {*}
     * @example
     *  smartorg.arrangeCategoriesConfig(rootNodeID, config)
     *    .then(function(statusMessage) {
     *         console.log(statusMessage);
     *    })
     *       .catch(function(err) {
     *           handleError(err);
     *   });
     */
    arrangeCategoriesConfig(rootNodeID, categoriesConfig) {
        let path_ = this.protocols._getPath("domain/category/config/arrange");
        path_ = `${path_}/${rootNodeID}`;
        let body_ = { categoriesConfig };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * @param treeID {string} - The treeID for which category
     *     assignment is sought
     * @returns {object} - Category assignment object
     */
    getAssignCategory(treeID) {
        var path_ = this.protocols._getPath("domain/category/assign/display");
        path_ = `${path_}/${encodeURIComponent(treeID)}`;

        return this.protocols.apiPost(path_);
    }

    /**
     * Get array of tags for a node.
     *
     * @param nodeID {string}
     * @param filter {string}
     * @returns {*} Tags promise.
     *     then: array of tags
     *     catch: {status: err code, statusText: status text,
     *         message: err message}
     *
     * @example
     * smartorg.tagsFor(nodeId).then(
     *   function (result) {
     *     console.log(result);
     *   }).catch(function (err) {
     *     console.log(err);
     *   });
     */
    tagsFor(nodeID, filter = "") {
        var path_ = this.protocols._getPath("domain/tags");

        path_ = `${path_}/${nodeID}`;

        if (filter) {
            path_ = `${path_}/${encodeURIComponent(filter)}`;
        }
        return this.protocols.apiGet(path_);
    }

    dropdownTagsFor(nodeID, filter = "") {
        var path_ = this.protocols._getPath("domain/dropdownTags");

        path_ = `${path_}/${nodeID}`;

        if (filter) {
            path_ = `${path_}/${encodeURIComponent(filter)}`;
        }

        return this.protocols.apiGet(path_);
    }

    /**
     * This callback is called when the saveTags response succeeds
     *
     * @callback SaveTagsPromisePromiseCallbackSuccess
     * @param {string} statusMessage - status of saveTags
     */

    /**
     * This callback is called when the saveTags response fails
     *
     * @callback SaveTagsPromisePromiseCallbackSuccess
     * @param {string} statusMessage - error message
     */

    /** Resolves with a {@link SaveTagsPromisePromiseCallbackSuccess}, fails with a {@link SaveTagsPromisePromiseCallbackSuccess}
     *
     * @typedef {Promise} SaveTagsPromise
     */

    /**
     * @typedef {Array<String>} TagSet
     * @example
     * //First element in the array is the node ID
     * //Second element in the array is a comma delimited string of 1 or more CategoryName:CategoryItemName, all is required
     * ["b8652f5d935cf2e22e8e5d01de065d5d","all, Region:Africa, Funded:No, CropType:legumes"]
     */

    /**
     * Saves array of tags for a node
     * @param nodeID
     * @param {Array<TagSet>} newTagString
     * @returns {SaveTagsPromise}
     * @example
     *  smartorg.saveTags(nodeId, newTagString)
     *  .then(function(msg) {
     *
     *       console.log(msg);
     * })
     * .catch(function(err) {
     *       console.error(err);
     * });
     */
    saveTags(nodeID, nodeTagList) {
        var path_ = this.protocols._getPath("domain/tags");
        path_ = `${path_}/${nodeID}`;
        var body_ = JSON.parse(nodeTagList);

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Saves array of tags for nodes (a node and its ancestors) and messages for the node
     * @param {String} nodeID
     * @param {Array<Array<String>} nodeTagList
     * @param {Object} categoryData
     * @param {String} menuID
     * @returns {*} Save Tags Data promise.
     *     then: {status: 0, message: 'Saved tags for node ...'}
     *     catch: {status: err code, statusText: status text,
     *         message: err message}
     * @example
     * smartorg.saveTagsData(nodeId, nodeTagList, categoryLogData, 'CateAssignPage')
     *  .then(function(res) {
     *       console.log(res.status, res.message);
     * })
     * .catch(function(err) {
     *       console.error(err);
     * });
     */
    saveTagsData(nodeID, nodeTagList, categoryData, menuID = "") {
        let path_ = this.protocols._getPath("domain/tagsData");
        path_ = `${path_}/${nodeID}`;
        const body_ = {
            nodeTagList: nodeTagList,
            categoryData: categoryData,
            menuID: menuID,
        };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Saves array of tags for nodes (a node and its ancestors) from assign category
     * @param {String} nodeID
     * @param {Array<Array<String>} nodeTagList
     * @param {Object} categoryData
     * @returns {*} Save Tags Data promise.
     *     then: {status: 0, message: 'Saved tags for node ...'}
     *     catch: {status: err code, statusText: status text,
     *         message: err message}
     * @example
     * smartorg.saveTagsData(nodeId, nodeTagList, categoryLogData)
     *  .then(function(msg) {
     *       console.log(msg);
     * })
     * .catch(function(err) {
     *       console.error(err);
     * });
     */
    saveAssignCategoryTagsData(nodeID, nodeTagList, categoryData) {
        let path_ = this.protocols._getPath("domain/tagsDataFromAssignCate");
        path_ = `${path_}/${nodeID}`;
        const body_ = {
            nodeTagList: nodeTagList,
            categoryData: categoryData,
        };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Get dictionary of category logs in astro_data for a node.
     *
     * @param nodeID {string}
     * @returns {*} categoryLog promise.
     *     then: dict of category logs
     *     catch: {status: err code, statusText: status text,
     *         message: err message}
     */
    categoryLogFor(nodeID) {
        var path_ = this.protocols._getPath("domain/categoryLog");
        path_ = `${path_}/${nodeID}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * Save INAV Issue data from Miro in astro_data collection.
     *
     * @param issueData {any}
     * @param nodeID {string}
     * @param nodeDataID {string}
     * @returns {boolean}
     *
     */
    saveInavIssueDataFromMiro(issueData, nodeID, nodeDataID) {
        let path_ = this.protocols._getPath("domain/inav/miro/save");
        path_ = `${path_}/${nodeID}`;
        let body_ = {
            issueData: issueData,
            nodeDataID: nodeDataID,
        };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Save INAV Issue data from Experiment Management in astro_data collection.
     *
     * @param issue {any}
     * @param nodeID {string}
     * @returns {boolean}
     *
     */
    saveInavIssueDataFromExpMgmt(issue, nodeID) {
        let path_ = this.protocols._getPath("domain/inav/expmgmt/save");
        path_ = `${path_}/${nodeID}`;
        let body_ = {
            issue: issue,
        };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Get tornado factor data associated with node for Experiment Management.
     *
     * @param nodeID {string}
     * @returns {any}
     *
     */
    getDataForInavExpMgmt(nodeID) {
        var path_ = this.protocols._getPath("domain/inav/tornado/get");
        path_ = `${path_}/${nodeID}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * Save INAV Issue data and its experiment data.
     *
     * @param issueID
     * @param experiment
     * @returns {any}
     *
     */
    saveInavExperimentData(issueID, experiment) {
        var path_ = this.protocols._getPath("domain/inav/experiment");
        path_ = `${path_}/${issueID}`;
        let body_ = {
            experiment: experiment,
        };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Save INAV Issue data and its experiment data.
     *
     * @param nodeID
     * @param issue
     * @param experiment
     * @returns {any}
     *
     */
    saveInavIssueAndExperiment(nodeID, issue, experiment) {
        var path_ = this.protocols._getPath("domain/inav/issue/experiment");
        path_ = `${path_}/${nodeID}`;
        let body_ = {
            issue: issue,
            experiment: experiment,
        };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Get INAV Issue data from astro_data collection.
     *
     * @param nodeID {string}
     * @returns {any}
     *
     */
    getInavIssueData(nodeID) {
        var path_ = this.protocols._getPath("domain/inav/get");
        path_ = `${path_}/${nodeID}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * Save INAV Issue data in inav_issue_data collection and update its id in astro_data
     *
     * @param nodeID {string}
     * @param issue
     * @returns {any}
     *
     */
    saveInavIssueData(issue, nodeID) {
        var path_ = this.protocols._getPath("domain/inav/issue");
        path_ = `${path_}/${nodeID}`;
        let body_ = {
            issue: issue,
        };
        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Get INAV Issue config data from astro_data collection.
     *
     * @param nodeID {string}
     * @returns {any}
     *
     */
    getInavDataForNode(nodeID) {
        var path_ = this.protocols._getPath("domain/inav/data_for_node");
        path_ = `${path_}/${nodeID}`;
        return this.protocols.apiGet(path_);
    }

    httpGet(path_: string): Observable<any> {
        this.cancelPreviousRequests.next();
        return this.http
            .get(`${this.endpoint}${path_}`, {
                headers: {
                    "Cache-Control": "no-cache",
                    Pragma: "no-cache",
                    Authorization: this.protocols.createAuthString(),
                },
            })
            .pipe(takeUntil(this.cancelPreviousRequests));
    }

    /**
     * Get INAV Issue data from astro_data collection with cancellation technique.
     *
     * @param nodeID {string}
     * @returns {Observable<any>}
     *
     */
    fetchDataForNode(nodeID: string): Observable<any> {
        var path_ = this.protocols._getPath("domain/inav/data_for_node");
        path_ = `${path_}/${nodeID}`;
        return this.httpGet(path_);
    }

    /**
     * Delete INAV Issue data in inav_issue_data collection
     *
     * @param issueId {string}
     * @returns {any}
     *
     */
    deleteInavIssueById(nodeID, issueId) {
        var path_ = this.protocols._getPath("domain/inav/issue/delete");
        path_ = `${path_}/${nodeID}/${issueId}`;
        return this.protocols.apiDelete(path_);
    }

    /**
     * Get INAV Issue data and its experiment data.
     *
     * @param nodeID {string}
     * @returns {any}
     *
     */
    getInavIssueAndExperiment(nodeID) {
        var path_ = this.protocols._getPath("domain/inav/issue/experiment");
        path_ = `${path_}/${nodeID}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * Save INAV Dashboard data in astro_data collection.
     *
     * @param nodeID {string}
     * @param summary {string}
     * @param recommendation {string}
     * @returns {boolean}
     *
     */
    saveInavDashboardData(nodeID, summary, recommendation) {
        let path_ = this.protocols._getPath("domain/inav/dashboard");
        path_ = `${path_}/${nodeID}`;
        let body_ = {
            summary: summary,
            recommendation: recommendation,
        };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Get INAV Dashboard data from astro_data collection.
     *
     * @param nodeID {string}
     * @returns {any}
     *
     */
    getInavDashboardData(nodeID) {
        let path_ = this.protocols._getPath("domain/inav/dashboard");
        path_ = `${path_}/${nodeID}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * Save INAV Learning Plan data in astro_data collection.
     *
     * @param nodeID {string}
     * @param description {string}
     * @param result {string}
     * @param recommendation {string}
     * @param actionMilestoneCheckpoint {string}
     * @param actionBudget {number}
     * @returns {boolean}
     *
     */
    saveInavLearningPlanData(nodeID, description, result, recommendation, actionMilestoneCheckpoint, actionBudget) {
        let path_ = this.protocols._getPath("domain/inav/learningplan");
        path_ = `${path_}/${nodeID}`;
        let body_ = {
            description: description,
            result: result,
            recommendation: recommendation,
            actionMilestoneCheckpoint: actionMilestoneCheckpoint,
            actionBudget: actionBudget,
        };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Get INAV Learning Plan data from astro_data collection.
     *
     * @param nodeID {string}
     * @returns {any}
     *
     */
    getInavLearningPlanData(nodeID) {
        let path_ = this.protocols._getPath("domain/inav/learningplan");
        path_ = `${path_}/${nodeID}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * Get INAV Miro URL from astro_data collection.
     *
     * @param nodeID {string}
     * @returns {any}
     *
     */
    getInavMiroBoard(nodeID) {
        var path_ = this.protocols._getPath("domain/inav/miroBoard");
        path_ = `${path_}/${nodeID}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * Save DFV data in astro_data collection.
     *
     * @param nodeID {string}
     * @param dfvData {any}
     * @returns {boolean}
     *
     */
    saveDFVData(nodeID, dfvData) {
        let path_ = this.protocols._getPath("domain/inav/dfv");
        path_ = `${path_}/${nodeID}`;
        let body_ = { dfv: dfvData };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Save SWOT data in astro_data collection.
     *
     * @param nodeID {string}
     * @param swotData {any}
     * @returns {boolean}
     *
     */
    saveSWOTData(nodeID, swotData) {
        let path_ = this.protocols._getPath("domain/inav/swot");
        path_ = `${path_}/${nodeID}`;
        let body_ = { swot: swotData };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Get DFV data from astro_data collection.
     *
     * @param nodeID {string}
     * @returns {boolean}
     *
     */
    getDFVData(nodeID) {
        let path_ = this.protocols._getPath("domain/inav/dfv");
        path_ = `${path_}/${nodeID}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * Get SWOT data from astro_data collection.
     *
     * @param nodeID {string}
     * @returns {boolean}
     *
     */
    getSWOTData(nodeID) {
        let path_ = this.protocols._getPath("domain/inav/swot");
        path_ = `${path_}/${nodeID}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * Get N Col Canvas Config from astro_data collection.
     *
     * @param nodeID {string}
     * @returns {boolean}
     *
     */
    getNColCanvasConfig(nodeID) {
        let path_ = this.protocols._getPath("domain/inav/n-col-canvas-config");
        path_ = `${path_}/${nodeID}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * Get DFV+SWOT data from astro_data collection.
     *
     * @param nodeID {string}
     * @returns {boolean}
     *
     */
    getDFVAndSWOTData(nodeID) {
        let path_ = this.protocols._getPath("domain/inav/dfv-swot");
        path_ = `${path_}/${nodeID}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * Get Ability to Launch data from astro_data collection.
     *
     * @param nodeID {string}
     * @returns {boolean}
     *
     */
    getAbilityToLaunch(nodeID) {
        let path_ = this.protocols._getPath("domain/inav/ability_to_launch");
        path_ = `${path_}/${nodeID}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * Save Ability To Launch data in astro_data collection.
     *
     * @param nodeID {string}
     * @param dataGot {any}
     * @returns {boolean}
     *
     */
    saveAbilityToLaunchData(nodeID, dataGot) {
        let path_ = this.protocols._getPath("domain/inav/ability_to_launch");
        path_ = `${path_}/${nodeID}`;
        let body_ = { data: dataGot };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Get Long-term-ALT Uncertainty data from astro_data collection.
     *
     * @param nodeID {string}
     * @returns {boolean}
     *
     */
    getLtUncertainAltData(nodeID) {
        let path_ = this.protocols._getPath("domain/inav/alt_lt_uncertainty");
        path_ = `${path_}/${nodeID}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * Save Long-term Uncertainty data in astro_data collection.
     *
     * @param nodeID {string}
     * @param dataGot {any}
     * @returns {boolean}
     *
     */
    saveLtUncertainData(nodeID, dataGot) {
        let path_ = this.protocols._getPath("domain/inav/lt_uncertainty");
        path_ = `${path_}/${nodeID}`;
        let body_ = { data: dataGot };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Save DFV+SWOT data in astro_data collection.
     *
     * @param nodeID {string}
     * @param dataGot {any}
     * @returns {boolean}
     *
     */
    saveDFVAndSWOTData(nodeID, dataGot) {
        let path_ = this.protocols._getPath("domain/inav/dfv-swot");
        path_ = `${path_}/${nodeID}`;
        let body_ = { data: dataGot };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Save INAV Miro URL in astro_data collection.
     *
     * @param nodeID {string}
     * @param html {string}
     * @returns {boolean}
     *
     */
    saveInavMiroBoard(nodeID, html = "") {
        let path_ = this.protocols._getPath("domain/inav/miroBoard");
        path_ = `${path_}/${nodeID}`;
        let body_ = { miroBoard: html };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Get user privilege.
     *
     * @param nodeId {string}
     * @returns user privilege level {number}
     */
    getUserPrivilege(nodeId) {
        var path_ = this.protocols._getPath("domain/portfolio/userPrivilege");
        path_ = `${path_}/${encodeURIComponent(nodeId)}`;

        return this.protocols.apiGet(path_);
    }

    // REGION: Portfolio Admin utility methods

    /**
     * Get access control list through tree id.
     *
     * @param treeId {string} Root node name as tree id.
     * @returns {*|Promise.<T>|Promise|axios.Promise} Resolved will
     *     get a acl object with owner and acl groups.
     */
    getAcl(treeId) {
        var path_ = this.protocols._getPath("domain/portfolio/acl");
        path_ = `${path_}/${encodeURIComponent(treeId)}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * Set acl info.
     *
     * @param treeId {string} Root node name as tree id.
     * @param acl {object} Though we request acl object here, we only
     *     save group info into database.
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    setAcl(treeId, acl) {
        var path_ = this.protocols._getPath("domain/portfolio/acl");
        path_ = `${path_}/${encodeURIComponent(treeId)}`;

        var body_ = {
            acl: acl,
        };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Fetch change log at or under current node.
     *
     * @param {string} nodeID - The ID of the node at or under which change log is to be fetched
     * @returns {Array<changes>}
     */
    changeLog(nodeID) {
        var path_ = this.protocols._getPath("domain/portfolio/changes");
        path_ = `${path_}/${nodeID}`;

        return this.protocols.apiGet(path_, {});
    }

    /**
     * Recalculate entire portfolio
     * @param treeId
     * @param recalType
     * @param startNodeId
     * @param numberOfNodes
     * @returns {string} msg - tree is recalculated
     * @example
     * smartorg.recalculatePortfolio(treeID)
     * .then(function (ret) {
     *      console.warn(ret);
     *  })
     * .catch(function (err) {
     *      handleError(err);
     *  })
     */
    recalculatePortfolio(treeId, recalType, startNodeId, numberOfNodes) {
        var path_ = this.protocols._getPath("domain/portfolio/recalculate");
        path_ = `${path_}/${encodeURIComponent(treeId)}`;

        var body_ = { recalType, startNodeId, numberOfNodes };

        return this.protocols.apiPost(path_, body_);
    }

    recalculateNodes(treeId, nodeIds, seriesId) {
        var path_ = this.protocols._getPath("domain/portfolio/recalculate-nodes");
        path_ = `${path_}/${encodeURIComponent(treeId)}`;

        return this.protocols.apiPost(path_, { nodeIds, seriesId });
    }

    getRecalculateRecord(treeId) {
        var path_ = this.protocols._getPath("domain/portfolio/recalculate");
        path_ = `${path_}/${encodeURIComponent(treeId)}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * Get the config for portfolio.
     *
     * @param treeID {string} Tree id aka root node name.
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     *     then: number of nodes.
     */
    getPortfolioConfig(treeID) {
        var path_ = this.protocols._getPath("domain/portfolio/config");
        path_ = `${path_}/${encodeURIComponent(treeID)}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * Save config for portfolio.
     *
     * @param treeID {string} Tree id aka root node name.
     * @param portfolioConfig
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    savePortfolioConfig(treeID, portfolioConfig) {
        var path_ = this.protocols._getPath("domain/portfolio/config");
        path_ = `${path_}/${encodeURIComponent(treeID)}`;
        var body_ = {
            portfolioConfig: portfolioConfig,
        };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Get subTree name of promise year of portfolio
     *
     * @param treeID {string} Tree id aka root node name.
     * @param treeID {string} Tree id aka root node name.
     * @returns {string} nodeID of subTree node with promise year
     */
    getPortfolioPromise(treeID) {
        var path_ = this.protocols._getPath("domain/portfolio/promise");
        path_ = `${path_}/${encodeURIComponent(treeID)}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * Save promise subTree for portfolio.
     *
     * @param treeID {string} Tree id aka root node name.
     * @param portfolioConfig
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     */
    savePortfolioPromise(treeID, promise) {
        var path_ = this.protocols._getPath("domain/portfolio/promise");
        path_ = `${path_}/${encodeURIComponent(treeID)}`;
        var body_ = {
            promise: promise,
        };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Get the total number of orphan nodes in tree.
     *
     * @param treeID {string} Tree id aka root node name.
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     *     then: number of nodes.
     */
    getOrphanNodesCount(treeID) {
        var path_ = this.protocols._getPath("domain/portfolio/orphan");
        path_ = `${path_}/${encodeURIComponent(treeID)}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * Fix (delete) all orphan nodes in tree.
     *
     * @param treeID {string} Tree id aka root node name.
     * @returns {*|Promise.<T>|Promise|axios.Promise}
     *     then: number of deleted nodes.
     */
    fixOrphanNodesCount(treeID) {
        var path_ = this.protocols._getPath("domain/portfolio/orphan");
        path_ = `${path_}/${encodeURIComponent(treeID)}`;

        return this.protocols.apiDelete(path_);
    }

    // REGION: Portfolio Admin universal IO methods

    /**
     * Lookup inputs and outputs that need to go into the universal table
     * by key. We need to supply nodeID, and at least one of
     * input or output keys (or both).
     * @param nodeID {string} Tree node Id.
     * @param schema {object} - contains selectedInputKeys {Array},
     *      selectedOutputKeys {Array} and selectedTableInputKeys {object}
     * @param packedReportOptions - contains encoded reportOptions
     * @param packedExcludeFilterTags
     * @returns list for fields to be displayed in universal output
     */
    getFieldList(nodeID, schema, packedReportOptions, packedExcludeFilterTags) {
        var path_ = this.protocols._getPath("domain/universal/io/fields");
        var body = {
            nodeID: nodeID,
            schema: schema,
            packedReportOptions: packedReportOptions,
            packedExcludeFilterTags: packedExcludeFilterTags,
        };
        return from(this.protocols.apiPost(path_, body)).pipe(
            catchError(error => {
                console.error('Error:', error);
                this.showAlert("danger", "Error!", error.message);
                return throwError('Something went wrong; please try again later.');
            }
            ));
    }

    /**
     * lookup inputs from astro_data and gives the specific fields
     * @param templateName
     * @param leafOrPlatform
     * @param nodeID {string} Tree node Id.
     * @returns list for fields to be displayed in universal output
     */
    getDisplayNameList(templateName, leafOrPlatform, nodeID) {
        var path_ = this.protocols._getPath("domain/universal/io/schema/fields");
        var body_ = {
            template_name: templateName,
            leaf_or_platform: leafOrPlatform,
            node_id: nodeID,
        };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * saving univSchema to rootNode
     * @param nodeID {string}
     * @param schema - has templateName, IOKeys & access
     * @param oldName - to check and replace it with new schema name
     */
    saveUnivSchemaToRoot(nodeID, schema, oldName) {
        var path_ = this.protocols._getPath("domain/universal/io/schema/save");
        var body = {
            nodeID: nodeID,
            schema: schema,
            oldName: oldName,
        };
        return this.protocols.apiPost(path_, body);
    }

    /**
     * delete specific schema from rootNode
     * @param nodeID {string}
     * @param schemaName {string}
     */
    deleteUnivSchemaFromRoot(nodeID, schemaName) {
        var path_ = this.protocols._getPath("domain/universal/io/schema/delete");
        var body_ = {
            node_id: nodeID,
            schema_name: schemaName,
        };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * replace univSchema in rootNode
     * @param nodeID {string}
     * @param schemaName {string}
     * @param oldSchemaName {string}
     * @param univSchema - schema has templateName, inputKeys, outputKeys & access
     */
    replaceUnivSchemaInRoot(nodeID, schemaName, univSchema, oldSchemaName) {
        var path_ = this.protocols._getPath("domain/universal/io/replaceUnivSchemaInRoot");
        var body_ = {
            node_id: nodeID,
            old_schema_name: oldSchemaName,
            ...univSchema,
        };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * save inputs to corresponding nodes
     * @param treeId {string} Tree id for this universal table.
     * @param inputs {{}}
     * @param comment {string}
     *
     */
    universalSaveInputs(treeId, inputs, comment, menuID) {
        var path_ = this.protocols._getPath("domain/universal/io/table/save");
        var body = {
            tree_id: treeId,
            inputs: inputs,
            comment: comment,
            menu_id: menuID,
        };
        return this.protocols.apiPost(path_, body);
    }

    /**
     * set chart as default view in addTable/table/univTable
     * @param treeId {string} Tree id for this universal table.
     * @param chartId (actionMenuId)
     * @param tableType (addTable or table or universalTable)
     * @param chartBy (row or col)
     * @param chartType (line or bar or pie or scatter or bubble)
     * @param orientation (horizontal or vertical)
     * @param chartRotationIndex (for scatter or bubble chart)
     *
     */
    setChartAsDefault(treeId, chartId, tableType, chartBy, chartType, orientation, chartRotationIndex) {
        var path_ = this.protocols._getPath("domain/portfolio/default/chart");
        var body = {
            tree_id: treeId,
            chart_id: chartId,
            table_type: tableType,
            chart_by: chartBy,
            chart_type: chartType,
            orientation: orientation,
            chart_rotation_index: chartRotationIndex,
        };
        return this.protocols.apiPost(path_, body);
    }

    // REGION: Portfolio Admin override share data

    overrideInput(targetNodeID, sourceNodeID, inputKey) {
        var path_ = this.protocols._getPath("domain/input/override");

        var body_ = {
            targetNodeID: targetNodeID,
            sourceNodeID: sourceNodeID,
            inputKey: inputKey,
        };

        return this.protocols.apiPut(path_, body_);
    }

    deoverrideInput(targetNodeID, inputKey) {
        var path_ = this.protocols._getPath("domain/input/deoverride");

        var body_ = {
            targetNodeID: targetNodeID,
            inputKey: inputKey,
        };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Executes goal analysis command and fetches the results. Implemented according to ActionFor
     * REGION: all users get goal analysis over portfolio uncertainty
     *
     * @param {string} nodeID - The node for which the action is to be applied
     * @param packedRangeInfo
     * @param packedReportOptions - Report options for this action.
     * @param packedMenuInfo - when analyze inside Goal Analysis
     * @param actionID - when click on action menu Goal Analysis, read default range info
     * @returns {PromiseActionResults} A promise to the result of running the goal analysis action
     */
    performGoalAnalysis(nodeID, packedRangeInfo, packedReportOptions, packedExcludeFilterTags, actionID = null, packedMenuInfo) {
        var path_ = this.protocols._getPath("domain/goal-analysis");
        path_ = `${path_}/${nodeID}`;

        var body_ = {
            action_id: actionID,
            packed_menu_info: packedMenuInfo,
            packed_range_info: packedRangeInfo,
            packed_report_options: packedReportOptions,
            packed_exclude_filter_tags: packedExcludeFilterTags,
        };

        return this.protocols.apiPost(path_, body_);
    }

    // REGION: Contributor save inputs

    /**
     * @typedef {object} InputKeyValPair
     * @property {string} Key - the key of the input, eg startYear or discountRate
     * @property {string} Val - the value of the input eg 0.89
     */

    /**
     * Saves inputs
     * @param {string} nodeID The ID of the node that's inputs are to be saved
     * @param {Array<InputKeyValPair>} inputs array of inputs to be saved
     * @param {boolean} forceSync force recalculate saving node.
     * @returns {string} msg - save status message
     * @example saved dataset for node 'leafNodeExample' by calling template 'exampleTemplateName'
     * @example
     *
     * //to save inputs for a given node
     * smartorg.saveInputs(nodeID, inputs)
     *  .then(function (ret) {
     *      console.log(ret); // Need documentation on ret
     *  })
     *  .catch(function (err) {
     *      handleError(err);
     *  });
     */
    saveInputs(nodeID, inputs, forceSync = false) {
        var path_ = this.protocols._getPath("template/input/save");
        var body_ = {
            nodeID: nodeID,
            inputs: inputs,
            recalc: forceSync,
        };

        return this.protocols.apiPost(path_, body_);
    }

    saveMultipleTableInputs(nodeID, inputs, gmfSummation, forceSync = false) {
        var path_ = this.protocols._getPath('template/input/multiple-table/save');
        var body_ = {
            nodeID: nodeID,
            inputs: inputs,
            GMFsummation: gmfSummation,
            recalc: forceSync,
        };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Save a node's description.
     * Both two parameters need to encode in base 64.
     *
     * @param nodeID {string} Tree node Id.
     * @param description {string} Html format description.
     */
    saveDescripton(nodeID, description) {
        var path_ = this.protocols._getPath("domain/description/save");
        path_ = `${path_}/${nodeID}`;
        var body_ = {
            description: description,
        };

        return this.protocols.apiPut(path_, body_);
    }

    /**
     * Save a node's smartText.
     * Both two parameters need to encode in base 64.
     *
     * @param nodeID {string} Tree node Id.
     * @param smartText {string} Html format smartText.
     */
    saveSmartText(nodeID, smartText) {
        var path_ = this.protocols._getPath("domain/smart-text/save");
        path_ = `${path_}/${nodeID}`;
        var body_ = {
            smartText: smartText,
        };

        return this.protocols.apiPut(path_, body_);
    }

    // REGION: Everyone portfolio related method

    /**
     * lookup host specific short code to return url
     * @param {string} shortCode - the short code to lookup url
     * @returns ShortUrl or 404 Not Found
     */
    getInfoForShortURL(shortCode) {
        var path_ = this.protocols._getPath("framework/shorturl");
        path_ = `${path_}/${shortCode}`;

        return this.protocols.apiGet(path_);
    }

    /**
     * @typedef {object} ShortUrl
     * @property {string} _id - short url code e.g.  JwijDXE
     * @property {string} _rev - internal document revision ( ignore )
     * @property {string} url - long url
     * @property {boolean} byPassLogin
     * @property {string} urlLinkName - e.g. Report 2016 PMF submission
     * @property {string} csum - checksum of the long url used for duplicate prevention ( ignore )
     * @property {array.<string>} CategoryEntries - e.g US, Asia, Europe
     */

    /**
     * This callback is called when the ShortUrlPromise response succeeds
     *
     * @callback ShortUrlPromiseeCallbackSuccess
     * @param {ShortUrl[]} ShortUrl - a ShortUrl object
     */

    /**
     * This callback is called when the ShortUrlPromise response fails
     *
     * @callback ShortUrlPromiseCallbackError
     * @param {string} message - base64 encoded error message
     */

    /**
     * Resolves with a {@link ShortUrlPromiseeCallbackSuccess},
     * fails with a {@link ShortUrlPromiseCallbackError}
     *
     * @typedef {Promise} ShortUrlPromise
     */

    /**
     *
     * @param {string} urlToShorten - long url to shorten
     * @param {boolean} byPassLogin - true or false flag to bypass login
     * @param {string} urlLinkName - optional link or report name
     * @returns ShortUrlPromise
     */
    createShortURL(urlToShorten, byPassLogin, urlLinkName) {
        var path_ = this.protocols._getPath("framework/shorturl");
        // TODO: Find out the difference
        // note the / at the end, this is important to match url for POST

        var body_ = {
            ByPassLogin: byPassLogin,
            urlToShorten: encodeURIComponent(urlToShorten),
            urlLinkName: urlLinkName,
        };

        return this.protocols.apiPost(path_, body_);
    }

    /** @typedef {object} Template
     * @property {string} name - Name of this template
     * @property {string} hasPlatform - Does this template have a platform capability?
     * @property {object} [info] - Information about this template
     * @property {string} [info.Creator] - Name of the creator of this template
     * @property {string} [info.CreatorLink] - Link to the template creator's website
     * @property {string} [info.Description] - Description of this template
     * @property {string} [info.Email] - Email id of template creator
     */

    /**
     * @external Promise
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise Promise}
     */

    /**
     * This callback is called when the result is loaded.
     *
     * @callback PromiseTemplatesCallbackTemplateSuccess
     * @param {Template[]} ret - The result is loaded.
     */

    /**
     * This callback is called when the result fails to load.
     *
     * @callback PromiseTemplatesCallbackTemplateError
     * @param {Error} err - The error that occurred while loading the result.
     */

    /** Resolves with a {@link PromiseTemplatesCallbackTemplateSuccess}, fails with a {@link PromiseTemplatesCallbackTemplateError}
     *
     * @typedef {Promise} PromiseTemplates
     */

    /**
     * Obtain all the templates applicable to a node.
     * @param {string} nodeID The ID of the node for which templates are to be fetched.
     * Normally, this would result in all the templates in the system, unless the administrator has restricted
     * the templates that are visible in the portfolio of which this node is a part. In that case,
     * only the restricted templates will be returned.
     * @returns {PromiseTemplates} A promise to provide the list of templates
     * @example
     * smartorg.templatesFor(templatesNodeID)
     *  .then(function (ret) {
     *      console.warn(ret);
     *      // ret[0] --> First Template
     *      // ret[0].name --> Name of first template
     *      // ret[0].hasPlatform --> Does this have a platform?
     *      // ret[0].info.Creator --> Optional name of creator of template
     *      // ret[0].info.CreatorLink --> Optional link to creator website
     *      // ret[0].info.Description --> Optional description of template
     *      // ret[0].info.Email --> Optional email id of creator of template
     *  })
     *  .catch(function (err) {
     *      console.error(err);
     *  })
     */
    templatesFor(nodeID) {
        var path_ = this.protocols._getPath("domain/templates/list");
        path_ = `${path_}/${nodeID}`;

        return this.protocols.apiGet(path_, {});
    }

    /**
     * @typedef {object} AclGroup
     * @property {Array<string>} editors - Array of user ids of editors
     * @property {Array<string>} portfolioAdmins - Array of user ids of portfolio administrators
     * @property {Array<string>} viewers - Array of user ids of viewers
     */
    /**
     * @typedef {object} Acl
     * @property {AclGroup} group - Group Access Control List
     * @property {string} owner - User ID of owner of portfolio
     * @property {AclGroup} user - What's this for?
     */
    /**
     * @typedef {object} Node
     * @property {string} name - Name of this node
     * @property {string} _id - ID of this node
     * @property {string} _rev - Revision number of this node
     * @property {string} data - A pointer to the data node of the portfolio
     * @property {string} treeID - The name of the tree to which this node belongs
     * @property {Array<string>} children - An array of child node IDs under this node
     * @property {Array<string>} commands - An array of templates that apply to this node. Portfolio nodes tend not to have commands, so this array is likely 0 for such nodes. If there is a template, then only the first item in this array would be populated. This has been kept an array just in case the design evolves to support multiple templates for a node.
     * @property {boolean} isPlatform - Is the current node a platform node? This is always false for portfolio nodes.
     * @property {Acl} [acl] - The access control list information for this portfolio. This attribute is only present if the node is a root (portfolio) node.
     */

    /**
     * @external Promise
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise Promise}
     */

    /**
     * This callback is called when the result is loaded.
     *
     * @callback PromisePortfoliosCallbackSuccess
     * @param {Node[]} ret - A collection of root (or portfolio) nodes that the user is allowed to access.
     */

    /**
     * This callback is called when the result fails to load.
     *
     * @callback PromisePortfoliosCallbackError
     * @param {Error} err - The error that occurred while loading portfolios.
     */

    /** Resolves with a {@link PromisePortfoliosCallbackSuccess}, fails with a {@link PromisePortfoliosCallbackError}
     *
     * @typedef {Promise} PromisePortfolios
     */

    /**
     * Fetches all the portfolios available to user, based on access control rules
     * setup by the administrator. If no rules are set for the user, all
     * portfolios will be returned.
     *
     * @returns {PromisePortfolios} A promise to the portfolios
     * @example
     * smartorg.portfolios()
     *  .then(function (portfolios) {
     *      portfolios.map(function (portfolio) {
     *          console.log(portfolio);
     *      })
     *  })
     *  .catch(function (err) {
     *      console.error(err);
     *  })
     */
    portfolios() {
        var path_ = this.protocols._getPath("domain/nav/portfolios");

        return this.protocols.apiGet(path_, {});
    }

    /**
     * This callback is called when the treeFor response succeeds
     *
     * @callback PromiseTreeCallbackSuccess
     * @param {Node[]} nodes - tree structure of Nodes
     */

    /**
     * This callback is called when the treeFor response fails
     *
     * @callback PromiseTreeCallbackError
     * @param {string} message - base64 encoded error message
     */

    /** Resolves with a {@link PromiseTreeCallbackSuccess}, fails with a {@link PromiseTreeCallbackError}
     *
     * @typedef {Promise} PromiseTree
     */

    /**
     * Gets the Tree for a Portfolio
     * @param {string} portfolioName Portfolio Name
     * @returns {PromiseTree} A promise representing the Portfolio Tree structure
     * @example
     * smartorg.treeFor(treeID)
     * .then(function (tree) {
     *      console.log(tree);
     *  })
     * .catch(function (err) {
     *      handleError(err);
     *  })

     */
    treeFor(portfolioName) {
        var path_ = this.protocols._getPath("domain/nav/tree");
        path_ = `${path_}/${encodeURIComponent(portfolioName)}`;

        return this.protocols.apiGet(path_, {});
    }

    /** @typedef {object} MenuObject
     * @property {string} Command - The command to be invoked by this action
     * @property {string} displayText - The display for this menu item
     * @property {string} menuID - The unique key for this menu item
     * @property {string} nodeID - The node ID for from this menu item has been fetched
     * @property {boolean} isOutput - Is this command an output command? *TABLE_INPUT* and *INPUT_SCREEN* are input commands, and all else are output commands.
     * @property {number} level - What is this?
     * @property {object} parameters - These specify what needs to go into the command. They differ based on the command.
     * @property {string} A - Legacy Javascript code injection. Ignore when writing your applications.
     */

    /**
     * This callback is called when the result is loaded.
     *
     * @callback PromiseActionMenuCallbackSuccess
     * @param {Object} menu - An object that contains the menu items
     * @param {Object} menu.menuItems - The menu items for the node
     * @param {Array<MenuObject>} menu.menuItems.Actions - The menu items under the "Actions" section
     * @param {Array<MenuObject>} menu.menuItems.Management - The menu items under the "Management" section
     */

    /**
     * This callback is called when the result fails to load.
     *
     * @callback PromiseActionMenuCallbackError
     * @param {Error} err - The error that occurred while loading inputs.
     */

    /** Resolves with a {@link PromiseActionMenuCallbackSuccess}, fails with a {@link PromiseActionMenuCallbackError}
     *
     * @typedef {Promise} PromiseActionMenu
     */

    /**
     * Fetches the menu (from the appropriate template) that is associated with a node.
     *
     * @param {string} nodeID The ID of the node for which a menu is to be fetched
     * @returns {PromiseActionMenu} A promise to return the action menu for the specified node
     * @example
     * smartorg.actionMenuFor(nodeID)
     *  .then(function (menu) {
     *      console.log(menu);
     *  })
     *  .catch(function (err) {
     *      handleError(err);
     *  });
     */
    actionMenuFor(nodeID) {
        var path_ = this.protocols._getPath("domain/nav/menu");
        path_ = `${path_}/${nodeID}`;

        return this.protocols.apiGet(path_, {});
    }

    /** @typedef {object} CompareValueChildData
     * @property {Array<string>} childLeafNames - A list of names of all children immediately below the selected node
     * @property {Array<object>} compareValueData - A list of values of all children immediately below the selected node. The values are picked from the template (portfolio or platformPortfolio) based on the *Keys* parameter (an array of output keys) for the COMPARE_VALUE command.
     * @property {string} nodeName - Name of the selected node
     */

    /** @typedef {object} CompareValueData
     * @property {object} commandJSON - This has attributes that setup the chart display
     * @property {CompareValueChildData} highlightedNode - This is the appropriate value data in the node that has been selected
     * @property {CompareValueChildData} siblingNode - This is the appropriate value data in the sibling node that has been selected. It will be *null* if no sibling has been selected.
     */

    /** @typedef {object} CompareUncOutputData
     * @property {string} Display - Full display describing the output
     * @property {string} Units - Units of the output
     * @property {number} Mean - The mean value of the combined uncertainty bar of this output in the Tornado
     * @property {string} NodeName - Name of the child
     * @property {Array<number>} Summary - The low (0), medium (1) and high (2) values of this output based on the combined uncertainty bar in the Tornado.
     */

    /** @typedef {object} CompareUncData
     * @property {Array<Array<CompareUncOutputData>>} highlightedNode - This is a nested array. First, it has as many elements as there are children. Each element is an array of outputs, one for each Tornado *ValueMetricKey*.
     * @property {Array<Array<CompareUncOutputData>>} siblingNode - This is a nested array. First, it has as many elements as there are children. Each element is an array of outputs, one for each Tornado *ValueMetricKey*. An empty array is returned if no sibling has been selected.
     * @property {string} nodeName - Node name of highlighted node
     * @property {string} shadowNodeName - Node name of sibling node. This is null if no sibling has been selected.
     */
    /**
     * This callback is called when the result is loaded.
     *
     * @callback PromiseActionCallbackSuccess
     * @param {(CompareValueData|CompareUncData)} ret - An object that differs based on the command that was executed
     */

    /**
     * This callback is called when the result fails to load.
     *
     * @callback PromiseActionCallbackError
     * @param {Error} err - The error that occurred while loading inputs.
     */

    /** Resolves with a {@link PromiseActionCallbackSuccess}, fails with a {@link PromiseActionCallbackError}
     *
     * @typedef {Promise} PromiseActionResults
     */

    /**
     * Executes action command and fetches the results. This is a general way to access any template menu command.
     *
     * @param {string} actionID - The ID for the action specified in the template menu JSON (portfolio or application structure).
     * @param {string} nodeID - The node for which the action is to be applied
     * @param {object} packedReportOptions - Report options for this action.
     * @returns {PromiseActionResults} A promise to the result of running the action
     * @example
     * smartorg.actionFor(actionID, nodeID)
     *  .then(function (data) {
     *      console.log(data); // The data object is structured differently for each command
     *  })
     *  .catch(function (err) {
     *      console.error(err);
     *  })
     */
    actionFor(actionID, nodeID, packedReportOptions, packedExcludeFilterTags) {
        this.startWorkbenchLoading();
        var path_ = this.protocols._getPath("template/actionMenu");
        path_ = `${path_}/${actionID}/${nodeID}`;

        var body_ = {
            packed_report_options: packedReportOptions,
            packed_exclude_filter_tags: packedExcludeFilterTags,
        };

        return this.protocols.apiPost(path_, body_);
    }

    /** @typedef {object} Input
     * @property {string} Key The key of this input
     * @property {string} Type The type of this input (SCALAR|DISTRIBUTION|TABLE)
     * @property {string} Units The units of this input
     * @property {string} Display The display prompt of this input
     * @property {string} Description The description of this input
     * @property {string} Constraint The constraint placed on this input (double|integer|year|date|text)
     * @property {string} Val The value of this input
     * @property {boolean} Inherited Is this an inherited input (shared variable)?
     * @property {string} InputType Is this data local to the node or overridden? (LOCAL|OVERRIDE)
     * @property {number} isLeaf If this node is a leaf, this is set to 1 otherwise 0
     * @property {string} CellLink The link to the Excel cell
     */
    /** @typedef {object} MenuItems
     * @property {string} nodeAttribute - This has identifiers like "r" or "rw" to specify if this node is editable.
     * @property {string} nodeID - The node for which the inputs have been fetched
     * @property {Input[]} inputs - The inputs that have been found
     * @property {string} selectedInputKey - what is this?
     * @property {string} targetDS - The dataset ID. Why is it called "target"?
     * @property {string} templateID - The ID of the template associated with this node
     * @property {string} user - The name of the user who has made the request
     */

    /**
     * @external Promise
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise Promise}
     */

    /**
     * This callback is called when the result is loaded.
     *
     * @callback PromiseInputsCallbackSuccess
     * @param {MenuItems} ret - A collection of inputs matching the keys at the node
     */

    /**
     * This callback is called when the result fails to load.
     *
     * @callback PromiseInputsCallbackError
     * @param {Error} err - The error that occurred while loading inputs.
     */

    /** Resolves with a {@link PromiseInputsCallbackSuccess}, fails with a {@link PromiseInputsCallbackError}
     *
     * @typedef {Promise} PromiseInputs
     */

    /**
     * Given the keys for inputs and a node ID, all the input data
     * at that node matching the keys are fetched.
     *
     * @param {string} nodeID The ID of the node at which the inputs are to be fetched
     * @param {string} inputKeys A pipe-delimited list of input keys that are to be fetched at the node
     * @returns {PromiseInputs} A promise to the inputs
     * @example
     * nodeID = "012345";
     * inputKeys = "marketSize|marketShare|discountRate";
     * smartorg.inputsFor(nodeID, inputKeys)
     *  .then(function (inputs) {
     *      inputs.menuItems.map(function (input) {
     *          console.log(input);
     *      })
     *  })
     *  .catch(function (err) {
     *      console.error(err);
     *  })
     */

    inputsFor(nodeID, inputKeys) {
        var path_ = this.protocols._getPath("template/inputs");
        path_ = `${path_}/${nodeID}/${encodeURIComponent(inputKeys)}`;

        return this.protocols.apiGet(path_, {});
    }

    /**
     * Get share data (Input screen) structure.
     *
     * @param nodeID {string} Tree node ID.
     * @returns {Promise} A promise to return shared data for the
     *     specified node.
     * @example
     * smartorg.sharedDataFor(nodeID)
     *  .then(function (menu) {
     *      console.log(menu);
     *  })
     *  .catch(function (err) {
     *      handleError(err);
     *  });
     */
    sharedDataFor(nodeID) {
        var path_ = this.protocols._getPath("template/share-data");
        path_ = `${path_}/${nodeID}`;

        return this.protocols.apiPost(path_, {});
    }

    /**
     * Downloads spreadsheet model applicable at node with given nodeID.
     * Data is a json object with the following structure:
     * @example
     * smartorg.downloadSpreadsheet(myNodeID).then(function(answer) {
     *    console.log(answer.templateName); // e.g. simpleLaunch
     *    console.log(answer.extension); // e.g. xls
     *    console.log(answer.modelData); // Base-64 encoded binary data
     * }).catch(function(err) {
     *    console.log(err);
     * });
     * @param nodeID
     * @return {*|Promise|axios.Promise}
     */
    downloadSpreadsheet(nodeID) {
        var path_ = this.protocols._getPath("domain/template-report");
        path_ = `${path_}/${nodeID}`;
        var body_ = {};

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Get simple outputs from node data
     * @returns {*} simple outputs
     *
     */
    getSimpleOutputs(nodeID) {
        var path_ = this.protocols._getPath("domain/output");
        path_ = `${path_}/${nodeID}`;
        return this.protocols.apiGet(path_);
    }

    requestPPEmail(name, email, data) {
        var path_ = this.protocols._getPath("service/portfolio-power/email");
        var body_ = {
            name: name,
            email: email,
            data: data,
        };

        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Get first-n-level nodes from root node
     * @param treeID
     * @param nLevel
     * @returns {any}
     */
    firstNLevelTreeFor(treeID, nLevel, selectedNodeId) {
        var path_ = this.protocols._getPath("domain/nav/tree-first-n-level");
        path_ = `${path_}/${encodeURIComponent(treeID)}/${nLevel}`;
        var body_ = {
            selectedNodeId,
        };
        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Get the subtrees of a list of nodes
     * Used before searching node to load rest nodes from backend
     * @param loadNodeList
     * @returns {any}
     */
    getSubtree(loadNodeList) {
        var path_ = this.protocols._getPath("domain/nav/get-subtree");
        var body_ = { node_ids: [] };
        for (let i = 0; i < loadNodeList.length; i++) {
            body_["node_ids"].push(loadNodeList[i]._id);
        }
        return this.protocols.apiPost(path_, body_);
    }

    /**
     * Get program overview report for selected node
     * @param node_id
     * @param menu_id
     * @param packedReportOptions
     * @param timestamp
     * @returns {any}
     */
    generateProgramOverviewReport(node_id, menu_id, packedReportOptions, packedExcludeFilterTags, timestamp) {
        var path_ = this.protocols._getPath("domain/generate-program-overview-report");
        path_ = `${path_}/${node_id}/${menu_id}/${packedReportOptions}/${packedExcludeFilterTags}/${timestamp}`;
        return this.protocols.apiGet(path_);
    }

    listRecycleBin(nodeId) {
        const path = this.protocols._getPath(`domain/recycle-bin/${nodeId}`);
        return this.protocols.apiGet(path);
    }

    moveToRecycle(nodeId) {
        const path = this.protocols._getPath(`domain/recycle-bin/${nodeId}`);
        return this.protocols.apiPost(path, {});
    }

    recoverFromRecycle(nodeId, recordId) {
        const path = this.protocols._getPath(`domain/recycle-bin/${nodeId}/${recordId}`);
        return this.protocols.apiPut(path, {});
    }

    permanentDeleteRecord(nodeId, recordId) {
        const path = this.protocols._getPath(`domain/recycle-bin/${nodeId}/${recordId}`);
        return this.protocols.apiDelete(path);
    }
}

export default SmartOrgService;
// module.exports = SmartOrg;

//For users not using a module loading eg(browersify), need a way
// to expose it to them via the window object
if (typeof window != "undefined") {
    window["SmartOrg"] = SmartOrgService;
}

//[NOTES]
//
// [1] - Promises - alt way to bubble promise up to next catch statement ...
// throw new APIErr({'status':error.status,'statusText':error.statusText,'message':error.data.message})
//
// [2] - Symbols - a neat way to set ACL/scope on methods etc ...
// export const createHMACget = Symbol('createHMACget');
// is possible to use reflection but hides this sufficiently for now ...
// also if you wanted to extend SmartOrg ... avoids name collisions
//
// [3] - Symbols - Private Fxn in action
// symbols way to define unique primatives  and give controll over access to properties and avoid collisions
// prototype instances named props on fxns
// this is a way to hide -

/*
 var rangal = new Rangal("server.smartorg.com");
 rangal.login(username,password, function(loginStatus) {});
 rangal.treeFor("Product Launch", function(treeJSON) {});
 rangal.actionByDisplayName("Product Launch", "Breath Strips Node ID", "Tornado Action ID", function(tornadoJSON) {});
 rangal.portfolios(function(portfolioJSON) {});
 rangal.actionMenuFor("Product Launch", "Breath Strips", function(actionMenuJSON){});
 */
