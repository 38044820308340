import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, isDevMode } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { SmartOrgService, BACKEND_DOMAIN_TOKEN, API_ENDPOINT_ENDPOINT_TOKEN } from './services/smartorg.service';
import { NavigationService } from './services/navigation.service';
import { BACKEND_DOMAIN, API_ENDPOINT_ENDPOINT } from '@app/services/config';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RequestResetComponent } from './pages/request-reset/request-reset.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SharedModule } from './shared/shared.module';
import { VerifyComponent } from './pages/verify/verify.component';
import { RememberMeService } from './services/remember-me.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
@NgModule({ declarations: [AppComponent, LoginComponent, RequestResetComponent, ResetPasswordComponent, VerifyComponent],
    bootstrap: [AppComponent], imports: [BrowserModule, AppRoutingModule, ReactiveFormsModule, FormsModule, ProgressSpinnerModule,
        BrowserAnimationsModule,
        StoreModule.forRoot({}, {}),
        EffectsModule.forRoot(),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: !isDevMode(), // Restrict extension to log-only mode
            autoPause: true, // Pauses recording actions and state changes when the extension window is not open
            trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
            traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
         connectInZone: true}),
        SharedModule], providers: [
        { provide: SmartOrgService, useClass: SmartOrgService },
        { provide: BACKEND_DOMAIN_TOKEN, useValue: BACKEND_DOMAIN },
        { provide: API_ENDPOINT_ENDPOINT_TOKEN, useValue: API_ENDPOINT_ENDPOINT },
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        NavigationService,
        RememberMeService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
