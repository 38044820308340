<div class="card flex justify-content-center">
  <p-progressSpinner *ngIf="loading" ariaLabel="loading" />
</div>
<div [hidden]="loading">
  <div class="login-container">
    <div class="login-sub-container">
      <div class="login-logo">
        <img width="70%" src="assets/images/smartorg_logo_transparent.png" />
      </div>
      <div class="login-main">
        <form [formGroup]="form" (ngSubmit)="login()">
          <label class="login-label" for="inputUsername">Username</label>
          <input
            type="text"
            id="inputUsername"
            required
            class="form-control login-input"
            [ngClass]="{ 'login-error-red-border': errorMessage }"
            placeholder="Username"
            required=""
            (keyup)="triggerLoginByEnter($event)"
            formControlName="username"
          />

          <label class="login-label" for="inputPassword">Password</label>
          <input
            type="password"
            id="inputPassword"
            required
            class="form-control login-input"
            [ngClass]="{ 'login-error-red-border': errorMessage }"
            placeholder="Password"
            formControlName="password"
            (keyup)="triggerLoginByEnter($event)"
          />

          <div class="checkbox">
            <label>
              <input type="checkbox" formControlName="rememberCheck" /> Remember
              username
            </label>
          </div>

          <div class="error-message" *ngIf="errorMessage">
            {{ errorMessage }}
          </div>

          <div class="d-grid">
            <button
              class="btn btn-lg btn-primary cta"
              style="width: -webkit-fill-available"
              type="submit"
              [disabled]="loginDisabled || loggingIn"
            >
              <span *ngIf="!loggingIn">Sign in</span>
              <span *ngIf="loggingIn"><i class="fa fa-spinner fa-spin"></i></span>
            </button>
          </div>

          <div
            class="login-forgot-password"
            style="text-align: right; margin-top: 5px"
          >
            <a [routerLink]="['/request-reset']">Forgot password?</a>
          </div>
        </form>
      </div>

      <div class="login-version">Version: {{ currentVersion }}</div>
    </div>
  </div>

  <div class="navbar fixed-bottom login-copyright">
    <div>© 2000-2024 SmartOrg Inc. All rights reserved.</div>
    <div class="mt-10">PATENT PROTECTION</div>
    <div>
      SmartOrg, Inc.'s technology is protected by one or more of the following
      patents listed at
      <a
        style="color: lightskyblue"
        href="https://smartorg.com/about-smartorg/patents/"
        target="_blank"
      >
        https://smartorg.com/about-smartorg/patents/
      </a>
      and other patents pending.
    </div>
    <div class="mt-10">PRIVACY POLICY</div>
    <div>
      <a
        style="color: lightskyblue"
        href="https://smartorg.com/privacy-policy/"
        target="_blank"
      >
        https://smartorg.com/privacy-policy/
      </a>
    </div>
  </div>
</div>