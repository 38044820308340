class Wizard {
    protocols: any;
  
    constructor(protocols: any) {
      this.protocols = protocols;
    }
  
    findDuplicatesInTemplates() {
      const path_ = this.protocols._getPath('wizard/duplicate-menu-id');
      return this.protocols.apiGet(path_);
    }
  
    fetchPotentialTableInputs(templateName: string) {
      const path_ = this.protocols._getPath('wizard/potential-table-inputs');
      const fullPath = `${path_}/${templateName}`;
      return this.protocols.apiGet(fullPath);
    }
  
    predictInputs(rangeNamesAsCommaSeparatedText: string, templatePrefix: string, author: string, industry: string, modelType: string) {
      const path_ = this.protocols._getPath('wizard/predict-inputs');
      const fullPath = `${path_}/${rangeNamesAsCommaSeparatedText}/${templatePrefix}/${author}/${industry}/${modelType}`;
      return this.protocols.apiGet(fullPath);
    }
  }
  
  export default Wizard;
  